import { Control, Controller } from "react-hook-form";
import styles from "./InputField.module.scss";
import clsx from "clsx";

type Props = {
  children?: React.ReactNode,
  fieldName: string,
  placeholder: string,
  control: Control<any>,
  customClasses: string,
}

const InputField: React.FC<Props> = ({
  fieldName,
  placeholder,
  control,
  customClasses = "",
}) => {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <input
          value={value ?? ""}
          className={clsx(
            { [styles.errorInput]: error?.message },
            customClasses
          )}
          placeholder={placeholder}
          {...field}
        />
      )}
    />
  );
};

export default InputField;
