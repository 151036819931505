// @ts-nocheck
import { commomKeys, routes } from '../../../common';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useState } from 'react';
import { changePasswordConst, decryptData, encryptData, useGlobalStore, usePostMigrateToPassword } from '@bryzos/giss-ui-library';
import { useNavigate } from 'react-router';
import { getChannelWindow } from '../../helper';
import useDialogStore from '../DialogPopup/DialogStore';
import styles from './changePassword.module.scss'

const ChangePassword = () => {
    const channelWindow =  getChannelWindow();
    const navigate = useNavigate();
    const {decryptionEntity, userData} = useGlobalStore();
    const { showCommonDialog, resetDialogStore } = useDialogStore();
    const changePasswordConfirmation = usePostMigrateToPassword();
    const [showOldPassword, setShowOldPassword] = useState(true);
    const { register, handleSubmit, setValue,  formState: { errors } } = useForm({
        resolver: yupResolver(
            yup.object({
                currentPassword: yup.string().test('len', 'Password must be 6 digits', val => val?.length >= 6),
                newPassword: yup.string().test('len', 'Password must be 6 digits', val => val?.length >= 6),
                confirmPassword: yup.string().test("isRequired", "Password does not match!", function(value) {
                    const password = this.parent.newPassword;
                    if(password.trim() === value.trim()) return true;
                    return false;
                  })
            }).required()

        ),
        mode: 'onBlur',
    });

    useEffect(()=>{
        (async ()=>{if(channelWindow?.getLoginCredential && decryptionEntity){
            const cred = window.electron.sendSync({ channel: channelWindow.getLoginCredential });
            if(cred){
                const data = JSON.parse(await decryptData(cred, decryptionEntity.decryption_key, true));
                setValue('currentPassword', data.password);
                setShowOldPassword(false);
            }
        }
        })()
    }, [])

    async function submit({newPassword, currentPassword}) {
        try {
            if(userData){
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, currentPassword, newPassword);
                const email = userData.data?.email_id
                if(channelWindow?.setLoginCredential && email && newPassword && decryptionEntity){
                    const _encryptCredential = await encryptData(JSON.stringify({email, password: newPassword}), decryptionEntity.decryption_key);
                    window.electron.send({ channel: channelWindow.setLoginCredential, data:_encryptCredential});
                }
                changePasswordConfirmation.mutateAsync(email).then(async () => {
                    showCommonDialog(null, changePasswordConst.onSuccess, null, resetDialog, [{name: commomKeys.continue, action: resetDialog}])
                })
            }
        } catch (error) {
            console.error('Error changing password:', error);
            if(error.message === changePasswordConst.changePasswordCognitoError) error.message = changePasswordConst.incorrectOldPasswordError;
            showCommonDialog(null, error.message ?? changePasswordConst.onError, null, resetDialogStore, [{name: commomKeys.errorBtnTitle, action: resetDialogStore}])
        }
    }

    const resetDialog = () => {
        resetDialogStore();
        navigate(routes.homePage);
    }
    return (
        <div ref={ref} className={styles.container}>
            <p className={styles.resetPassTitle}>Reset Password</p>
            <p className={styles.noteText}>Note: For your security, we request you to set your own password.</p>
            <table>
                <tbody>
                {showOldPassword && <tr className={styles.inputBody}>
                        <td className={styles.rowHeader}>
                        <p className={styles.rowHeaderTexts}>Old Password</p>
                        </td>
                        <td className={styles.enterEmail}>
                            <div>
                                <input type="password" {...register("currentPassword")}  placeholder='Enter Current Password'  />
                            </div>
                            <p className={styles.errorText}>{errors.currentPassword?.message}</p>
                        </td>
                    </tr>}
                    <tr className={styles.inputBody}>
                        <td className={styles.rowHeader}>
                            <p className={styles.rowHeaderTexts}>Enter New Password</p>
                        </td>
                        <td className={styles.enterEmail}>
                            <div>
                                <input type="password" {...register("newPassword")}  placeholder='Enter New Password'  />
                            </div>
                            <p className={styles.errorText}>{errors.newPassword?.message}</p>
                        </td>
                    </tr>
                    <tr className={styles.inputBody}>
                        <td className={styles.rowHeader}>
                            <p className={styles.rowHeaderTexts}>Re-Enter New Password</p>
                        </td>
                        <td className={styles.enterEmail}>
                            <div>
                                <input type="password" {...register("confirmPassword")}  placeholder='Re-Enter New Password'  />
                            </div>
                            <p className={styles.errorText}>{errors.confirmPassword?.message}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={styles.btnSection}>
              <button className={styles.btnReset} onClick={()=>{handleSubmit(submit)()}} disabled={Object.keys(errors).length>0}>Reset</button>
            </div>
        </div>
    );
};

export default ChangePassword;