import clsx from 'clsx';
import {
    useState,
    useEffect,
    useRef
} from 'react';
import styles from './Impersonate.module.scss';
import { useGetAllUsers, useGlobalStore, filterArrray } from '@bryzos/giss-ui-library';
import axios from 'axios';
import DialogPopup from '../DialogPopup/DialogPopup';
import { commomKeys } from 'src/common';
function Impersonate(props:any) {
    const { setShowLoader }: any = useGlobalStore();
    const { data: allUsers, isLoading: isGetAllUsersLoading } = useGetAllUsers()
    const [userList, setUserList] = useState([]);
    const [filterUserList, setFilterUserList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        if (allUsers && !isGetAllUsersLoading) {
            setUserList(allUsers);
            setFilterUserList(allUsers);
            setShowLoader(false);
        }
    }, [allUsers, isGetAllUsersLoading])
    const handleSelectedUserLogin = async(userData) => {
        try{
            setShowLoader(true);
            const response = await axios.get(import.meta.env.VITE_API_SERVICE +'/admin/getUserAccessToken?userKey='+userData.unique_key);
            const token = response.data.data;
            if(typeof response.data.data === 'string'){
                await props.loginImpersonatedUser(token, userData);
            }else{
                setErrorMessage(response.data.data.error_message);
                setShowLoader(false);
            }
        }catch(error) {
            console.log(error);
        }
    }
    const search = (e) => {
        setSearchString(e.target.value);
        if (e.target.value) {
            const _filterArrray = filterArrray(userList, e.target.value, [
                "email_id",
                "first_name",
                "last_name",
                "company_name",
            ]);
            if (_filterArrray?.length) {
                setFilterUserList(_filterArrray);
            } else {
                setFilterUserList([]);
            }
        } else {
            setFilterUserList(userList ? userList : []);
        }
    }
    return (
        <>
            <div className={clsx(styles.impersonateInnerContent, 'bgBlurContent')}>
                <div className='scrollContent'>
                    <div>
                        <input type="text" value={searchString} onChange={search} />
                    </div>
                    <div>
                        <div className={styles.userHeader}>
                            <div>First Name</div>
                            <div>Last Name</div>
                            <div>Email</div>
                        </div>
                        {filterUserList.map((user) => (
                            <div className={styles.userRow} key={user.id} onClick={()=>{handleSelectedUserLogin(user)}}>
                                <div>{user.first_name}</div>
                                <div>{user.last_name}</div>
                                <div>{user.email_id}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <DialogPopup
                dialogContent={errorMessage}
                dialogBtnTitle={commomKeys.errorBtnTitle}
                type={commomKeys.actionStatus.error}
                open={!!errorMessage}
                onClose={() => setErrorMessage('')}
            />
        </>
    );
}
export default Impersonate;