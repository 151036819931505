import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {userRole} from '../../../common';
import { v4 as uuidv4 } from 'uuid';
import { useSellerOrderStore, useGlobalStore, selectProduct, dataOpticsApi1, dataOpticsApi2, changeProductUnitOption, getFormattedProductPricing } from '@bryzos/giss-ui-library';
import ProductSearch from './productSearch';
import SelectedProductListing from './selectedProductListing';
import ShareProductPricingOrApp from './shareProductPricingOrApp';
import {  ProductPricingModel, ProductQtyType, SearchAnalyticDataModel, HttpRequestPayload } from '../../types/Search';
import { ReferenceDataProduct } from 'src/renderer/types/ReferenceDataProduct';

const Home = () => {
    const {enableShareWidget, setEnableShareWidget, setShowLoader, setSearchSessionId, searchSessionId, userData, setUserData, discountData, isUserLoggedIn}:any = useGlobalStore();
    const [products, setProducts] = useState<ReferenceDataProduct[]>([]);
    const initialSelectedProducts: ProductPricingModel[] = userData.selectedProducts ? userData.selectedProducts : [];
    const [selectedProducts, setSelectedProducts] = useState<ProductPricingModel[]>(initialSelectedProducts);
    const [showWidgetPanel, setShowWidgetPanel] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<ProductQtyType>('cwt');
    const [isUserChangedSelectedOption, setIsUserChangedSelectedOption] = useState<boolean>(false);
    const [sessionId, setSessionId] = useState<string>('');
    const {resetFiltersInPurchaseOrders}:any = useSellerOrderStore();
    const analyticRef = useRef<string>();
    const [isShareWidget, setIsShareWidget] = useState<boolean>(false);
    analyticRef.current = sessionId;

    useEffect(()=>{
        resetFiltersInPurchaseOrders();
        setShowLoader(false);
        if(searchSessionId){
            setSessionId(searchSessionId)
        }else{
            const sessionId = uuidv4();
            setSessionId(sessionId);
        }
        
        return ()=>{
            setEnableShareWidget(false);
            const dataOpticsPayload = {
                "data": {
                    "session_id": analyticRef.current,
                    "move_to_screen": location.pathname.replace('/',"")
                }
            }
            dataOpticsApi2(dataOpticsPayload)
        }
    },[]);

    useEffect(()=>{
        if(enableShareWidget){
            handleOpenWidget();
        }
        else{
            handleCloseWidget();
        }
    },[enableShareWidget])

    useEffect(() => {
      if (userData.allProductsData) {
        setProducts(userData.allProductsData);
        onProductUnitChange(selectedProducts);
      }
    }, [userData?.allProductsData]);

    useEffect(() => {
        if(sessionId){
            setSearchSessionId(sessionId);
        }
    }, [sessionId])

    useEffect(() => {
        if (userData?.productMapping && userData?.selectedProducts?.length) {
            const selectedProductsCopy: ProductPricingModel[] = [];
            userData.selectedProducts.forEach((selectedProduct: ProductPricingModel) => {
                selectedProductsCopy.push(getFormattedProductPricing(userData.productMapping[selectedProduct.id], userData.data.type));   
            });
            setSelectedProducts(selectedProductsCopy);
            setUserData({ ...userData, selectedProducts: selectedProductsCopy });
        }
        else{
            setSelectedProducts([]);
        }
    }, [discountData, userData?.productMapping]);

    const onProductUnitChange = (productList: ProductPricingModel[]) => {
        changeProductUnitOption(productList, isUserChangedSelectedOption, selectedOption, setSelectedOption);
    }

    const onSelectProduct = (product: ReferenceDataProduct) => {
        selectProduct(product, selectedProducts, setSelectedProducts, sessionId, selectedOption, isUserChangedSelectedOption, setSelectedOption);
    }
    
    const handleOpenWidget = () => {
        setShowWidgetPanel(true);
        setIsShareWidget(true);
    };
    const handleCloseWidget = () => {
        setShowWidgetPanel(false);
        setIsShareWidget(false);
        setEnableShareWidget(false);
    };

    const onShareProductPricing = async (emailTo: string, emailContent: string):Promise<void> => {
            const _selectedProduct:ProductPricingModel[] = userData.singleProduct.length === 0 ? selectedProducts : userData.singleProduct;
            const productList:any[] = [];
            const dataOpticsData: SearchAnalyticDataModel[] = [];
            _selectedProduct.forEach((product:ProductPricingModel) => {
                productList.push({
                    "product_id": product.id,
                    "product_description": product.UI_Description,
                    "price_ft": product.ft_price.trim().replace("$", ""),
                    "price_lb": product.lb_price.trim().replace("$", ""),
                    "price_cwt": product.cwt_price.trim().replace("$", ""),
                    "price_share_type": selectedOption !== 'cwt,ft' ? selectedOption : product.product_type_pipe ? "ft" : "cwt",
                });
                dataOpticsData.push({
                    "session_id": sessionId,
                    "line_session_id": product.line_session_id,
                    "product_id": product.id,
                    "description": product.UI_Description,
                    "price_shared": true,
                    "search_price_unit": selectedOption !== 'cwt,ft' ? selectedOption : product.product_type_pipe ? "ft" : "cwt",
                })
            });
            
            const dataOpticsPayload:HttpRequestPayload<SearchAnalyticDataModel[]> = {
                "data": dataOpticsData
            }
            dataOpticsApi1(dataOpticsPayload)
            const payload = {
                data: {
                    "user_id": userData.data.id,
                    "from_email": userData.data.email_id,
                    "to_email": emailTo,
                    "email_content": emailContent.length === 0 ? null : emailContent,
                    "products": productList,
                }
            }
            try{
                await axios.post(import.meta.env.VITE_API_SERVICE + '/user/shareProductPrice', payload)
                setUserData({...userData, singleProduct: []});
            }catch(err){
                throw new Error("Share Product Pricing Api Failure");
            }
    }

    const onShareApp = async (emailTo: string, emailContent: string):Promise<void> => {
        const payload = {
            data: {
                "user_id": userData.data.id,
                "from_email": userData.data.email_id,
                "to_email": emailTo,
                "email_content": emailContent.length === 0 ? null : emailContent
            }
        }
        try{
            await axios.post(import.meta.env.VITE_API_SERVICE + '/user/shareWidgetRequest', payload)
        }catch(err){
            throw new Error("Share App Api Failure");
        }
    }

    const shareHandler = isShareWidget ? onShareApp : onShareProductPricing;

    
    return (
        <div className='mainContent'>
            {userData.data && 
                <>
                    <div className={!showWidgetPanel ? 'searchPanel' : 'searchPanel hidden'}>
                        <ProductSearch 
                        selectedProducts = {selectedProducts}
                        selectProduct = {onSelectProduct}
                        sessionId = {sessionId}
                        products={products} />
                        {selectedProducts.length !== 0 && 
                        <SelectedProductListing 
                        selectedProducts = {selectedProducts}
                        setSelectedProducts = {setSelectedProducts}
                        isUserChangedSelectedOption = {isUserChangedSelectedOption} 
                        setIsUserChangedSelectedOption = {setIsUserChangedSelectedOption}
                        selectedOption = {selectedOption} 
                        setSelectedOption = {setSelectedOption}
                        sessionId = {sessionId}
                        setShowWidgetPanel={setShowWidgetPanel}/>
                        }
                    </div>
                    {
                        showWidgetPanel && 
                        <div className='widgetPanel'>
                            <ShareProductPricingOrApp
                            sessionId={sessionId}
                            selectedProducts= {selectedProducts}
                            handleCloseWidget={handleCloseWidget}
                            shareHandler={shareHandler}
                            isShareWidget={isShareWidget}/>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default Home;

