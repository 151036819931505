// @ts-nocheck
import Header from './header';
import { reactQueryKeys, routes } from '../../common';
import { useEventListener } from '@mantine/hooks';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { OTPInputComponent } from '../component/OTPInput';
import Loader from '../Loader/Loader';
import { changePasswordConst, decryptData, emojiRemoverRegex, encryptData, useGlobalStore } from '@bryzos/giss-ui-library';
import { useNavigate } from 'react-router';
import { getChannelWindow } from '../helper';

const Login = () => {
    const channelWindow =  getChannelWindow();
    const navigate = useNavigate();
    const setShowLoader = useGlobalStore(state => state.setShowLoader);
    const setIsUserLoggedIn = useGlobalStore(state => state.setIsUserLoggedIn);
    const userCredentials = useGlobalStore(state => state.userCredentials);
    const setUserCredentials = useGlobalStore(state => state.setUserCredentials);
    const decryptionEntity = useGlobalStore(state => state.decryptionEntity);
    const signupUser = useGlobalStore(state => state.signupUser);
    const setSignupUser = useGlobalStore(state => state.setSignupUser);
    const setCurrentAccessToken = useGlobalStore(state => state.setCurrentAccessToken);
    const setStartLoginProcess = useGlobalStore(state => state.setStartLoginProcess);

    const { register, watch, handleSubmit, setError, setValue, clearErrors, trigger, formState: { errors, isValid, submitCount } } = useForm({
        resolver: yupResolver(
            yup.object({
                email: yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, {
                    message: 'Please enter valid email'
                }).required('Email is Required'),
                password: yup.string().test('len', 'Password must be 6 digits', val => val?.length >= 6),
                // .max(7,'Password must be  max 7 digits'),
                // password: yup.array()
                //     .of(yup.number().typeError('').required('req').integer('integer')).test('len', 'Password must be 6 digits', val => val?.filter(v => !isNaN(v)).length === 6),
            }).required()

        ),
        mode: 'onSubmit',
    });

    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const handleFocus = () => {
        setShowPasswordInput(true);
    };

    const handleEmailFocus = () => {
        setShowPasswordInput(false);
    };

    useEffect(() => {
        if (errors.root?.serverError)
            clearErrors('root.serverError')
    }, [watch('email'), watch('password')])

    useEffect(()=>{
        if(userCredentials && decryptionEntity){
            if(userCredentials === changePasswordConst.noUserCredentialFound){
                setIsUserLoggedIn(true);
                query.invalidateQueries([reactQueryKeys.cognitoUser]);
            }
            else{
                decryptUserCredential(userCredentials, decryptionEntity);
                setUserCredentials(null);
            }
        }
    },[userCredentials, decryptionEntity])


    useEffect(()=>{
        if(signupUser){
            submit(signupUser);
        }
    },[signupUser])

    const decryptUserCredential = async (userCredentials, decryptionEntity) => {
        const data = JSON.parse(await decryptData(userCredentials, decryptionEntity.decryption_key, true));
        submit(data);
    }

    const query = useQueryClient()

    async function submit(data) {
        setShowLoader(true);
        setStartLoginProcess(true);
        const password = data.password;
        const email = data.email;
        try {
            const user = await Auth.signIn(data.email, password);
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                const completeUser = await Auth.completeNewPassword(user, password);
            }
            if(channelWindow?.setLoginCredential){
                const _encryptCredential = await encryptData(JSON.stringify({email, password}), decryptionEntity.decryption_key);
                window.electron.send({ channel: channelWindow.setLoginCredential, data:_encryptCredential});
            }
            setIsUserLoggedIn(true);
            setCurrentAccessToken(user.signInUserSession.accessToken.jwtToken);
            query.invalidateQueries([reactQueryKeys.cognitoUser]);
        } catch (error) {
            console.log('error signing in', error.name, error);
            if (error.name === 'NotAuthorizedException')
                setError('root.serverError', { message: 'Wrong username or password' })
            setShowLoader(false);
        } finally {
            setSignupUser(null);
        }
    }

    const keydownRef = useEventListener('keydown', function (event) {
        if (event.key === 'Enter') {
            handleSubmit(submit)()
        }
    }
    );


    return (
        <div>
        <table ref={keydownRef}>
            <tbody>
                <tr className='inputBody'>
                    <td className='enterEmail'>
                        <input type="email" name="email" autoFocus onFocus={handleEmailFocus} {...register("email")}
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                register("email").onChange(e);
                            }}
                            placeholder='Enter Email' />
                        <p className='errorText'>{errors.email?.message}</p>
                        {(isValid && !errors.root?.serverError?.message) ?
                            <button className={`pressBtn`} onClick={handleSubmit(submit)} type="button">Press ENTER to start session</button>
                            :
                            <p className='errorText'>{errors.root?.serverError?.message}</p>
                        }
                    </td>
                    <td className='enterEmail'>
                        <div>
                            <input type="password" {...register("password")}  placeholder='Enter Password'  />
                        </div>
                        <p className='errorText'>{errors.password?.message}</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <div className='forgot-password'>
            <button onClick={()=>navigate(routes.forgotPassword)} className='forgot-password-btn'>Forgot Password?</button>
        </div>
        </div>
    );
};

export default Login;