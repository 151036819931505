// @ts-nocheck
import { usePrevious } from "@mantine/hooks";
import React, { memo, useRef, useLayoutEffect } from "react";

type Props = {
  children?: React.ReactNode,
  focus: boolean;
  autoFocus: boolean;
  index: number;
  register: UseFormRegister<any>;
  registerName: string;
  type: string;
  value: string;
  onFocus: () => void;
  onChange: (event: any) => void;
  onKeyDown: (event: any) => void;
  onBlur: () => void;
  onPaste: (event: any) => void;
  style: string;
  className: string;
  disabled: boolean;
}

export const SingleOTPInputComponent: React.FC<Props> = (props) => {
  const { focus, autoFocus, index, register, registerName, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
   
      if (focus && autoFocus && focus !== prevFocus) {
        
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);
  
  return <input 
  {...register(`${registerName}.${index}`) }
  {...rest}  
  ref={el =>{
  inputRef.current =el 
      register(`${registerName}.${index}`).ref(el)
  }}
  onChange={e=>{  
      rest.onChange(e)
      register(`${registerName}.${index}`).onChange(e)
  }}
  onBlur={e=>{
      rest.onBlur(e)
      register(`${registerName}.${index}`).onBlur(e)
  }}
  />;
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
