// @ts-nocheck
import { Dialog, Fade, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './createPo.module.scss';
import { ReactComponent as DropdownIcon } from '../../assets/images/icon_Triangle.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { ReactComponent as QuestionBlackIcon } from '../../assets/images/setting-black-question.svg';
import { ReactComponent as QuestionBlackHoverIcon } from '../../assets/images/question-black-hover.svg';
import { ReactComponent as AddLineIcon } from '../../assets/images/Add-line.svg';
import { ReactComponent as AddLineHoverIcon } from '../../assets/images/Add-line-hover.svg';
import { ReactComponent as RefreshNet30Icon } from '../../assets/images/net30refresh.svg';
import { DeliveryDateTooltip, DeliveryToTooltip, JobPOTooltip, SalesTaxTooltip, chooseYourUnitTooltip } from '../../tooltip';
import clsx from 'clsx';
import { useFieldArray } from 'react-hook-form';
import { CustomMenu } from './CustomMenu';
import { useDebouncedValue } from '@mantine/hooks';
import dayjs from 'dayjs';
import useGetUserPartData from '../../hooks/useGetUserPartData';
import { useQueryClient } from '@tanstack/react-query';
import CreatePoTile from './CreatePoTile/CreatePoTile';
import { ReactComponent as WarningIcon } from '../../assets/images/warning-seller-icon.svg';
import { v4 as uuidv4 } from 'uuid';
import { CommonTooltip } from '../../component/Tooltip/tooltip';
import PdfMakePage from '../PdfMake/pdfMake';
import { InstantPurchasingWrapper, emojiRemoverRegex, ueGetBuyingPreference, useBuyerCheckOut, useGlobalStore, useSaveProductSearchAnaytic, useSaveUserActivity, userRole, routes, reactQueryKeys, addWorkingDaysToDate, formatToTwoDecimalPlaces, getChannelWindow} from '@bryzos/giss-ui-library';

let cartItem = {
    descriptionObj: '',
    description: '',
    qty: '',
    qty_unit: '',
    price_unit: '',
    product_tag: '',
    domestic_material_only: false,
    qtyUnitM:[],
    priceUnitM:[],
    line_session_id:'',
}

const InstantPurchasing = (
    {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        errors,
        isValid,
        handleStateZipValidation,
        quantitySizeValidator,
        updateLineItem,
        pricePerUnitChangeHandler,
        calculateMaterialTotalPrice,
        buyingPreference,
        setBuyingPreference,
        calculateTotalPricing,
        handleSalesTax,
        paymentMethodChangeHandler,
        saveCreatePOUserActivity,
        saveUserLineActivity,
        setDefaultPaymentOption,
        paymentMethods
    }
) => {
    const navigate = useNavigate();
    
    const {setCreatePoData, createPoData, setShowLoader, backNavigation, setCreatePoSessionId, resetHeaderConfig, userData, setBackNavigation, bryzosPayApprovalStatus, setBryzosPayApprovalStatus} = useGlobalStore();
    const channelWindow =  getChannelWindow();
    const [products, setProducts] = useState([]);
    const [states, setStates] = useState([]);
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [showLine1Tooltip, setShowLine1Tooltip] = useState(true);
    const [showCityTooltip, setShowCityTooltip] = useState(true);
    const [showPaymentOptionTooltip, setShowPaymentOptionTooltip] = useState(undefined);
    const [buyingPreferenceEmailId, setBuyingPreferenceEmailId] = useState("");

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sendInvoicesToEmailData, SetSendInvoicesToEmailData] = useState('');
    const [apiCallInProgress, setApiCallInProgress] = useState(false);

    const queryClient = useQueryClient();
    const { data: userPartData, isLoading: isUserPartDataLoading } = useGetUserPartData();
    const logUserActivity = useSaveUserActivity();

    const { fields, append, remove, } = useFieldArray({
        control,
        name: "cart_items"
    });

    const [debouncedMatTotal] = useDebouncedValue(watch('price'), 400);
    const [handleSubmitValidation, setHandleSubmitValidation] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [lineSessionId, setLineSessionId] = useState('');
    const [disableCloseAnalytic, setDisableCloseAnalytic] = useState(true);
    const [deliveryStateFocusEnable, setDeliveryStateFocusEnable] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchStringData, setSearchString] = useState('');
    const [todayDate, setTodayDate] = useState('');
    const [disableDeliveryDate, setDisableDeliveryDate] = useState(true);
    const [deboucedSearchString, cancelDebouncedSearchString] = useDebouncedValue(searchStringData, 400);
    const analyticRef = useRef();
    analyticRef.current = disableCloseAnalytic && !resetHeaderConfig;
    const getBuyingPreference = ueGetBuyingPreference();
    const saveBuyerCheckout = useBuyerCheckOut();
    const saveProductSearch = useSaveProductSearchAnaytic();

    useEffect(() => {
        if(!createPoData)
        addNewRow();
        setShowLoader(true)
        if (userData) {
            setProducts(userData.allProductsData)

            const referenceData = userData.referenceData;
            setStates(referenceData.ref_states);
            setDeliveryDates(referenceData.ref_delivery_date);
            initializeCreatePOData();
            
        }
        const sessionId = uuidv4();
        setSessionId(sessionId);
        setCreatePoSessionId(sessionId)
        const payload = {
            "data": {
                "session_id": sessionId
            }
        }
        logUserActivity.mutateAsync({url:import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close' ,payload}).catch(err => console.error(err));
        return(() => {
            setBackNavigation(-1)
            cancelDebouncedSearchString()
            if(analyticRef.current  && sessionId){
                const payload = {
                    "data": {
                        "session_id": sessionId,
                        "close_status": "CANCEL"
                    }
                }
                logUserActivity.mutateAsync({url:import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close' ,payload})
                .then(() => setCreatePoSessionId(null))
                .catch(err => console.error(err))
            }
        })
    }, []);

    useEffect(() => {
        setProducts(userData.allProductsData);
    }, [userData?.allProductsData]);

    useEffect(()=>{
        setHandleSubmitValidation(false);
        paymentMethodChangeHandler().then(()=>{
            calculateTotalPrice();
            setHandleSubmitValidation(true);
        });
    },[watch('payment_method')]);

    useEffect(() => {
        if(getValues('price') && getValues('shipping_details.zip') && getValues('shipping_details.state_id')){
            setHandleSubmitValidation(false);
            calculateTotalPricing(true).then(()=>{
                setHandleSubmitValidation(true);
            });
        }
        else{
            setValue("sales_tax", parseFloat(0));
            setValue("depositAmount", parseFloat(0));
        }
    }, [debouncedMatTotal])

    useEffect(() => {
        onStateZipChange()
    }, [watch('shipping_details.zip'), watch('shipping_details.state_id')])

    const onStateZipChange = async()=>{
        const isValid:Boolean = await handleStateZipValidation();
        if(isValid)
        handleSalesTax();
    }

    useEffect(() => {
        calculateTotalPrice();
    }, [watch('price'), watch('sales_tax'), watch('depositAmount')]);

    const initializeCreatePOData = ()=>{
        const referenceData: any = userData.referenceData;
        setShowLoader(true);
        getBuyingPreference.mutateAsync()
        .then(res => {
            const buyingPreference = res.data.data;
            buyingPreference.userType = userRole.buyerUser;
            buyingPreference.stateRef = referenceData.ref_states;
            setBuyingPreference(buyingPreference);
            setBuyingPreferenceEmailId(buyingPreference ? buyingPreference.email_id : "");
            const deliveryReceivingHours = buyingPreference.user_delivery_receiving_availability_details;
            if(deliveryReceivingHours?.length !== 0){
                setValue('recevingHours', deliveryReceivingHours)
                const disableDeliveryDates = deliveryReceivingHours.every((deliveryReceivingHour:any)=> deliveryReceivingHour.from === "closed")
                setDisableDeliveryDate(disableDeliveryDates)
            }
            if (createPoData) {
                setValue('internal_po_number', createPoData.internal_po_number);
                setValue('shipping_details', createPoData.shipping_details);
                setValue('delivery_date', handleGetDeliveryDate(createPoData.delivery_date,buyingPreference.user_delivery_receiving_availability_details));
                setValue('cart_items', createPoData.cart_items);
                createPoData.cart_items.forEach((product: any, index: number) => {
                    if(product.descriptionObj) resetQtyAndPricePerUnitFields(index,product.descriptionObj);
                    updateValue(index)
                });
                setValue('payment_method', createPoData.payment_method);
                setValue('seller_price', createPoData.seller_price);
                setValue('price', createPoData.price);
                setValue('sales_tax', createPoData.sales_tax);
            }else{
                setValue('shipping_details.line1', buyingPreference.delivery_address_line1)
                setValue('shipping_details.city', buyingPreference.delivery_address_city)
                setValue('shipping_details.state_id', buyingPreference.delivery_address_state_id)
                setValue('shipping_details.zip', buyingPreference.delivery_address_zip)
                const convertedInDateFormat = addWorkingDaysToDate(buyingPreference.delivery_days_add_value ?? referenceData.ref_delivery_date[0].days_to_add);
                setValue('delivery_date', handleGetDeliveryDate(convertedInDateFormat,buyingPreference.user_delivery_receiving_availability_details))
                SetSendInvoicesToEmailData(buyingPreference.send_invoices_to);
                if (buyingPreference.default_payment_method === 'ACH_CREDIT') {
                    setValue('payment_method', 'ach_credit')
                    setValue('depositAmount',0);
                } else if (buyingPreference.default_payment_method === 'BUY_NOW_PAY_LATER') {
                    setValue('payment_method', 'bryzos_pay')
                }
                setValue('sales_tax', 0);
            }
            setDefaultPaymentOption(buyingPreference, paymentMethods, false);
            setTodayDate(new Date());
            setShowLoader(false);
        })
        .catch(err => {
            setOpenErrorDialog(true);
            setErrorMessage("Something went wrong. Please try again in sometime");
            setShowLoader(false);
            console.error(err)
        })
    }

    const calculateTotalPrice = ()=>{
        const materialTotal = +(watch('price') ?? 0);
        let totalPurchaseOrderPrice: number = 0;
        if (materialTotal) {
            totalPurchaseOrderPrice += materialTotal + (+(watch('sales_tax') ? watch('sales_tax') : 0)) + (+(watch('depositAmount') ?? 0))
        }
        setValue('totalPurchase',parseFloat(totalPurchaseOrderPrice));
    }

    useEffect(() => {
        if(deboucedSearchString){
            handleCreatePOSearch(searchStringData, null, lineSessionId)
        }
    }, [deboucedSearchString])

    const updateLineProduct = (index, product) => {
        setSelectedProduct(product);
        setValue(`cart_items.${index}.qty`, null)
        setValue(`cart_items.${index}.extended`, 0)
        setValue(`cart_items.${index}.seller_extended`, 0)
        if(product){
            resetQtyAndPricePerUnitFields(index, product);
            if (userPartData && Object.keys(userPartData)?.length) {
                setValue(`cart_items.${index}.product_tag`, userPartData[product?.Product_ID])
            }
        }
        else{
            setValue(`cart_items.${index}.price`, 0)
            setValue(`cart_items.${index}.seller_price`, 0);
            calculateMaterialTotalPrice();
        }
    }

    const resetQtyAndPricePerUnitFields = (index, product) => {
        const qtyUnitMData = product.QUM_Dropdown_Options.split(",")
        const priceUnitMData = product.PUM_Dropdown_Options.split(",")
        setValue(`cart_items.${index}.qty_um`,qtyUnitMData);
        setValue(`cart_items.${index}.price_um`,priceUnitMData);
        if (!createPoData?.cart_items?.[index]?.qty_unit) {
            setValue(`cart_items.${index}.qty_unit`, qtyUnitMData[0])
        } else {
            const qtyUnit = createPoData.cart_items[index].qty_unit;
            if (qtyUnitMData?.length && !qtyUnitMData.find(unit => unit === qtyUnit)) {
                setValue(`cart_items.${index}.qty_unit`, qtyUnitMData[0]);
            }
        }
        if (!createPoData?.cart_items?.[index]?.price_unit) {
            setValue(`cart_items.${index}.price_unit`, priceUnitMData[0])
        } else {
            const priceUnit = createPoData.cart_items[index].price_unit;
            if (priceUnitMData?.length && !priceUnitMData.find(unit => unit === priceUnit)) {
                setValue(`cart_items.${index}.price_unit`, priceUnitMData[0]);
            }
        }
        pricePerUnitChangeHandler(index, product);
    }

    const removeLineItem = (index)=>{
        remove(index);
        calculateMaterialTotalPrice();
    }

    const updateValue = (index: number)=>{
        if(getValues(`cart_items.${index}`).descriptionObj){
            updateLineItem(index);
            calculateMaterialTotalPrice();
        }
    }

    const onSubmit = (data) => {
        const date = new Date()
        if(dayjs(date).format('M/D/YYYY') === dayjs(todayDate).format('M/D/YYYY')){
            setShowLoader(true);
            const totalPurchaseValue = getValues('price');
            const totalSellerPurchaseValue = parseFloat(getValues('seller_price')).toFixed(2);
            const payload = {
                "data": {
                    "internal_po_number": data.internal_po_number,
                    "shipping_details": data.shipping_details,
                    "delivery_date": data.delivery_date,
                    "payment_method": getValues('payment_method'),
                    "seller_price": totalSellerPurchaseValue, 
                    "price": String(totalPurchaseValue),
                    "sales_tax": data.sales_tax,
                    "freight_term": "Delivered",
                    "cart_items": formatCartItems(getValues('cart_items'))
                }
            };
            setDisableCloseAnalytic(false)
            saveBuyerCheckout.mutateAsync(payload)
                .then(res => {
                    if (res.data.data.error_message) {
                        setOpenErrorDialog(true);
                        setErrorMessage(res.data.data.error_message);
                        setShowLoader(false);
                        saveUserActivity(null, res.data.data.error_message)
                        setDisableCloseAnalytic(true)
                    } else {
                        queryClient.invalidateQueries([reactQueryKeys.getUserPartData]);
                        const payload = {
                            "data": {
                                "session_id": sessionId,
                                "close_status": "ACCEPT"
                            }
                        }
                        logUserActivity.mutateAsync({url:import.meta.env.VITE_API_SERVICE + '/user/create_po_open_close' ,payload})
                        .catch(err => console.error(err))
                        navigate(routes.orderConfirmationPage, { state: { poNumber: res.data.data, jobNumber: data.internal_po_number, sendInvoicesTo: sendInvoicesToEmailData, selectedOptionPayment: data.payment_method, email_id: buyingPreferenceEmailId } })
                        setCreatePoData(null);
                        saveUserActivity(res.data.data, null);
                    }
                })
                .catch(err => {
                    saveUserActivity(null, (err?.message ?? err));
                    setDisableCloseAnalytic(true)
                    setOpenErrorDialog(true);
                        setErrorMessage("Something went wrong. Please try again in sometime");
                        setShowLoader(false);
                    console.error(err)
                })
        }else{
            setOpenErrorDialog(true);
            setErrorMessage('Selected delivery date is incorrect, please select correct delivery date.');
            setValue('delivery_date', null)
            handleDeliveryDateList()
            setTodayDate(new Date());
        }

        
    }

    const addNewRow = () => {
        append({...cartItem});
        const t = setTimeout(() => {
            clearTimeout(t);
            const element = document.getElementById(`combo-box-demo${fields.length}`);
            if (element) {
                element.focus()
            }
        }, (100));
    }

    const formatCartItems = (products) => {
        return products.map((item, index) => {
            if (item.descriptionObj) {
                const cartItem = {
                    "line_id": index + 1,
                    "description": item.descriptionObj.UI_Description,
                    "qty": item.qty,
                    "qty_unit": item.qty_unit,
                    "product_tag": item.product_tag,
                    "product_tag_mapping_id": item.descriptionObj.product_tag,
                    "price": item.price,
                    "price_unit": item.price_unit,
                    "extended": item.extended,
                    "product_id": item.descriptionObj.Product_ID,
                    "reference_product_id": item.descriptionObj.id,
                    "shape": item.descriptionObj.Key2,
                    "seller_price": item.seller_price,
                    "seller_extended": item.seller_extended.toFixed(2),
                    "buyer_pricing_lb": item.descriptionObj.Buyer_Pricing_LB.replace("$", ""),
                    "domestic_material_only": item.domesticMaterialOnly ?? false,
                    "buyer_calculation_price": item.buyerPricePerUnit,
                    "seller_calculation_price": item.sellerPricePerUnit,
                };
                return cartItem
            }
            return null
        }).filter(Boolean);
    }

    const handleGetDeliveryDate = (delivery_date,recevingHours) => {
        const date = dayjs(delivery_date)
        const convertDate = date.format('dddd')
        let checkDeliveryDate = null;
        recevingHours?.forEach((recevingHour)=>{
            if(recevingHour.is_user_available === 1 && convertDate.toLowerCase() === recevingHour.day.toLowerCase()){
                checkDeliveryDate = delivery_date
            }
        })
        return checkDeliveryDate;
    }

    const handleDeliveryDateList = () => {
        return deliveryDates.map(x => {
            const isDeliveryDateDisable = handleGetDeliveryDate(addWorkingDaysToDate(x.days_to_add),getValues('recevingHours'));
            return { title: x.delivery_date_string, value: addWorkingDaysToDate(x.days_to_add), disabled: !isDeliveryDateDisable }
        })
    }

    const saveUserActivity = (checkOutPoNumber, checkOutError) => {
        saveCreatePOUserActivity(sessionId, checkOutPoNumber, checkOutError);
    }

    const handleCreatePOSearch = (searchStringKeyword, status, descriptionLineSessionId) => {
        const payload = {
            "data": {
                "session_id": sessionId,
                "keyword" : searchStringKeyword,
                "status" : status,
                "source" : 'create-po',
                "line_session_id": descriptionLineSessionId,
            }
        }
        setSelectedProduct(null)
        saveProductSearch.mutateAsync(payload)
        .catch(err => console.error(err))
    }

    const getExportPoData = ()=>{
        return { ...getValues(), selectedOptionPayment: getValues('payment_method') };
    }
    
    const disablePlaceOrderButton = !isValid || Object.keys(errors).length || !handleSubmitValidation ||  watch('shipping_details.validating_state_id_zip') ||  (getValues('payment_method') === 'bryzos_pay' && buyingPreference?.bnpl_settings?.is_approved === null);
    return (
        <div>
            <div className={clsx(styles.createPoContent, 'bgBlurContent')}>
                <div className={styles.formInnerContent}>
                            <div className={styles.headerNoteCreatePO}>
                                <span className={styles.leftIcon}><WarningIcon /></span>

                                <span className={styles.headerNoteText}>All delivered material will be new (aka “prime”), fulfilled to the defined specification,
                                    loaded/packaged for forklift and/or magnetic offloading and have mill test reports.</span>
                                <span className={clsx(styles.headerNoteText, styles.marginTop8)}>The maximum bundle weight is 5,000 pounds.</span>
                                <span className={styles.rightIcon}><WarningIcon /></span>
                            </div>
                            <div className='w100'>
                                <div >
                                    <div className={styles.formInputGroup}>
                                        <span className={styles.lblInput}>
                                            Job / PO#
                                            <Tooltip
                                                title={JobPOTooltip()}
                                                arrow
                                                placement={'right'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'inputTooltip',
                                                }}
                                            >
                                                <span className={styles.questionIcon}>
                                                    <QuestionIcon className={styles.questionIcon1} />
                                                    <QuestionHoverIcon className={styles.questionIcon2} />
                                                    <QuestionBlackIcon className={styles.questionIcon4} />
                                                    <QuestionBlackHoverIcon className={styles.questionIcon3} />
                                                </span>

                                            </Tooltip>
                                        </span>
                                        <span className={styles.inputSection}>
                                            <input maxLength={20} type='text' {...register('internal_po_number')} placeholder='Enter Job Name or PO# for this purchase'
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                                    register("internal_po_number").onChange(e);
                                                }}
                                                onBlur={(e) => {
                                                    e.target.value = e.target.value.trim();
                                                    register("internal_po_number").onBlur(e);
                                                    saveUserActivity();
                                                }} />
                                        </span>
                                    </div>
                                    <div className={styles.formInputGroup}>
                                        <span className={styles.lblInput}>
                                            Delivery To
                                            <Tooltip
                                                title={DeliveryToTooltip()}
                                                arrow
                                                placement={'right'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'inputTooltip',
                                                }}
                                            >
                                                <span className={styles.questionIcon}>
                                                    <QuestionIcon className={styles.questionIcon1} />
                                                    <QuestionHoverIcon className={styles.questionIcon2} />
                                                    <QuestionBlackIcon className={styles.questionIcon4} />
                                                    <QuestionBlackHoverIcon className={styles.questionIcon3} />
                                                </span>

                                            </Tooltip>

                                        </span>
                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                            <Tooltip
                                                title={showLine1Tooltip && watch("shipping_details.line1")}
                                                arrow
                                                placement={'bottom'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'addressTooltip',
                                                }}
                                            >
                                                <input type='text' {...register("shipping_details.line1")} placeholder='Address' onFocus={() => setShowLine1Tooltip(false)}
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                                        register("shipping_details.line1").onChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        setShowLine1Tooltip(true);
                                                        e.target.value = e.target.value.trim();
                                                        register("shipping_details.line1").onBlur(e);
                                                        saveUserActivity();
                                                    }} />
                                            </Tooltip>
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.cityInput, styles.bdrRadius0, styles.bdrRight0)}>
                                        <Tooltip
                                                title={showCityTooltip && watch("shipping_details.city")}
                                                arrow
                                                placement={'bottom'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'addressTooltip',
                                                }}
                                            >
                                                <input type='text' {...register("shipping_details.city")} placeholder='City' onFocus={() => setShowCityTooltip(false)}
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                                        register("shipping_details.city").onChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        setShowCityTooltip(true);
                                                        e.target.value = e.target.value.trim();
                                                        register("shipping_details.city").onBlur(e);
                                                        saveUserActivity();
                                                    }} />
                                            </Tooltip>
                                            
                                        </span>
                                        <Tooltip
                                                title={errors?.shipping_details?.state_id?.message}
                                                arrow
                                                placement={'bottom'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'addressTooltip',
                                                }}
                                            >
                                            <span className={clsx(styles.inputSection,styles.paddingLR0, styles.stateInput, styles.bdrRadius0, styles.bdrRight0, errors?.shipping_details?.state_id?.message && styles.errorInput )}>
                                                <CustomMenu
                                                    control={control}
                                                    name={'shipping_details.state_id'}
                                                    placeholder={'State'}
                                                    MenuProps={{
                                                        classes: {
                                                            paper: styles.Dropdownpaper,
                                                            list: styles.muiMenuList,
                                                        },
                                                    }}
                                                    onChange={() => saveUserActivity()}
                                                    items={states.map(x => ({ title: x.code, value: x.id }))}
                                                    className={'selectDropdown SelectDeliveryDate'}
                                                    
                                                    />

                                            </span>
                                        </Tooltip>
                                        <Tooltip
                                                title={ errors?.shipping_details?.zip?.message}
                                                arrow
                                                placement={'bottom'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'addressTooltip',
                                                }}
                                            >
                                        <span className={clsx(styles.inputSection, styles.zipCodeInput, errors?.shipping_details?.zip?.message && styles.errorInput )}>
                                            <input type='text' {...register("shipping_details.zip")} placeholder='Zip Code' 
                                                onChange={(e) => {
                                                register("shipping_details.zip").onChange(e);
                                                const zipCode = e.target.value.replace(/\D/g, '');
                                                setValue("shipping_details.zip", zipCode);
                                            }} maxLength="5"
                                            onBlur={(e) => {
                                                e.target.value = e.target.value.trim();
                                                register("shipping_details.zip").onBlur(e);
                                                saveUserActivity();
                                            }}/>
                                        </span>
                                            </Tooltip>
                                    </div>
                                    <div className={clsx(styles.formInputGroup,deliveryStateFocusEnable && styles.formInputGroupFocus)} id='delivery-date-div'>
                                        <span className={styles.lblInput}>
                                            Delivery Date
                                            <Tooltip
                                                title={DeliveryDateTooltip()}
                                                arrow
                                                placement={'right'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'inputTooltip',
                                                }}
                                            >
                                                <span className={styles.questionIcon}>
                                                    <QuestionIcon className={styles.questionIcon1} />
                                                    <QuestionHoverIcon className={styles.questionIcon2} />
                                                    <QuestionBlackIcon className={styles.questionIcon4} />
                                                    <QuestionBlackHoverIcon className={styles.questionIcon3} />
                                                </span>

                                            </Tooltip>
                                        </span>
                                        <span className={clsx(styles.inputSection,styles.paddingLR0)}>
                                            <CustomMenu
                                                control={control}
                                                name={'delivery_date'}
                                                placeholder={'Choose One'}
                                                MenuProps={{
                                                    classes: {
                                                        paper: styles.Dropdownpaper,
                                                        list: styles.muiMenuList,
                                                    },
                                                }}
                                                onfocus= {() => {
                                                    setDeliveryStateFocusEnable(true)
                                                }}
                                                onBlur= {() => {
                                                    setDeliveryStateFocusEnable(false)
                                                }}
                                                onChange={() => saveUserActivity()}
                                                disabled = {disableDeliveryDate}
                                                onClick={() => {
                                                    if(disableDeliveryDate){
                                                        setOpenErrorDialog(true)
                                                        setErrorMessage("No dates available. Please add at least one day as receiving hours in settings.")
                                                    }
                                                }}
                                                renderValue={(val) => val ? val : 'Choose One'}
                                                items={handleDeliveryDateList()}
                                                className={'selectDropdown SelectDeliveryDate'}
                                            />
                                        </span>

                                    </div>
                                    <div className={styles.addPoLineTable}>
                                        <table >
                                            <thead>
                                                <tr>
                                                    <th><span>LN</span></th>
                                                    <th><span>DESCRIPTION</span></th>
                                                    <th><span>QTY</span></th>
                                                    <th>    
                                                    
                                                        <span className='questionIconPriceChange'>
                                                            $/UM 
                                                            <Tooltip
                                                                title={chooseYourUnitTooltip()}
                                                                arrow
                                                                placement={'bottom'}
                                                                disableInteractive
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 600 }}
                                                                classes={{
                                                                    tooltip: 'chooseYourUnitTooltip',
                                                                }}
                                                            >
                                                                <label>
                                                                    <QuestionIcon className='questionIcon1' />
                                                                    <QuestionHoverIcon className='questionIcon2' />
                                                                </label>
                                                                </Tooltip>
                                                                </span>
                                                        
                                                    </th>
                                                    <th colSpan={2}><span>EXT ($)</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fields.map((item, index) => (
                                                        <CreatePoTile key={item.id}
                                                            index={index}
                                                            register={register}
                                                            fields={fields}
                                                            updateValue={updateValue}
                                                            updateLineProduct={updateLineProduct}
                                                            products={products}
                                                            setValue={setValue}
                                                            watch={watch}
                                                            errors={errors}
                                                            control={control}
                                                            getValues={getValues}
                                                            pricePerUnitChangeHandler={pricePerUnitChangeHandler}
                                                            removeLineItem={removeLineItem}
                                                            item={item}
                                                            userPartData={userPartData}
                                                            sessionId={sessionId}
                                                            selectedProduct={selectedProduct}
                                                            searchStringData={searchStringData}
                                                            setSearchString={setSearchString}
                                                            setLineSessionId={setLineSessionId}
                                                            lineSessionId={lineSessionId}
                                                            handleCreatePOSearch={handleCreatePOSearch}
                                                            setHandleSubmitValidation={setHandleSubmitValidation}
                                                            handleSalesTax={handleSalesTax}
                                                            apiCallInProgress={apiCallInProgress}
                                                            quantitySizeValidator={quantitySizeValidator}
                                                            saveUserLineActivity={saveUserLineActivity}
                                                        />
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <CommonTooltip
                                            title={'Click this + to add more products to your purchase order'}
                                            tooltiplabel={<>
                                                <div className={styles.addMoreLine}>
                                                    <button onClick={() => { addNewRow(); }}>
                                                        <AddLineIcon className={styles.addLine} />
                                                        <AddLineHoverIcon className={styles.addLineHover} />
                                                    </button>
                                                    <span></span>
                                                </div>
                                            
                                            </>
                                                
                                            }
                                            placement={'bottom-start'}
                                            classes={{
                                                popper: 'tooltipPopper',
                                                tooltip: 'tooltipMain tooltipSearch tooltipAddRow',
                                                arrow: 'tooltipArrow'
                                            }}
                                            localStorageKey="addMoreLineTooltip"
                                        />
                                    <div className={styles.totalAmt}>
                                        <div className={styles.featureActions}>
                                            <div>
                                                {(channelWindow?.fetchPdf || channelWindow?.generatePdf) &&  <PdfMakePage getExportPoData={getExportPoData} buyingPreferenceData={buyingPreference} disabled={disablePlaceOrderButton} />}
                                            </div>
                                            {buyingPreference?.bnpl_settings?.is_approved === null && <button className={styles.refreshNetTerm} onClick={initializeCreatePOData}><RefreshNet30Icon /><span>Click to check status (Net 30 Terms)</span></button>}
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><span className={styles.saleTax}>Material Total</span></td>
                                                        <td><span className={styles.saleTax}>$</span></td>
                                                    <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`price`))}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className={styles.saleTax}>
                                                        Sales Tax
                                                        <Tooltip
                                                            title={SalesTaxTooltip()}
                                                            arrow
                                                            placement={'top-end'}
                                                            disableInteractive
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 200 }}
                                                            classes={{
                                                                tooltip: 'inputTooltip',
                                                            }}
                                                        >
                                                            <span className={styles.questionIcon}>
                                                                <QuestionIcon className={styles.questionIcon1} />
                                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                                            </span>

                                                        </Tooltip>

                                                    </span>
                                                    </td>
                                                    <td><span className={styles.saleTax}>$</span></td>
                                                    <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`sales_tax`))}</span></td>
                                                </tr>
                                                <tr className={clsx(watch('payment_method') !== 'ach_credit' && 'displayNone')}>
                                                    <td><span className={styles.saleTax}>Deposit</span></td>
                                                    <td><span className={styles.saleTax}>$</span></td>
                                                    <td><span className={styles.saleTax}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`depositAmount`))}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className={styles.totalPurchase}>Total Purchase</span></td>
                                                    <td><span className={styles.totalPurchase}>$</span></td>
                                                    <td><span className={styles.totalPurchase}>{formatToTwoDecimalPlaces(!apiCallInProgress && watch(`totalPurchase`))}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={styles.btnOfCreatePo}>
                                        <div className={styles.selectedpayment}>

                                        <CommonTooltip
                                            title={'Click this drop down box to select what method of payment you want to use for this order.'}
                                            tooltiplabel={
                                                <CustomMenu
                                                    control={control}
                                                    name={'payment_method'}
                                                    placeholder={'Method of Payment'}
                                                    className={styles.selectPaymentMethod}
                                                    MenuProps={{
                                                        classes: {
                                                            paper: styles.selectPaymentMethodPaper,
                                                        },
                                                    }}
                                                    onOpen={() => setShowPaymentOptionTooltip(false)}
                                                    onClose={() => setShowPaymentOptionTooltip(undefined)}
                                                    items={paymentMethods}
                                                    IconComponent={DropdownIcon}
                                                    onChange={() => saveUserActivity()}
                                                />
                                            }
                                            open={showPaymentOptionTooltip}
                                            placement={'bottom-start'}
                                            classes={{
                                                popper: 'tooltipPopper',
                                                tooltip: 'tooltipMain tooltipSearch tooltipPayment',
                                                arrow: 'tooltipArrow'
                                            }}
                                            localStorageKey="selectPaymentTooltip"
                                        />

                                        </div>
                                        <button
                                            onClick={handleSubmit(onSubmit)}
                                            disabled={disablePlaceOrderButton}
                                            className={styles.placeOrder}>Place Order</button>
                                    </div>
                                </div>

                                <div className={styles.textOfCreatePo}>
                                    <p>After clicking “Place Order,” the next screen will be your order confirmation.</p>
                                    <p>Additionally, you will receive an email confirmation with full order details.</p>
                                </div>
                                <div className={styles.backBtnMain}>
                                    <button onClick={() => { setCreatePoData(null); navigate(backNavigation) }} className={styles.cancelPOGoBack}>Cancel PO & Go Back</button>
                                </div>
                            </div>
                        <Dialog
                            open={openErrorDialog}
                            onClose={(event) => setOpenErrorDialog(false)}
                            transitionDuration={200}
                            hideBackdrop
                            classes={{
                                root: styles.ErrorDialog,
                                paper: styles.dialogContent
                            }}

                        >
                            <p>{errorMessage}</p>
                            <button className={styles.submitBtn} onClick={(event) => {setOpenErrorDialog(false); disableDeliveryDate && navigate(routes.buyerSettingPage)}}>Ok</button>
                        </Dialog>

                </div>
            </div>
        </div>
    );
}


const CreatePo = () => {
    const navigate = useNavigate();

    const navigateToSettings = () => {
        navigate(routes.buyerSettingPage);
    }

    return (
        <InstantPurchasingWrapper
            navigateToSettings = {navigateToSettings}
        >
            <InstantPurchasing />
        </InstantPurchasingWrapper>
    )
}
export default CreatePo;
