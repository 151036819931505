import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Chat.module.scss';
import { ChatMessages } from './chat-messages/chat-messages';
import { useGlobalStore } from '@bryzos/giss-ui-library';
import dayjs from 'dayjs';
import InputEmoji from "react-input-emoji";
import { ReactComponent as ScrollDownImage } from '../../assets/images/uom-dropdown.svg';

export const Chat = () => {
    const channelMessagesRef = useRef<any>([]);
    const getMessageInProgress = useRef(false);
    const [channelMessages, setChannelMessages] = useState(channelMessagesRef.current);
    const [inputMessage, setInputMessage] = useState('');
    const { userData, sdk, setShowLoader }: any = useGlobalStore();

    const chatViewPort = useRef<HTMLDivElement>(null);
    const startLoader = !(channelMessagesRef.current?.length || channelMessages?.length);
    const [skipCount, setSkipCount] = useState(0);
    const [noMoreMessageToLoad, setNoMoreMessageToLoad] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [showScrollDownButton, setShowScrollDownButton] = useState(false);
    const lastSeenMessageRef = useRef(null);
    const messagePool = useRef<Set<string>>(new Set());

    useEffect(() => {
        if (!sdk) {
            return;
        }
        (async () => {
            sdk.on('message', (message: any) => {
                if (!channelMessagesRef.current.find((obj: any) => obj._id === message._id)) {
                    autoScrollToBottom(chatViewPort);
                    setMessages([message, ...channelMessagesRef.current]);
                }
            });

            sdk.on('messageDeleted', (message: any) => {
                setMessages(channelMessagesRef.current.filter((msg: any) => msg._id !== message._id));
            });
        })();
    }, [sdk]);

    useEffect(() => {
        if (!sdk) {
            return;
        }
        getMessages();
    }, [sdk, userData]);

    const handleScroll = () => {
        const container:HTMLDivElement|null  = chatViewPort.current;
        isScrollAtBottom(chatViewPort) ? setShowScrollDownButton(false) : setShowScrollDownButton(true);
        if(
            container &&
            container.scrollTop <= 100 &&
            !isFetching &&
            container.scrollHeight - container.clientHeight > 0
        ) {
            if(!noMoreMessageToLoad){
                setIsFetching(true);
                if(channelMessages.length > 0)
                lastSeenMessageRef.current = channelMessages[channelMessages.length-1]._id;
                loadMessageOnScroll();
            }
        }
    }

    const autoScrollToBottom = (chatViewPort: React.RefObject<HTMLDivElement>) => {
        if (isScrollAtBottom(chatViewPort)) {
            setTimeout(() => {
                scrollToBottom();
            }, 300);
        }
    }

    const isScrollAtBottom = (_chatViewPort: React.RefObject<HTMLDivElement>): boolean => {
        const { current } = _chatViewPort;
        if (!current) return false;
        return (current.scrollHeight - current.scrollTop) - current.clientHeight < 10 ? true : false;
    };

    const scrollToBottom = () => {
        const { current } = chatViewPort;
        if (current) {
            current.scrollTop = current.scrollHeight;
        }
    };

    const loadMessageOnScroll = async () => {
        let _channelMessages: any[] = [];
        const skipIncrement = 10;
        let skip = skipCount;
        const loadOnEveryScrollUp = 3;
        let stopGetMessageLoop = false
        let loopCount = 0;
        const messageSet:Set<string> = messagePool.current;
        while (!noMoreMessageToLoad && !stopGetMessageLoop && loopCount < loadOnEveryScrollUp) {
            const unfilteredMessages = (await sdk.getMessages(skip))?.messages;
            if (unfilteredMessages?.length > 0) {
                const messages = unfilteredMessages.filter((msg: any) => {
                    if(!messageSet.has(msg._id)){
                        messageSet.add(msg._id);
                        return true;
                    }
                    return false;
                })
                skip += skipIncrement;
                if(messages.length > 0){
                    const today = dayjs();
                    const lastChat = messages[messages.length - 1];
                    const created = dayjs(lastChat.created);

                    if (today.diff(created, "days") > 15) {
                        stopGetMessageLoop = true;
                        const filteredMessages = messages.filter((msg: any) => today.diff(msg.created, "days") <= 15);

                        _channelMessages = _channelMessages.concat(filteredMessages);
                    } else {
                        _channelMessages = _channelMessages.concat(messages);
                    }
                }
            } else {
                stopGetMessageLoop = true;
            }
            loopCount++;
        }
        setIsFetching(false);
        setNoMoreMessageToLoad(stopGetMessageLoop);
        setSkipCount(skip);
        const messageList = channelMessages.concat(_channelMessages);
        setMessages(messageList);
    }

    const getMessages = async () => {
        if (getMessageInProgress.current) {
            return;
        }
        getMessageInProgress.current = true;

        if (startLoader) {
            setShowLoader(true);
        }
        await loadMessageOnScroll();
        if (startLoader) {
            setShowLoader(false);
        }
        getMessageInProgress.current = false;
        autoScrollToBottom(chatViewPort)
    };

    const setMessages = (messages: any[]) => {
        channelMessagesRef.current = messages;
        setChannelMessages(messages);
    };

    const sendMessage = async () => {
        if (sdk) {
            const text:string = inputMessage.replace(/^(\s*<\/?br\s*\/?>\s*)+|(\s*<\/?br\s*\/?>\s*)+$/gi, '');
            text.trim();
            scrollToBottom();
            sdk.sendMessage(text);
            setInputMessage('');
        }
    };

    const scrollToLastSeenMessage = () => {
        const lastSeenMessageId = `message-${lastSeenMessageRef.current}`;
        if (lastSeenMessageId && chatViewPort.current) {
          const lastSeenMessageElement = document.getElementById(lastSeenMessageId);
          if (lastSeenMessageElement) {
            lastSeenMessageElement.scrollIntoView();
          }
        }
    };

    useEffect(()=>{
        if(!isFetching)
        scrollToLastSeenMessage();
    },[lastSeenMessageRef.current, isFetching])

    return (
        <div className={styles.chatMain}>
            <div className={styles.chatContainer}>
                <div className={styles.innerChat} ref={chatViewPort} onScroll={handleScroll}>
                    <div className={styles.chat}>
                        <ChatMessages
                            sdk={sdk}
                            channelMessages={channelMessages}
                            userData={userData?.data}
                            startLoader={startLoader}
                            isFetching={isFetching}
                        />
                    </div>
                    { showScrollDownButton && <div className={styles.scrollDownImage}>
                        <span>
                            <ScrollDownImage onClick={scrollToBottom} />
                        </span>
                    </div>}
                </div>
                <div className={styles.inputBox}>
                    <>
                        <InputEmoji
                            value={inputMessage}
                            onChange={setInputMessage}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    sendMessage();
                                }
                            }}
                            shouldReturn={true}
                            shouldConvertEmojiToImage={false} />
                        <button type='button' onClick={sendMessage} disabled={!inputMessage}>Send</button>
                    </>
                </div>
            </div>
        </div>
    );
};
