// @ts-nocheck
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import { fileType, routes } from '../../common';
import { useGlobalStore, getSocketConnection, resetConnection, userRole } from '@bryzos/giss-ui-library';
import { Auth } from 'aws-amplify';
import { downloadFiles } from '../helper';
import { Dialog } from '@mui/material';

function Tnc() {
    const {backNavigation, setShowLoader, userData, setUserData, setResetHeaderConfig, setDisableBryzosNavigation, isChangePasswordRequired} = useGlobalStore();
    const navigate = useNavigate();
    const [tandCData, setTandCData] = useState({});
    const [, setTncBottom] = useState(false);
    const [tnc, setTnc] = useState('');
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    const location = useLocation();
    const { isViewMode, navigateTo } = location.state;
    
    useEffect(() => {
        if (!userData.data) {
            navigate(routes.loginPage);
        }
        const referenceData = userData.referenceData;
        if(referenceData)
        referenceData.ref_bryzos_terms_conditions.forEach(termsAndCondition => {
            if(userData.data.type === termsAndCondition.type){
                setTandCData(termsAndCondition)
            }
        });
    }, [userData]);
    const handleLogout = async () => {
      if (userData.data && !isLoggingOut) {
        setIsLoggingOut(true);
        const userEmail = {
          data: {
            email_id: userData.data.email_id,
          },
        };

        axios
          .post(import.meta.env.VITE_API_SERVICE + "/user/logout", userEmail)
          .finally(async () => {
            try {
              await Auth.signOut();
            } catch (error) {
              console.log("error signing out: ", error);
            }
            navigate(routes.loginPage);
            setIsLoggingOut(false);
            setUserData({});
            console.log("header-----");
            const socketInstance = getSocketConnection();
            socketInstance?.disconnect();
            for (let i = 0; i < sessionStorage.length; i++) {
              const key = sessionStorage.key(i);
              if (key !== "isSticky" && key !== "localStorageStickyItemKey") {
                sessionStorage.removeItem(key);
              }
            }
            document.cookie.split(";").forEach((c) => {
              document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
            });
            resetConnection();
            setResetHeaderConfig(true);
            setDisableBryzosNavigation(true);
          });
      }
    };
    const handleSubmitTnc = () => {
        const payload = {
            data: {
                "bryzos_terms_condtion_id": tandCData.id,
                "terms_conditions_version": tandCData.terms_conditions_version,
                "email_id": userData.data.email_id
            }
        };
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/saveTermsCondition', payload).then(response => {
            if (response.data !== null) {
                setDisableBryzosNavigation(false);
                if(isChangePasswordRequired)
                navigate(routes.changePassword)
                else
                navigate(routes.homePage);
            }
        })
            .catch(error => { console.error(error) });
    }
    const FetchHtml = async () => {
        const response = await fetch(tandCData.cloudfront_url+"?ver="+new Date().getTime());
        return await response.text();
    };
    const SetHtml = async () => {
        await FetchHtml().then((text) => {
            setTnc(text);
            setShowLoader(false);
        });
    };
    useEffect(() => {
        if(Object.keys(tandCData).length !== 0){
            SetHtml(true)
        }
    },[tandCData]);

    const handleScroll = (e) => {
        const tncWorking = e.target.scrollHeight - e.target.scrollTop
        const bottom =
            Math.floor(tncWorking) === e.target.clientHeight;
        if (bottom) {
            setTncBottom(bottom);
        }
    };
    
    const downloadReports = () => {
        const url = userData.data.type === userRole.sellerUser ? import.meta.env.VITE_FILE_URL_DOWNLOAD_SELLER_TNC_PDF : import.meta.env.VITE_FILE_URL_DOWNLOAD_BUYER_TNC_PDF;
        const showError = downloadFiles(`${url}?ver=${new Date().getTime()}`, 'Tnc', fileType.pdf);
        showError.then(res => {
            if (res) {
                setOpenErrorDialog(false);
            } else {
                setOpenErrorDialog(true);
            }
        })
    }

    return (
        <>
          {/* <div className='bgImg'>
          <div className='headerPanel commonHeader'>
                <Header />
            </div> */}
            <div className='widgetBody tncBg'>
                <div className='termsAndConditions'>
                  
                        <div className='TncContainer'>
                            <div className='termsAndConditions' onScroll={handleScroll}
                            >
                                <div>
                                    <div className='tncHead'>
                                        <div>Bryzos Instant Pricing Desktop Widget Terms of Use</div>
                                    </div>
                                    <p className='effectiveDate'>Effective as of: March 8, 2023</p>
                                    <div  dangerouslySetInnerHTML={{ __html: tnc }}></div>

                                </div>
                            </div>
                        </div>

                </div>
                <div className='tncButtons'>
                    {isViewMode ?
                        <>
                            <button onClick={()=>navigate(backNavigation)} className='disagreeBtn'>Back</button>
                            <div onClick={() => { downloadReports() }} className='downloadTnCBtn'> Click here to download T&C </div>
                        </>
                        :
                        <>
                        <button onClick={handleLogout} className='disagreeBtn'>Disagree</button>
                        <div onClick={() => { downloadReports() }} className='downloadTnCBtn'> Click here to download T&C </div>
                        <button className='agreeBtn1' onClick={handleSubmitTnc}>Agree</button>
                        </>
                    }

                    
                    
                </div>
            </div>
            <>
                <Dialog
                    open={openErrorDialog}
                    onClose={(event) => setOpenErrorDialog(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: 'ErrorDialog',
                        paper: 'dialogContent'
                    }}

                >
                    <p>No data found. Please try again in sometime</p>
                    <button className={'submitBtn'} onClick={(event) => setOpenErrorDialog(false)}>Ok</button>
                </Dialog>
            </>
          {/* </div> */}
          
        </>
    );
}
export default Tnc;
