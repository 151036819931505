// @ts-nocheck
import React, { memo,  useEffect, useState } from 'react';
import Header from '../header';
import styles from './orderConfirmation.module.scss'
import { ReactComponent as ReturntoSearchIcon } from '../../assets/images/ReturntoSearch.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { ReactComponent as Staroutlinedicon } from '../../assets/images/StarOutlined.svg';
import { commomKeys, fileType, orderConfirmationConst, prefixUrl, routes } from '../../../common';
import { useLocation, useNavigate } from 'react-router';
import { Dialog, Fade, Tooltip } from '@mui/material';
import { BUploadYourSOTooltip } from '../../tooltip';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { downloadFiles, navigatePage } from '../../helper';
import DialogPopup from '../../component/DialogPopup/DialogPopup';
import { useGlobalStore } from '@bryzos/giss-ui-library';

const OrderConfirmationBuyer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {setShowLoader, userData} = useGlobalStore();
    const poData = location.state;
    const selectedOptionPayment = poData.selectedOptionPayment;
    const emailId = poData.email_id;
    const PAYMENT_TYPE_ACH_CREDIT= "ach_credit";
    const [rating, setRating] = useState(0);
    const [buyerPoUrls, setBuyerPoUrls] = useState([]);
    const [disableUploadYourPo, setDisableUploadYourPo] = useState(false);
    const [showDialogConfirmationPopup, setShowDialogConfirmationPopup] = useState(false);
    const [uploadPoProgress, setUploadPoProgress] = useState(null);
    const [cancelOrderDisableBtn, setCancelOrderDisableBtn] = useState(false);
    //Dialog Popup 
    const [showDialogUploadPoPopup, setShowDialogUploadPoPopup] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogBtnTitle, setDialogBtnTitle] = useState('');
    const [dialogType, setDialogType] = useState('');
    //

    useEffect(()=>{
        setShowLoader(false);
    },[])

    const handleCheckboxClick = (value) => {
        setRating(value);
        const poRatingPayload = {
            "data": {
                "po_number": poData.poNumber,
                "rating": value.toString()
            }
        }
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/purchaseOrderRatings', poRatingPayload)
            .then(res => {})
            .catch(err => console.error(err))
    };

    useEffect(() => {
        function goToMainPage(event) {
            if (event.key === 'Enter') {
                navigate(routes.homePage);

            }
        }

        document.addEventListener('keydown', goToMainPage)
        return (() => {
            document.removeEventListener('keydown', goToMainPage)
        })
    }, [navigate])
    const uploadPoFile = async (event) => {
        const file = event.target.files[0];

        if (event.target.files.length !== 0) {
            // setUploadCertName1(file.name);
            setDisableUploadYourPo(true);
            setUploadPoProgress(true);
            let index = file.name.length - 1;
            for (; index >= 0; index--) {
                if (file.name.charAt(index) === '.') {
                    break;
                }
            }
            const ext = file.name.substring(index + 1, file.name.length);

            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + userData.data.id + '/' + prefixUrl.buyerPo + '-' + uuidv4() + '.' + ext;
            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_SETTINGS_PO_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300

                }
            }
            let buyerPoUrl = 'https://' + payload.data.bucket_name + ".s3.amazonaws.com/" + payload.data.object_key;

            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    axios.put(signedUrl, file)
                        .then(response => {
                            if (response.status === 200) {
                                const payload = {
                                    "data": {
                                        "po_number": poData.poNumber,
                                        "upload_po_s3_urls": [
                                            buyerPoUrl
                                        ]
                                    }
                                }
                                axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/uploadPoS3Url', payload)
                                    .then(response => {
                                        setShowDialogUploadPoPopup(true);
                                        setDialogTitle(commomKeys.uploadSuccessful)
                                        setDialogContent(orderConfirmationConst.uploadPoDialogContent)
                                        setDialogBtnTitle(commomKeys.successBtnTitle)
                                        setDialogType(commomKeys.actionStatus.success)
                                        setUploadPoProgress(false);
                                    })
                                    .catch(err => {
                                        setDisableUploadYourPo(false);
                                        setUploadPoProgress(null);
                                        setShowDialogUploadPoPopup(true);
                                        setDialogContent(commomKeys.errorContent)
                                        setDialogBtnTitle(commomKeys.errorBtnTitle)
                                        setDialogType(commomKeys.actionStatus.error)
                                        console.error(err)

                                    })

                            }
                        })
                        .catch(error => {
                            setDisableUploadYourPo(false);
                            setUploadPoProgress(null);
                            setShowDialogUploadPoPopup(true);
                            setDialogContent(commomKeys.errorContent)
                            setDialogBtnTitle(commomKeys.errorBtnTitle)
                            setDialogType(commomKeys.actionStatus.error)
                            console.error(error)
                        })
                })
                .catch(error => {
                    setDisableUploadYourPo(false);
                    setUploadPoProgress(null);
                    setShowDialogUploadPoPopup(true);
                    setDialogContent(commomKeys.errorContent)
                    setDialogBtnTitle(commomKeys.errorBtnTitle)
                    setDialogType(commomKeys.actionStatus.error)
                    console.error(error)
                })

        }
    }

    const showW9File = (fileUrl, fileName, fileType) => {
        const showError = downloadFiles(fileUrl, fileName, fileType)
        showError.then(res => {
            if (res) {
                //  setOpenErrorDialog(false);
            } else {
                //  setOpenErrorDialog(true);
            }
        })

    }

    const cancelOrder = () => {
        setCancelOrderDisableBtn(true)
        const payload = {
            data: {
                po_number: poData.poNumber,
                type: orderConfirmationConst.buyerCancel
            }
        }
        axios.post(import.meta.env.VITE_API_ADMIN_SERVICE_NODE + '/cancel_order/user', payload)
            .then(res => {
                navigate(-1);
                setDisableUploadYourPo(false);
            })
            .catch(err => {setCancelOrderDisableBtn(false);console.error(err)});
    };

    return (
        <div>
            {/* <div className={clsx(styles.BuyerSetting, 'bgImg')}>
                <div className='headerPanel commonHeader'>
                    <Header />
                </div> */}
                <div className={clsx(styles.buyerorderConfirmationContent,'bgBlurContent')}>
                    <span className={styles.orderConfirmation}>Thanks for the Order !!</span>
                    <div className={styles.emailHeder} >
                        <span className={styles.orderEmail}>We have just emailed the order </span>
                        <br />
                        {selectedOptionPayment === PAYMENT_TYPE_ACH_CREDIT ?
                        <span className={styles.orderEmail}> confirmation to <span className={styles.emailIdInvoice}>{userData.data.email_id} <span className={styles.orderEmail}>and will send invoice to</span> {poData.sendInvoicesTo ? poData.sendInvoicesTo : emailId}</span>.</span> :
                        <span className={styles.orderEmail}> confirmation to <span className={styles.emailIdInvoice}>{userData.data.email_id}</span>.</span>
                        }
                    </div>
                    <div className={styles.poBoxOne}>
                        <div className={styles.poNumberCreator}>{poData.poNumber}<span className={styles.poNumber}>(PO# {poData.jobNumber})</span></div>
                        
                    </div>
                    <div className={styles.uploadYourPo1}>

                        <label>
                            <div className={clsx(styles.uploadYourPoDiv, (disableUploadYourPo) && styles.disabled)}>
                                {uploadPoProgress === true ?
                                    <span className={styles.poNumber}>Uploading </span> :
                                    uploadPoProgress === false ?
                                        <span className={styles.poNumber}>PO Uploaded </span> :
                                        <>
                                            <span className={styles.poNumber}>Upload Your PO </span>
                                            <span className={styles.emailIdInter}>or, email to po@bryzos.com</span>
                                        </>
                                }
                            </div>
                            <input type='file' onChange={uploadPoFile}  disabled={disableUploadYourPo} />
                        </label>
                        <DialogPopup
                            dialogTitle={dialogTitle}
                            dialogContent={dialogContent}
                            dialogBtnTitle={dialogBtnTitle}
                            type={dialogType}
                            open={showDialogUploadPoPopup}
                            onClose={() => setShowDialogUploadPoPopup(false)}
                        />
                        <Tooltip
                            title={BUploadYourSOTooltip()}
                            arrow
                            placement={'left'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 200 }}
                            classes={{
                                tooltip: 'inputTooltip',
                            }}
                        >
                            <div className={styles.svgDiv}>
                                <QuestionIcon className={styles.questionIcon1} />
                                <QuestionHoverIcon className={styles.questionIcon2} />
                            </div>

                        </Tooltip>
                    </div>
                    {selectedOptionPayment === PAYMENT_TYPE_ACH_CREDIT && (
                        <div className={styles.uploadYourPo}>
                            <span className={styles.downoadInvoiceBtn}>Download Invoice<br />
                                <span className={styles.emailIdInter}>Order cannot be fulfilled until payment received</span></span>
                        </div>
                    )}
                    <div className={styles.uploadYourPo}>
                        <span className={styles.poNumber}>Download Confirmation & <button onClick={() => { showW9File(import.meta.env.VITE_FILE_URL_W9_CERTIFICATE_PDF_LINK, 'W-9', fileType.pdf) }}>W-9</button></span>
                    </div>
                    <div onClick={() => { navigatePage(location.pathname, {path: routes.createPoPage}) }} className={clsx(styles.uploadYourPo, styles.pointer)}>
                        <span className={styles.poNumber}>Create Another Purchase</span>
                    </div>
                    <div onClick={() => { setShowDialogConfirmationPopup(true) }} className={clsx(styles.uploadYourPo, styles.pointer)}>
                        <span className={styles.poNumber}>Cancel Order</span>
                    </div>
                    <div className={styles.setRatingBox}>
                        {/* <span><Staroutlinedicon /><Staroutlinedicon /><Staroutlinedicon /><Staroutlinedicon /><Staroutlinedicon /></span> */}
                        <div className="star-rating">
                            <input type="checkbox" id="star1" name="rating" value="1" checked={rating >= 1}
                                onChange={() => handleCheckboxClick(1)} />
                            <label htmlFor="star1"></label>
                            <input type="checkbox" id="star2" name="rating" value="2" checked={rating >= 2}
                                onChange={() => handleCheckboxClick(2)} />
                            <label htmlFor="star2"></label>
                            <input type="checkbox" id="star3" name="rating" value="3" checked={rating >= 3}
                                onChange={() => handleCheckboxClick(3)} />
                            <label htmlFor="star3"></label>
                            <input type="checkbox" id="star4" name="rating" value="4" checked={rating >= 4}
                                onChange={() => handleCheckboxClick(4)} />
                            <label htmlFor="star4"></label>
                            <input type="checkbox" id="star5" name="rating" value="5" checked={rating >= 5}
                                onChange={() => handleCheckboxClick(5)} />
                            <label htmlFor="star5"></label>
                        </div>

                        <span className={styles.emailIdInter} >Rate Your Purchasing Experience!</span>
                    </div>

                    <div onClick={() => navigate(routes.homePage)} className={styles.returnInstantPricing}>Return to Instant Pricing</div>
                    {/* <span> <ReturntoSearchIcon /></span> */}
                </div>
            {/* </div> */}
            <Dialog
                open={showDialogConfirmationPopup}
                onClose={(event) => (false)}
                transitionDuration={200}
                hideBackdrop
                classes={{
                    root: styles.ConfirmationDialog,
                    paper: styles.dialogContent
                }}
            >
                <p>Do you want to continue?</p>
                <div className={styles.actionsTab}>
                    <button className={styles.submitBtn} onClick={(event) => cancelOrder()} disabled={cancelOrderDisableBtn}>Yes</button>
                    <button className={styles.cancelBtn} onClick={(event) => setShowDialogConfirmationPopup(false)}>No</button>
                </div>
            </Dialog>
        </div>
    )
}

export default OrderConfirmationBuyer;
