// @ts-nocheck
import clsx from 'clsx';
import {
    useState,
    useEffect,
    useRef
} from 'react';
import styles from './sellerSetting.module.scss';
import { ReactComponent as QuestionIcon } from '../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/setting-upload.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';
import { useNavigate } from 'react-router';
import { CASS_INVALID_ACCOUNT_DETAILS_ERROR, fileType, prefixUrl, routes, userRole } from '../../../common';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './sellerSchema';
import { Autocomplete, Dialog, Fade, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import { ReactComponent as ErrorMessageIcon } from '../../assets/images/error-warning.svg';
import { CompanyEntityTooltip, SACHCreditTooltip, SCompanyAddressTooltip, SCompanyNameTooltip, SEmailPhoneTooltip, SIRSW9FormTooltip, SSendInvoicesTooltip, SShippingDocsTooltip, SStockingLocationTooltip, SWireTooltip, SYourNameTooltip, SYourProductsTooltip } from '../../tooltip';
import { v4 as uuidv4 } from 'uuid';
import TrueVaultClient from 'truevault';
import { CustomMenu } from '../buyer/CustomMenu';
import { downloadFiles, formatPhoneNumber, formatPhoneNumberRemovingCountryCode, formatPhoneNumberWithCountryCode } from '../../helper';
import { useGlobalStore, getSocketConnection, emojiRemoverRegex } from '@bryzos/giss-ui-library';
import Cass from '../buyer/Cass/Cass';
import { useImmer } from 'use-immer';
import { CommonTooltip } from '../../component/Tooltip/tooltip';
import useGetCompanyLists from '../../hooks/useGetCompanyLists';


function BuyerSetting(props) {
    const { userData } = useGlobalStore();
    const navigate = useNavigate();
    const [States, setStates] = useState([]);
    const [errorKeys, setErrorKeys] = useState([]);
    const [irsW9SignedUrl, setIrsW9SignedUrl] = useState('');
    const [lineCardSignedUrl, setLineCardSignedUrl] = useState('');
    //  const [AddressState, setAddressState] = useState('State');
    const [achId, setAchId] = useState(0);
    const [wireId, setWireId] = useState(0);
    const [referenceDocumentId, setReferenceDocumentId] = useState('');
    const [backToHome, setBackToHome] = useState(false);
    const [isDataLoad, setisDataLoad] = useState(true);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [apiFailureDialog, setApiFailureDialog] = useState(false);
    const [validationInProgress, setValidationInProgress] = useState(true);
    const [sellerSettings, setSellerSettings]  = useImmer(null);
    const [companyNameValue, setCompanyNameValue] = useImmer(null);
    const [companyNameInput, setCompanyNameInput] = useState("");
    const [isCompanyNameExists, setIsCompanyNameExists] = useState(false);
    const [yourCompanyValue, setYourCompanyValue] = useImmer(null);
    const [yourCompanyInput, setYourCompanyInput] = useState("");
    const [disableYourCompany, setDisableYourCompany] = useState(true);
    const [yourCompanyList, setYourCompanyList] = useState([]);

    const childRef = useRef();
    const w9UploadTooltipRef = useRef();
    const productsUploadUrlRef = useRef();

    const { register, handleSubmit, getValues, control, clearErrors, watch, setValue, setError, formState: { errors, dirtyFields, isDirty } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            wireCheckBox: false,
            achCheckBox: false,
            defaultValues: {companyName: null},

        }
    });

    const { data: companyListsData, isLoading: isCompanyListsDataLoading, } = useGetCompanyLists();

    const setSellerSettingsData = useGlobalStore(state => state.setSellerSettingsData);
    const setShowLoader = useGlobalStore(state => state.setShowLoader);
    const backNavigation = useGlobalStore(state => state.backNavigation);
    const inputFileRef = useRef();
    const productLineFileRef = useRef();
    const socket = getSocketConnection();

    document.body.onfocus = () => {
        if (!inputFileRef.current?.value && w9UploadTooltipRef?.current?.setCurrentInFocus) {
            w9UploadTooltipRef.current.setCurrentInFocus(false);
        }

        if (!productLineFileRef.current?.value && productsUploadUrlRef?.current?.setCurrentInFocus) {
            productsUploadUrlRef.current.setCurrentInFocus(false);
        }
    }

    useEffect(() => {
        if (userData.data.id) {
            const referenceData = userData.referenceData;
            referenceData.ref_pgpm_mapping.forEach(paymentMethod => {
                if (paymentMethod.user_type === userRole.sellerUser.toLowerCase() && paymentMethod.payment_method === 'ACH') {
                    return setAchId(paymentMethod.id)
                }
                if (paymentMethod.user_type === userRole.sellerUser.toLowerCase() && paymentMethod.payment_method === 'WIRE') {
                    return setWireId(paymentMethod.id)
                }
            })
            setStates(referenceData.ref_states);

            axios.get(import.meta.env.VITE_API_SERVICE + '/user/sellingPreference', {
                headers: {
                    UserId: userData.data.id
                }
            })
                .then(response => {
                    const sellerSettings = response.data.data;
                    setSellerSettings(sellerSettings);
                    if (sellerSettings) {
                        setValue('companyName', sellerSettings.company_name);
                        setValue('yourCompany', sellerSettings.client_company);
                        setYourCompanyInput(sellerSettings.client_company ?? "")
                        setIsCompanyNameExists(!!sellerSettings.company_name);
                        setDisableYourCompany(sellerSettings.company_name === null)
                        setValue('companyAddressLine', sellerSettings.company_address_line1);
                        setValue('companyCity', sellerSettings.company_address_city);
                        setValue('companyState', sellerSettings.company_address_state_id);
                        //  setAddressState(sellerSettings.company_address_state_id);
                        setValue('companyZipCode', sellerSettings.company_address_zip);
                        setValue('firstName', sellerSettings.first_name);
                        setValue('lastName', sellerSettings.last_name);
                        setValue('emailAddress', sellerSettings.email_id);
                        setValue('phoneNo', formatPhoneNumberRemovingCountryCode(sellerSettings.phone));
                        setValue('stockingLocation', sellerSettings.stocking_location ?? '');
                        if (sellerSettings.w9_form_s3_url) {
                            setIrsW9SignedUrl(sellerSettings.w9_form_s3_url)
                        }
                        if (sellerSettings.products_s3_url) {
                            setLineCardSignedUrl(sellerSettings.products_s3_url)
                        }
                        if (sellerSettings.funding_settings) {
                            if (sellerSettings.funding_settings.pgpm_mapping_id === achId) {
                                setValue('achCheckBox', true)
                                setValue('bankName1', sellerSettings.funding_settings.bank_name);
                                setValue('routingNo', sellerSettings.funding_settings.routing_number);
                                setValue('accountNo', sellerSettings.funding_settings.account_number);
                                setReferenceDocumentId(sellerSettings.funding_settings.reference_document_id)
                            } else if (sellerSettings.funding_settings.pgpm_mapping_id === wireId) {
                                setValue('wireCheckBox', true)
                                setValue('bankName', sellerSettings.funding_settings.bank_name);
                                setValue('routingNumber', sellerSettings.funding_settings.routing_number);
                                setValue('accountNumber', sellerSettings.funding_settings.account_number);
                                setReferenceDocumentId(sellerSettings.funding_settings.reference_document_id)
                            }
                        }
                        setErrorKeys([])
                        clearErrors();
                    }

                    setisDataLoad(false);
                    setShowLoader(false);

                })
                .catch(error => {
                    console.error(error);
                    setisDataLoad(false);
                    setShowLoader(false);
                    setApiFailureDialog(true)
                }
                );
        }

    }, [achId, wireId]);

    useEffect(() => {
        handleStateZipValidation('companyZipCode', 'companyState')
    }, [watch('companyZipCode'), watch('companyState')])

    useEffect(() => {
        if(watch("companyName") !== null){
            const companyData = companyListsData?.find((companyData) => companyData.company_name === watch("companyName"))
            setYourCompanyList(companyData?.client_company ?? [])
        }
    },[watch("companyName")])
    


    const fundingSettingChanged = (field) => {
        const bankName = getValues("bankName");
        const routingNumber = getValues("routingNumber");
        const accountNumber = getValues("accountNumber");
        const bankName1 = getValues("bankName1");
        const routingNo = getValues("routingNo");
        const accountNo = getValues("accountNo");

        if (field === "bankName") {
            if (routingNumber?.includes("x")) {
                setValue('routingNumber', "");
            }
            if (accountNumber?.includes("x")) {
                setValue('accountNumber', "");
            }

        } else if (field === "routingNumber") {
            if (bankName?.includes("x")) {
                setValue('bankName', "");
            }
            if (accountNumber?.includes("x")) {
                setValue('accountNumber', "");
            }
        } else if (field === "accountNumber") {
            if (bankName?.includes("x")) {
                setValue('bankName', "");
            }
            if (routingNumber?.includes("x")) {
                setValue('routingNumber', "");
            }
        } else if (field === "bankName1") {
            if (routingNo?.includes("x")) {
                setValue('routingNo', "");
            }
            if (accountNo?.includes("x")) {
                setValue('accountNo', "");
            }
        }
        else if (field === "routingNo") {
            if (bankName1?.includes("x")) {
                setValue('bankName1', "");
            }
            if (accountNo?.includes("x")) {
                setValue('accountNo', "");
            }
        }
        else if (field === "accountNo") {
            if (bankName1?.includes("x")) {
                setValue('bankName1', "");
            }
            if (routingNo?.includes("x")) {
                setValue('routingNo', "");
            }
        }
    }

    const uploadIRSW9File = async (event) => {
        const file = event.target.files[0];

        if (event.target.files.length !== 0) {
            let index = file.name.length - 1;
            for (; index >= 0; index--) {
                if (file.name.charAt(index) === '.') {
                    break;
                }
            }
            const ext = file.name.substring(index + 1, file.name.length);

            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + userData.data.id + '/' + prefixUrl.irsW9Prefix + '-' + uuidv4() + '.' + ext;

            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_SETTINGS_IRS_W9_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300

                }
            }
            let setIRSUrl = 'https://' + payload.data.bucket_name + ".s3.amazonaws.com/" + payload.data.object_key;
            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    axios.put(signedUrl, file)
                        .then(response => {
                            if (response.status === 200) {
                                setIrsW9SignedUrl(setIRSUrl)

                            }
                        })
                        .catch(error => {
                            console.error(error);
                            setisDataLoad(false);
                            setShowLoader(false);
                            setApiFailureDialog(true)
                        }
                        );
                })
                .catch(error => {
                    console.error(error);
                    setisDataLoad(false);
                    setShowLoader(false);
                    setApiFailureDialog(true)
                }
                );

        }
    }

    const uploadLineCardFile = async (event) => {
        const file = event.target.files[0];

        if (event.target.files.length !== 0) {
            // const ext = file.type.split('/');
            let index = file.name.length - 1;
            for (; index >= 0; index--) {
                if (file.name.charAt(index) === '.') {
                    break;
                }
            }
            const ext = file.name.substring(index + 1, file.name.length);

            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + userData.data.id + '/' + prefixUrl.lineCard + '-' + uuidv4() + '.' + ext;

            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_SETTINGS_LINE_CARD_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300

                }
            }
            let setLineCardUrl = 'https://' + payload.data.bucket_name + ".s3.amazonaws.com/" + payload.data.object_key;
            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    axios.put(signedUrl, file)
                        .then(response => {
                            if (response.status === 200) {
                                setLineCardSignedUrl(setLineCardUrl);
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            setisDataLoad(false);
                            setShowLoader(false);
                            setApiFailureDialog(true)
                        }
                        );
                })
                .catch(error => {
                    console.error(error);
                    setisDataLoad(false);
                    setShowLoader(false);
                    setApiFailureDialog(true)
                }
                );

        }
    }

    const getTruevaultData = async (companyName, userData, bankName, routingNo, accountNo, paymentId) => {
        try {
            const res = await axios.get(import.meta.env.VITE_API_PHP_SERVICE + '/api/v1/widget/truevault/get-access-token');
            const accessToken = res.data.data.access_token;
            const sellerPaymentData = {
                "document": {
                    "company_name": companyName,
                    "user_id": userData,
                    "bank_name": bankName,
                    "routing_number": routingNo,
                    "account_number": accountNo,
                    "pgpm_mapping_id": paymentId
                }
            }

            const client = new TrueVaultClient({ accessToken });

            try {
                const response = await client.createDocument(import.meta.env.VITE_TRUE_VAULT_ID_SELLER_VAULT_ID, null, sellerPaymentData)
                const documentIdFromTruevault = response.id;
                return documentIdFromTruevault;

            } catch(error) {
                console.error(error);
                setisDataLoad(false);
                setShowLoader(false);
                setApiFailureDialog(true)
            }
        }  catch(error) {
            console.error(error);
            setisDataLoad(false);
            setShowLoader(false);
            setApiFailureDialog(true)
        }

    }
    function BackToHomeDialog() {
        return (
            <>
                <Dialog
                    open={backToHome}
                    onClose={(event) => setBackToHome(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: clsx(styles.SubmitApp, styles.backToHomePopup),
                        paper: styles.dialogContent,
                    }}
                >
                    <button className={styles.closeIcon} onClick={(event) => setBackToHome(false)}><CloseIcon /></button>
                    <p className={styles.bactToHomeTitle}>You have unsaved changes</p>
                    <button className={styles.btnOfPopup} onClick={(event) => { handleSubmit(onSubmit)(); setBackToHome(false) }} >Save Changes</button>
                    <button className={styles.btnOfPopup} onClick={() => navigate(backNavigation)}>Don’t Save</button>
                    <button className={styles.btnOfPopup} onClick={(event) => setBackToHome(false)}>Go Back to Settings</button>
                </Dialog>
            </>
        );
    }
    const onSubmit = async (data) => {
        const referenceData = userData?.referenceData;

        if (referenceData?.ref_general_settings.length) {
            let obj = referenceData.ref_general_settings.find((obj) => obj.name === "CASS_MASTER_DATA_CREATE");
            if (obj?.value === "true") {
                try {
                    setShowLoader(true);
                    await childRef.current.startCassCreateion();
                } catch (error) {
                    if (error === CASS_INVALID_ACCOUNT_DETAILS_ERROR) {
                        setShowLoader(false);
                    }
                }
            }
        }

        const payload = {
            "data": {
                "company_name": data.companyName,
                "client_company": data.yourCompany,
                "address": {
                    "line1": data.companyAddressLine,
                    "city": data.companyCity,
                    "state_id": data.companyState,
                    "zip": data.companyZipCode
                },
                "first_name": data.firstName,
                "last_name": data.lastName,
                "email_id": data.emailAddress,
                "phone": formatPhoneNumberWithCountryCode(data.phoneNo),
                "stocking_location": data.stockingLocation ?? null,
                "send_invoices_to": data.sendInvoicesTo,
                "shipping_docs_to": data.shippingDocsTo,
            }
        }

        if (irsW9SignedUrl) {
            payload.data.w9_form_s3_url = irsW9SignedUrl;
        }
        if (lineCardSignedUrl) {
            payload.data.products_s3_url = lineCardSignedUrl;
        }
        if (data.achCheckBox) {

            if (dirtyFields.bankName1 || dirtyFields.routingNo || dirtyFields.accountNo) {

                if (isNaN(data.routingNo) || isNaN(data.accountNo)) {
                    const key = isNaN(data.routingNo) ? 'routingNo' : 'accountNo'
                    setError(key, { message: 'ACH Credit is not valid' }, { shouldFocus: true });
                    return
                }

                getTruevaultData(data.companyName, userData.data.id, data.bankName1, data.routingNo, data.accountNo, achId).then(documentIdFromTruevault => {
                    const achFundingSetting = {};
                    const convertedRoutingNo = data.routingNo.slice(-4).padStart(data.routingNo.length, 'x');
                    const convertedAccountNO = data.accountNo.slice(-4).padStart(data.accountNo.length, 'x');
                    achFundingSetting.bank_name = data.bankName1;
                    achFundingSetting.routing_number = convertedRoutingNo;
                    achFundingSetting.account_number = convertedAccountNO;
                    achFundingSetting.reference_document_id = documentIdFromTruevault;
                    achFundingSetting.pgpm_mapping_id = achId;
                    payload.data.funding_settings = achFundingSetting;
                    submitData(payload);
                })
            } else {

                const achFundingSetting = {};
                achFundingSetting.bank_name = data.bankName1;
                achFundingSetting.routing_number = data.routingNo;
                achFundingSetting.account_number = data.accountNo;
                achFundingSetting.reference_document_id = referenceDocumentId;
                achFundingSetting.pgpm_mapping_id = achId;
                payload.data.funding_settings = achFundingSetting;
                submitData(payload);
            }


        } else if (data.wireCheckBox) {

            if (dirtyFields.bankName || dirtyFields.routingNumber || dirtyFields.accountNumber) {

                if (isNaN(data.routingNumber) || isNaN(data.accountNumber)) {
                    const key = isNaN(data.routingNumber) ? 'routingNumber' : 'accountNumber'
                    setError(key, { message: 'Wire is not valid' }, { shouldFocus: true });
                    return
                }
                getTruevaultData(data.companyName, userData.data.id, data.bankName, data.routingNumber, data.accountNumber, wireId).then(documentIdFromTruevault => {
                    const wireFundingSetting = {};
                    const convertedRoutingNo = data.routingNumber.slice(-4).padStart(data.routingNumber.length, 'x');
                    const convertedAccountNO = data.accountNumber.slice(-4).padStart(data.accountNumber.length, 'x');
                    wireFundingSetting.bank_name = data.bankName;
                    wireFundingSetting.routing_number = convertedRoutingNo;
                    wireFundingSetting.account_number = convertedAccountNO;
                    wireFundingSetting.reference_document_id = documentIdFromTruevault;
                    wireFundingSetting.pgpm_mapping_id = wireId;
                    payload.data.funding_settings = wireFundingSetting;
                    submitData(payload);
                });
            } else {
                const wireFundingSetting = {};
                wireFundingSetting.bank_name = data.bankName;
                wireFundingSetting.routing_number = data.routingNumber;
                wireFundingSetting.account_number = data.accountNumber;
                wireFundingSetting.reference_document_id = referenceDocumentId;
                wireFundingSetting.pgpm_mapping_id = wireId;
                payload.data.funding_settings = wireFundingSetting;
                submitData(payload);
            }


        } else {
            submitData(payload);
        }


    }

    const submitData = async (payload) => {
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/sellingPreference', payload, {
            headers: {
                UserId: userData.data.id
            }
        })
            .then(response => {
                setShowLoader(false);
                setSellerSettingsData(true);
                navigate(routes.homePage)
            })
            .catch(error => {
                console.error(error);
                setisDataLoad(false);
                setShowLoader(false);
                setApiFailureDialog(true)
            }
            );

    }

    const showErrorKeyVal = (fieldNames) => {
        setErrorKeys(fieldNames)
    }

    const singleErrorKey = errorKeys.find(x => errors[x])
    const handlePhoneNoChange = (event) => {
        const { value } = event.target;
        setValue('phoneNo', formatPhoneNumber(value));
    };


  
    const downloadReports = (fileUrl,fileName,fileType) => {
       const showError =  downloadFiles(fileUrl,fileName, fileType)
       showError.then(res => {
        if(res){
            setOpenErrorDialog(false);
           }else{
            setOpenErrorDialog(true);
           }
       })
       
    }

    const handleStateZipValidation = async (zipCode, stateCode) => {
      if (getValues(zipCode)?.length > 4 && getValues(stateCode)) {
        setValidationInProgress(false)
        const payload = {
          data: {
            state_id: getValues(stateCode),
            zip_code: parseInt(getValues(zipCode)),
          },
        };
        const checkStateZipResponse = await axios.post(
          import.meta.env.VITE_API_SERVICE + "/user/checkStateZip",
          payload
        );
        if (checkStateZipResponse.data.data === true) {
          clearErrors([stateCode, zipCode]);
        } else {
          setError(
            stateCode,
            { message: "The zip code and state code do not match" },
            { shouldFocus: true }
          );
          setError(
            zipCode,
            { message: "The zip code and state code do not match" },
            { shouldFocus: true }
          );
        }
        setValidationInProgress(true)
      }
    };

    const irsW9FormEditHandler = () => {
        inputFileRef.current.click();
    }
    
    const lineCardFormEditHandler = () => {
        productLineFileRef.current.click();
    }

    const wireCheckBoxChagneHandler = () => {
        const isChecked = getValues('wireCheckBox');
        setValue('wireCheckBox', !isChecked);
        setValue('achCheckBox', !!isChecked)
        if (!isChecked) {
            setValue('bankName', '');
            setValue('routingNumber', '');
            setValue('accountNumber', '')
            clearErrors(['bankName', 'routingNumber', 'accountNumber'])
        }
    }

    const achCreditCheckBoxChagneHandler = () => {
        const isChecked = getValues('achCheckBox');
        setValue('achCheckBox', !isChecked);
        setValue('wireCheckBox', !!isChecked);
        if (!isChecked) {
            setValue('bankName1', '');
            setValue('routingNo', '');
            setValue('accountNo', '')
            clearErrors(['bankName1', 'routingNo', 'accountNo'])
        }
    }
    
const handleFormSubmit = () => {
  if (Object.keys(errors).length === 0 && validationInProgress) {
    handleSubmit(onSubmit)();
  } else {
    return;
  }
};
    return (

        <>
            {/* <div className={clsx(styles.BuyerSetting, 'bgImg')}>
                <div className='headerPanel commonHeader'>
                    <Header />
                </div> */}
                <div className={clsx(styles.buyerSettingInnerContent,'bgBlurContent')}>
                        <div className='scrollContent scrollSeller'>
                            <div className={styles.dFlex}>
                                <span className={styles.generalSettings}>General Settings</span>
                                {(singleErrorKey && errors[singleErrorKey]?.message) &&
                                <div className={styles.errorMesDiv}>
                                    <span className={styles.errorMessage}><ErrorMessageIcon /> {errors[singleErrorKey]?.message}</span>
                                </div>
                                }
                            </div>
                            <div className={styles.formInnerContent}>

                                <div className={clsx(styles.FormInputGroup, errors.companyName && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['companyName'])}>
                                    <span className={styles.lblInput}>
                                        Main Company
                                        <Tooltip
                                            title={SCompanyNameTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.comanyName, errors.companyName && styles.borderOfError)}>
                                    {isCompanyNameExists ?
                                    <p className={styles.comanyNameInput1}>{watch("companyName")}</p>
                                    :
                                        <Controller
                                            name="companyName"
                                            control={control}
                                            render={({ field: { ...rest } }) => (
                                                <Autocomplete
                                                    value={companyNameValue}
                                                    onChange={(event, value) => {
                                                        setCompanyNameValue(value);
                                                        if(value?.company_name){
                                                            setDisableYourCompany(false)
                                                        }else{
                                                            setDisableYourCompany(true)
                                                            setYourCompanyList([])
                                                            setYourCompanyInput("")
                                                        }
                                                        rest.onChange(value?.company_name ?? null);
                                                    }}
                                                    inputValue={companyNameInput}
                                                    onInputChange={(event, newInputValue) => {
                                                        newInputValue = newInputValue.replace(emojiRemoverRegex, '');
                                                        setCompanyNameInput(newInputValue);
                                                        setValue("companyName", getValues("companyName"), { shouldDirty: true });
                                                    }}
                                                    className={'companySelectDropdown'}
                                                    id="controllable-states-demo"
                                                    classes={{
                                                        root: styles.autoCompleteDesc,
                                                        popper: styles.autocompleteDescPanel,
                                                        paper: styles.autocompleteDescInnerPanel,
                                                        listbox: styles.listAutoComletePanel,
                                                      }}
                                                    options={companyListsData?.length ? companyListsData : []}
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => <TextField {...params} placeholder="Select company name" />}
                                                    getOptionLabel={(item) => {
                                                        return item?.company_name ?? "";
                                                    }}
                                                />
                                                )}
                                            />}
                                    </span>
                                </div>
                                <div className={clsx(styles.FormInputGroup, errors.yourCompany && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['yourCompany'])}>
                                    <span className={styles.lblInput}>
                                        Your Company
                                        <Tooltip
                                            title={CompanyEntityTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.comanyName, errors.yourCompany && styles.borderOfError)}>
                                    <Controller
                                        name="yourCompany"
                                        control={control}
                                        render={({ field: { ...rest } }) => (
                                            <Autocomplete
                                                freeSolo
                                                disabled={disableYourCompany}
                                                value={yourCompanyValue}
                                                onChange={(event, value) => {
                                                    setYourCompanyValue(value);
                                                    rest.onChange(value ?? null);
                                                }}
                                                inputValue={yourCompanyInput}
                                                onInputChange={(event, newInputValue) => {
                                                    newInputValue = newInputValue.replace(emojiRemoverRegex, '');
                                                    setYourCompanyInput(newInputValue);
                                                    rest.onChange(newInputValue)
                                                }}
                                                className={'companySelectDropdown'}
                                                id="controllable-states-demo"
                                                classes={{
                                                    root: styles.autoCompleteDesc,
                                                    popper: styles.autocompleteDescPanel,
                                                    paper: styles.autocompleteDescInnerPanel,
                                                    listbox: styles.listAutoComletePanel,
                                                    }}
                                                options={yourCompanyList?.length ? yourCompanyList : []}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <TextField {...params} placeholder="Enter company name" />}
                                                getOptionLabel={(item) => {
                                                    return item ?? "";
                                                }}
                                            />
                                            )}
                                        />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.companyAddressLine || errors.companyState || errors.companyCity || errors.companyZipCode) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['companyAddressLine', 'companyState', 'companyCity', 'companyZipCode'])}>
                                    <span className={clsx(styles.lblInput, styles.lblAdress)}>
                                        Company Address
                                        <Tooltip
                                            title={SCompanyAddressTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.companyAddressLine && styles.borderOfError)}>
                                        <input type='text' {...register("companyAddressLine")} placeholder='Address' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("companyAddressLine").onChange(e); }}
                                        onBlur={(e) => {
                                                e.target.value = e.target.value.trim();
                                                register("companyAddressLine").onBlur(e);
                                            }} />
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.cityInput, styles.bdrRadius0, styles.bdrRight0, errors.companyCity && styles.borderOfError)}>
                                        <input type='text' {...register("companyCity")} placeholder='City' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("companyCity").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("companyCity").onBlur(e);
                                        }} />
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.companyState, styles.bdrRadius0, styles.bdrRight0, errors.companyState && styles.borderOfError)}>

                                        <CustomMenu
                                            control={control}
                                            name={'companyState'}
                                            placeholder={'state'}
                                            MenuProps={{
                                                classes: {
                                                    paper: styles.Dropdownpaper,
                                                    list: styles.muiMenuList,
                                                },
                                            }}
                                            items={States.map(x => ({ title: x.code, value: x.id }))}
                                            className={'selectDropdown selectState'}

                                        />
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.zipCodeInput, errors.companyZipCode && styles.borderOfError)}>
                                        <input type='tel' {...register("companyZipCode")} placeholder='Zip Code'  onChange={(e) => {
                                            register("companyZipCode").onChange(e);
                                            const zipCode = e.target.value.replace(/\D/g, '');
                                            setValue('companyZipCode', zipCode);
                                        }} maxLength="5" />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.firstName || errors.lastName) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['firstName', 'lastName'])}>
                                    <span className={styles.lblInput}>
                                        Your Name
                                        <Tooltip
                                            title={SYourNameTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.firstName && styles.borderOfError)}>
                                        <input type='text' {...register("firstName")} placeholder='First name' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("firstName").onChange(e);  }}
                                        onBlur={(e) => {
                                                e.target.value = e.target.value.trim();
                                                register("firstName").onBlur(e);
                                            }} />
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.lastName && styles.borderOfError)}>
                                        <input type='text' {...register("lastName")} placeholder='Last name' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("lastName").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("lastName").onBlur(e);
                                        }} />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.emailAddress || errors.phoneNo) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['emailAddress', 'phoneNo'])}>

                                    <span className={styles.lblInput}>
                                        Email / Phone
                                        <Tooltip
                                            title={SEmailPhoneTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.emailAddress && styles.borderOfError)}>
                                        <input type='text' {...register("emailAddress")} placeholder='Email address' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("emailAddress").onChange(e); }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.phoneNo, errors.phoneNo && styles.borderOfError)}>
                                        <input
                                            pattern="[0-9]" 
                                            maxLength="14"
                                            type="tel"
                                            {...register("phoneNo")} onChange={(e) => {
                                                register("phoneNo").onChange(e)
                                                handlePhoneNoChange(e)
                                            }}
                                            placeholder='(xxx) xxx-xxxx'
                                        />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.stockingLocation) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['stockingLocation'])}>
                                    <span className={clsx(styles.lblInput, styles.lblAdress)}>
                                        Stocking Location
                                        <Tooltip
                                            title={SStockingLocationTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.stockingLocation && styles.borderOfError)}>
                                        <input type='text' {...register("stockingLocation")} placeholder='Address'
                                            onChange={(e) => {
                                                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                                register("stockingLocation").onChange(e);
                                            }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("stockingLocation").onBlur(e);
                                        }} />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.sendInvoicesTo && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['sendInvoicesTo'])}>
                                    <span className={styles.lblInput}>
                                        Send Invoices to
                                        <Tooltip
                                            title={SSendInvoicesTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.sendInvoicesTo && styles.borderOfError)}>
                                        <input {...register("sendInvoicesTo")} type='text' value={'invoices@bryzos.com'} placeholder='Enter AP email address (multiple separate with a comma)' readOnly />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.shippingDocsTo && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['shippingDocsTo'])}>
                                    <span className={styles.lblInput}>
                                        Shipping Docs to
                                        <Tooltip
                                            title={SShippingDocsTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.shippingDocsTo && styles.borderOfError)}>
                                        <input type='text' {...register("shippingDocsTo")} value={'shippingdocs@bryzos.com'} placeholder='Enter email address (multiple separate with a comma)' readOnly />
                                    </span>
                                </div>
                                <div className={clsx(styles.FormInputGroup, styles.UploadSection)}>
                                    <span className={styles.lblInput}>
                                        IRS W-9 Form
                                        <Tooltip
                                            title={SIRSW9FormTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>
                                        </Tooltip>
                                    </span>
                                    <span className={styles.inputSection2}>
                                        {irsW9SignedUrl ?
                                            <span>
                                                <a className={styles.viewBtn} href={irsW9SignedUrl} >View</a><span className={styles.orText}>or</span><button className={styles.viewBtn} onClick={irsW9FormEditHandler}>Edit </button>
                                            </span>
                                            :
                                            <label>
                                                <button onClick={irsW9FormEditHandler} className={styles.uploadText}>Upload W-9
                                                    <span className={styles.uploadIcon}>
                                                        <CommonTooltip
                                                            title={"Click here to upload documents directly from your device"}
                                                            tooltiplabel={<UploadIcon />}
                                                            placement={'bottom-end'}
                                                            classes={{
                                                                popper: 'tooltipPopper',
                                                                tooltip: 'tooltipMain2 tooltipMain3',
                                                                arrow: 'tooltipBottomEndArrow'
                                                            }}
                                                            localStorageKey="w9UploadTooltip"
                                                            ref={w9UploadTooltipRef}
                                                        />
                                                    </span>
                                                </button>
                                            </label>
                                        }
                                        <input {...register("IRSW9FormUpload")} type='file' onChange={e => {
                                            uploadIRSW9File(e)
                                            register('IRSW9FormUpload').onChange(e)
                                        }} ref={inputFileRef} />
                                    </span>
                                </div>
                                <div className={clsx(styles.FormInputGroup, styles.UploadSection)}>
                                    <span className={styles.lblInput}>
                                        Your Products
                                        <Tooltip
                                            title={SYourProductsTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={styles.inputSection2}>
                                        {lineCardSignedUrl ?
                                            <span>
                                                    <a className={styles.viewBtn} href={lineCardSignedUrl} >View</a><span className={styles.orText}>or</span><button className={styles.viewBtn} onClick={lineCardFormEditHandler}>Edit </button>
                                            </span>
                                            :
                                            <label>
                                                <button className={styles.uploadText}  onClick={lineCardFormEditHandler}>Upload Line Card
                                                    <span className={styles.uploadIcon}>
                                                        <CommonTooltip
                                                            title={"Click here to upload documents directly from your device"}
                                                            tooltiplabel={<UploadIcon />}
                                                            placement={'bottom-end'}
                                                            classes={{
                                                                popper: 'tooltipPopper',
                                                                tooltip: 'tooltipMain2 tooltipMain3',
                                                                arrow: 'tooltipBottomEndArrow'
                                                            }}
                                                            localStorageKey="productUploadTooltip"
                                                            ref={productsUploadUrlRef}
                                                        />
                                                    </span>
                                                </button>
                                            </label>
                                        }
                                        <input {...register("productsUploadUrl")} type='file' onChange={e => {
                                            uploadLineCardFile(e)
                                            register('productsUploadUrl').onChange(e)
                                        }} ref={productLineFileRef} />
                                    </span>
                                </div>

                                <span className={styles.fundGeneralSettings}>Funding Settings</span>

                                <div className={clsx(styles.FormInputGroup, styles.achCredit, (errors.bankName || errors.routingNumber || errors.accountNumber) && styles.FormInputGroupError, (watch('wireCheckBox') === false && watch('achCheckBox') === true) && styles.disabled)}
                                    onClick={() => showErrorKeyVal(['bankName', 'routingNumber', 'accountNumber'])} >

                                    <span className={styles.lblInput}>
                                        <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                            <input tabIndex={-1} type='checkbox' {...register('wireCheckBox')} onChange={e => {
                                                const isChecked = (e.target.checked)
                                                register('wireCheckBox').onChange(e)
                                                setValue('achCheckBox', !isChecked)
                                                if (!isChecked) {
                                                    setValue('bankName', '');
                                                    setValue('routingNumber', '');
                                                    setValue('accountNumber', '')
                                                    clearErrors(['bankName', 'routingNumber', 'accountNumber'])
                                                }
                                            }} />
                                            <span>Wire</span>
                                            <button onClick={wireCheckBoxChagneHandler}  className={clsx(styles.checkmark, 'checkmark')} disabled={watch('wireCheckBox') === false && watch('achCheckBox') === true} />
                                        </label>
                                        <Tooltip
                                            title={SWireTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                             </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.bankName && styles.borderOfError)}>
                                        <label>Bank Name</label>
                                        <input {...register("bankName")} type='text' onChange={(e) => {
                                            e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                            register("bankName").onChange(e)
                                            setValue('wireCheckBox', true)
                                            fundingSettingChanged("bankName");
                                        }} 
                                        onBlur = {(e) => {
                                            e.target.value = e.target.value.trim();
                                           register("bankName").onBlur(e)
                                       }}
                                        placeholder='Enter bank name'
                                        disabled={watch('wireCheckBox') === false && watch('achCheckBox') === true} />
                                    </span>

                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.routingNumber && styles.borderOfError)}>
                                        <label>Routing No.</label>
                                        <input maxLength={9} {...register("routingNumber")} onChange={(e) => {
                                            e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                            register("routingNumber").onChange(e)
                                            setValue('wireCheckBox', true)
                                            fundingSettingChanged("routingNumber");
                                        }} type='text' placeholder='xxxxxxxxx' 
                                        onBlur = {(e) => {
                                            e.target.value = e.target.value.trim();
                                           register("routingNumber").onBlur(e)
                                       }}
                                       disabled={watch('wireCheckBox') === false && watch('achCheckBox') === true} />
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.accountNumber && styles.borderOfError)}>
                                        <label>Account No.</label>
                                        <input {...register("accountNumber")} onChange={(e) => {
                                            e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                            register("accountNumber").onChange(e)
                                            setValue('wireCheckBox', true)
                                            fundingSettingChanged("accountNumber");
                                        }} type='text' placeholder='xxxxxxxxxx' 
                                        onBlur = {(e) => {
                                            e.target.value = e.target.value.trim();
                                           register("accountNumber").onBlur(e)
                                       }}
                                       disabled={watch('wireCheckBox') === false && watch('achCheckBox') === true} />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, styles.achCredit, (errors.bankName1 || errors.accountNo || errors.routingNo) && styles.FormInputGroupError, (watch('achCheckBox') === false && watch('wireCheckBox') === true) && styles.disabled)}
                                    onClick={() => showErrorKeyVal(['bankName1', 'routingNo', 'accountNo'])}>
                                    <span className={styles.lblInput}>
                                        <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                            <input tabIndex={-1} type='checkbox'  {...register('achCheckBox')} onChange={e => {
                                                const isChecked = (e.target.checked)
                                                register('achCheckBox').onChange(e)
                                                setValue('wireCheckBox', !isChecked)
                                                if (!isChecked) {
                                                    setValue('bankName1', '');
                                                    setValue('routingNo', '');
                                                    setValue('accountNo', '')
                                                    clearErrors(['bankName1', 'routingNo', 'accountNo'])
                                                }
                                            }}
                                            />
                                            <span>ACH Credit</span>
                                            <button onClick={achCreditCheckBoxChagneHandler} className={clsx(styles.checkmark, 'checkmark')} disabled={watch('achCheckBox') === false && watch('wireCheckBox') === true} />
                                        </label>
                                        <Tooltip
                                            title={SACHCreditTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.bankName1 && styles.borderOfError)}>
                                        <label>Bank Name</label>
                                        <input {...register("bankName1")} type='text' onChange={(e) => {
                                            register("bankName1").onChange(e)
                                            e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                            setValue('achCheckBox', true)
                                            fundingSettingChanged("bankName1")
                                        }} placeholder='Enter bank name'
                                        onBlur = {(e) => {
                                            e.target.value = e.target.value.trim();
                                           register("bankName1").onBlur(e)
                                       }}
                                       disabled={watch('achCheckBox') === false && watch('wireCheckBox') === true} />
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.routingNo && styles.borderOfError)}>
                                        <label>Routing No.</label>
                                        <input maxLength={9} {...register("routingNo")} type='text' onChange={(e) => {
                                            register("routingNo").onChange(e)
                                            e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                            setValue('achCheckBox', true)
                                            fundingSettingChanged("routingNo")
                                        }} placeholder='xxxxxxxxx' 
                                        onBlur = {(e) => {
                                            e.target.value = e.target.value.trim();
                                           register("routingNo").onBlur(e)
                                       }}
                                       disabled={watch('achCheckBox') === false && watch('wireCheckBox') === true} />
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.accountNo && styles.borderOfError)}>
                                        <label>Account No.</label>
                                        <input {...register("accountNo")} type='text' onChange={(e) => {
                                            e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                            register("accountNo").onChange(e)
                                            setValue('achCheckBox', true)
                                            fundingSettingChanged("accountNo")
                                        }} placeholder='xxxxxxxxxx' 
                                        onBlur = {(e) => {
                                            e.target.value = e.target.value.trim();
                                           register("accountNo").onBlur(e)
                                       }}
                                       disabled={watch('achCheckBox') === false && watch('wireCheckBox') === true} />
                                    </span>
                                </div>

                                <div className={styles.myReportsTitle}>My Reports <span>(click to export)</span></div>
                                <button className={styles.purchaseOrderHistoryText} onClick={() => {downloadReports(import.meta.env.VITE_API_SERVICE + `/user/salesOrderExcel`, 'Sales Order History',fileType.excelSheet)}}>Sales Order History</button>
                                <button className={styles.purchaseOrderHistoryText} onClick={() => {downloadReports(import.meta.env.VITE_API_SERVICE + `/user/receivableStatementExcel`, 'Funding History',fileType.excelSheet)}}>Funding History (Accounts Receivable Statement)</button>
                            </div>
                        </div>

                    <div className={styles.btnSection}>
                        <div>
                            <button onClick={() => isDirty ? setBackToHome(true) : navigate(backNavigation)} className={styles.backBtn}>Back</button>
                        </div>
                        <div className={styles.termsAndPatent}>
                            <div className={clsx(styles.TermsandConditions, socket?.connected && styles.version)}>{props.appVersion}</div>
                            <div className={styles.TermsandConditions} onClick={() => navigate(routes.TnCPage, { state: { isViewMode: true, navigateTo: routes.sellerSettingPage } })}>Terms and Conditions</div>
                            <div className={styles.patentPendingText}>Patent Pending</div>
                        </div>
                        <div className={styles.btnRight}>
                            <button onClick={handleFormSubmit} disabled={!isDirty} className={clsx(styles.backBtn, styles.saveBtn)}>Save</button>
                        </div>
                    </div>

                </div>
            {/* </div> */}
            <>
                <Dialog
                    open={apiFailureDialog}
                    onClose={(event) => setApiFailureDialog(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: styles.ErrorDialog,
                        paper: styles.dialogContent
                    }}

                >
                    <p>"Something went wrong. Please try again in sometime"</p>
                    <button className={styles.submitBtn} onClick={(event) => setApiFailureDialog(false)}>Ok</button>
                </Dialog>
            </>
            <>
                            <Dialog
                                open={openErrorDialog}
                                onClose={(event) => setOpenErrorDialog(false)}
                                transitionDuration={200}
                                hideBackdrop
                                classes={{
                                    root: styles.ErrorDialog,
                                    paper: styles.dialogContent
                                }}

                            >
                                <p>No data found. Please try again in sometime</p>
                                <button className={styles.submitBtn} onClick={(event) => setOpenErrorDialog(false)}>Ok</button>
                            </Dialog>
                        </>
            <BackToHomeDialog />
        <Cass ref={childRef} getValues={getValues} referenceData={userData?.referenceData} states={States} />
        </>

    );
}

export default BuyerSetting;