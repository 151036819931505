// @ts-nocheck
import MenuItem from '@mui/material/MenuItem';
import { ListSubheader, Select } from '@mui/material';
// import styles from './CustomMenu.scss';
import { Controller } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';

export function CustomMenu({ items, className, MenuProps, control, name, onChange, defaultValue, placeholder, renderValue, IconComponent,category, onOpen, onClose, keyHandler, onfocus, onBlur, onClick, disabled}) {
    const [openMenu, setOpenMenu] = useImmer(false);
    const [onFocus, setonFocus] = useImmer(false);

    const keyupHanlder = (event) => {
        if (event.key === "Tab" || event.key === "Escape") {
            if (onFocus) {
                if (openMenu) {
                    setOpenMenu(false);
                } else if (items?.length) {
                    setOpenMenu(true);
                    if(keyHandler) keyHandler();
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', keyupHanlder);

        return () => {
            window.removeEventListener('keyup', keyupHanlder);
        }
    })

    return (
        <>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ field: { value, ...rest } }) => {
                    return (
                        <Select MenuProps={MenuProps}
                            value={value ?? ''}
                            {...rest}
                            displayEmpty
                            renderValue={renderValue ? renderValue : value ? undefined : () => <span className='dropdownPlaceholderText'>{placeholder}</span> ?? ''}
                            onChange={(e) => {
                                rest.onChange(e)
                                onChange?.(e)
                                setOpenMenu(false);
                            }}
                            className={className}
                            classes={{
                                select: 'selectDropdown'
                            }}
                            IconComponent={IconComponent}
                            open={disabled ? false : openMenu}
                            onClick={(e) => {
                                if(e.target.innerText === category || e.target.localName === "ul" ||  Object.keys(e.target.dataset).length === 0 && e.target.id.length !== 0){
                                    setOpenMenu(true);
                                    onClick?.()
                                    return;
                                }
                                setOpenMenu(!openMenu);
                                setonFocus(true);
                            }
                            }
                            onFocus={() => {
                                setonFocus(true)
                                onfocus?.()
                            }}
                            onBlur={() => {
                                setonFocus(false);
                                setOpenMenu(false);
                                onBlur?.()
                            }}
                            onOpen={onOpen}
                            onClose={onClose}
                        >
                             {category && <ListSubheader>{category}</ListSubheader>}
                            {items.map((item) => (
                                <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
                                    {item.available ?
                                        item.changeTitle :
                                        item.title
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    )
                }}
            />

        </>
    );
}

// export CustomMenu;
