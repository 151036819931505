// @ts-nocheck
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reactQueryKeys } from "../../common";
import axios from "axios";

const useSendForbiddenTooltips = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (tooltips) => {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_SERVICE}/user/postTooltip`,
          { data: { tooltips } }
        );

        queryClient.invalidateQueries([reactQueryKeys.getForbiddenTooltips]);

        if (response.data && response.data.data) {
          if (
            typeof response.data.data === "object" &&
            "err_message" in response.data.data
          ) {
            throw new Error(response.data.data.err_message);
          } else {
            return response.data.data.tooltips;
          }
        } else {
          return null;
        }
      } catch (error) {
        throw new Error(error?.message ?? error);
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 0,
    }
  );
};

export default useSendForbiddenTooltips;
