// @ts-nocheck
import React from "react";
import { routes } from "../../common";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { OTPInputComponent } from "../component/OTPInput";
import { useNavigate } from "react-router";
import { usePostMigrateToPassword } from "@bryzos/giss-ui-library";

function ForgotPassword() {
  const navigate = useNavigate();
  const [changeOtpScreen, setChangeOtpScreen] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showConfirmPasswordScreen, setShowConfirmPasswordScreen] = useState(false);
  const changePasswordConfirmation = usePostMigrateToPassword();
  if (!changeOtpScreen) {
    const {
      register,
      watch,
      handleSubmit,
      setError,
      clearErrors,
      formState: { errors, isValid, submitCount },
    } = useForm({
      resolver: yupResolver(
        yup
          .object({
            email: yup
              .string()
              .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                {
                  message: "Please enter valid email",
                }
              )
              .required("Email is Required"),
          })
          .required()
      ),
      mode: "onSubmit",
    });

    useEffect(() => {
      if (errors.root?.serverError) clearErrors("root.serverError");
    }, [watch("email")]);

    const sendOtpHandler = async (data) => {
      try {
        await Auth.forgotPassword(data.email);
        setChangeOtpScreen(true);
      } catch (error) {
        setChangeOtpScreen(false);
        setError("email", { message: error.message })
      }
    };
    return (
      <>
        <div className="resetPassword">
          <div className="emailDiv">
            <input
              type="email"
              name="email"
              {...register("email")}
              placeholder="Enter Email"
            />
            <p className='errorText'>{errors.email?.message}</p>
          </div>
          <div className="resetBtnDiv">
            <button
              className="resetPassBtn"
              onClick={handleSubmit(sendOtpHandler)}
            >
              Reset Password
            </button>
            <button
              className="backBtn"
              onClick={() => navigate(routes.loginPage)}
            >
              Back
            </button>
          </div>
        </div>
      </>
    );
  } else {
    const {
      register,
      watch,
      handleSubmit,
      setError,
      setValue,
      clearErrors,
      getValues,
      formState: { errors, isValid, submitCount },
    } = useForm({
      resolver: yupResolver(
        yup
          .object({
              newPassword: yup.string().required().test('len', 'Password must be 6 digits', val => val?.length >= 6),
              confirmPassword: yup.string().required().test("isRequired", "Password does not match!", function(value) {
                const password = this.parent.newPassword;
                if(password.trim() === value.trim()) return true;
                return false;
              })
          })
          .required()
      ),
      mode: "onSubmit",
    });

    const handleFocus = () => {
      setShowPasswordInput(true);
    };

    useEffect(() => {
      if (errors.root?.serverError) clearErrors("root.serverError");
    }, [watch("otp")]);

    const setNewPasswordHandler = async (data) => {
      try {
        const otp = data.otp?.join("");
        const newPassword = data.newPassword;
        const confirmPassword = data.confirmPassword;
        if(newPassword === confirmPassword){
          const res = await Auth.forgotPasswordSubmit(data.email, otp, confirmPassword);
          if(res === 'SUCCESS'){
            await changePasswordConfirmation.mutateAsync(data.email);
            navigate(routes.loginPage);
          }
        }else{
          setError("confirmPassword", { message: 'Password does not match!' })
          return
        }
      } catch (error) {
        setShowConfirmPasswordScreen(false)
        setShowPasswordInput(false)
        setValue('otp', '')
        setValue('newPassword', '')
        setValue('confirmPassword', '')
        setError("otp", { message: error.message })
      }
    };

    return (
      <>
        {!showConfirmPasswordScreen ? (
          <div className="resetPassword">
            <div className="emailDiv">
              {!watch("otp") && !showPasswordInput ? (
                <input
                  id="inputPlaceholder"
                  placeholder="Enter OTP"
                  value=""
                  onFocus={handleFocus}
                  readOnly
                />
              ) : (
                <OTPInputComponent
                  register={register}
                  className="passwordBox"
                  autoFocus
                  inputType={"tel"}
                  registerName={"otp"}
                  disableOnPaste = {true}
                />
              )}
            <p className='errorText errorForgetPass'>{errors.otp?.message}</p>
            </div>
            <div className="resetBtnDiv">
              <button
                className="nextBtn"
                onClick={() => {
                  if(Array.isArray(getValues('otp')) && getValues('otp')?.join('').length >= 6){
                    setShowConfirmPasswordScreen(!showConfirmPasswordScreen);
                    setShowPasswordInput(false);
                    clearErrors("otp")
                  }else{
                    setError("otp", { message: 'OTP must be 6 digits' })

                  }
                }}
              >
                Next
              </button>
              <button
                className="backBtn"
                onClick={() => navigate(routes.loginPage)}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="resetPassword">
            <div className="passDiv">
            <input type="password" {...register("newPassword")}  placeholder='Enter New Password'  />
              {(errors.newPassword && <p className='errorText errorForgetPass'>{errors.newPassword?.message}</p>)
              ||
              (errors.confirmPassword && <p className='errorText errorForgetPass'>{errors.confirmPassword?.message}</p>)
              }
            </div>
            <div className="passDiv">
            <input type="password" {...register("confirmPassword")}  placeholder='Re-Enter New Password'  />
            </div>
            <div className="resetBtnDiv">
              <button
                className="nextBtn"
                onClick={() => handleSubmit(setNewPasswordHandler)()}
              >
                Confirm
              </button>
              <button
                className="backBtn"
                onClick={() => navigate(routes.loginPage)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ForgotPassword;
