// @ts-nocheck
import React, { useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import styles from "./updatePopup.module.scss";
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';
import { getChannelWindow } from '@bryzos/giss-ui-library';

type Props = {
  children?: React.ReactNode
}
const UpdatePopup: React.FC<Props> = ()=> {
  if (!window.electron) {
    window.electron = {
        send: () => { },
        sendSync: () => { },
        receive: ()=>{}
    }
  }

  const [releaseNotes, setReleaseNotes] = useState('');
  const [currentVersion, setCurrentVersion] = useState('');
  const [releaseVersion, setReleaseVersion] = useState('');
  const channelWindow =  getChannelWindow() ;
  useEffect(()=>{
    const releaseData = channelWindow?.ready && window.electron.sendSync({ channel: channelWindow.ready });
    if(releaseData){
      setReleaseNotes(releaseData.releaseNotes);
      setCurrentVersion(releaseData.currentVersion);
      setReleaseVersion((releaseData.releaseName.charAt(0).toLowerCase() === 'v')?releaseData.releaseName.slice(1):releaseData.releaseName);
    }
  },[]);
 
  const handleClose = () => {
    if(channelWindow?.closeNewUpdateWindow)
      window.electron.send({ channel: channelWindow.closeNewUpdateWindow });
      
  };

  const handleUpdate = () => {
    if(channelWindow?.doUpdate)
    window.electron.send({ channel: channelWindow.doUpdate });    
};

  return (
    <div>
        <div className={styles.updatePopupMain}>
          <span className={styles.closeIcon} onClick={handleClose}><CloseIcon /></span>
          <div className={styles.updateAvailableTitle}>Update Available</div>
          <div className={styles.updateAvailableText}>New version {releaseVersion} is available. You have {currentVersion}</div>
          <div className={styles.updateInfoBox}>
             Release notes {releaseVersion}:
             <br/>
             <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
         
          </div>
          <div className={styles.btnSection}>
            <button className={styles.closeWidgetBtn} onClick={handleClose}>Close Widget</button>
            <button className={styles.updateWidgetBtn} onClick={handleUpdate}>Close & Update</button>
          </div>

        </div>
    </div>
  );
}

export default UpdatePopup;