import React, { useEffect, useRef } from 'react';
import styles from './reaction.module.scss';
import clsx from 'clsx';

interface ReactionMenuProps {
  onReactionSelect: (reaction: string) => void;
  selectedReactions:string[];
}

const reactionEmojis: { [key: string]: string } = {
  wave: '👋',
  thumbs_up: '👍',
  clap: '👏',
  pray: '🙏',
  strong: '💪',
  celebrate: '🎉',
  heart: '❤️'
};

const ReactionMenu: React.FC<ReactionMenuProps> = ({ onReactionSelect, selectedReactions }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onReactionSelect(''); // Trigger the parent function without a reaction to close the menu
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onReactionSelect]);

  return (
    <div ref={menuRef} className={styles.reactionMenu}>
      {Object.entries(reactionEmojis).map(([key, emoji]) => (
        <button key={key} className={styles.reactionButton} onClick={() => onReactionSelect(key)}>
          {emoji}
        </button>
      ))}
    </div>
  );
};

export default ReactionMenu;
