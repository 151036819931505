import MatPopup from '../MatPopup/MatPopup';
import { useEffect, useState } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import FacebookIcon from '../../assets/images/FB.svg';
import LinkedinIcon from '../../assets/images/linkedin.svg';
import TwitterIcon from '../../assets/images/X.com.svg';
import WhatsappIcon from '../../assets/images/WhatsApp.svg';
import EmailIcon from '../../assets/images/Email.svg';
import CopyLinkIcon from '../../assets/images/CopyLink.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg';
import { useSaveShareVideoAnalytic, validateEmailWithSuffix } from '@bryzos/giss-ui-library';

const ShareVideoUrl = ({ openShare, shareVideoPopupClose, videoData={} }) => {
  const saveShareVideoAnalytic = useSaveShareVideoAnalytic();
  const [activeShare, setActiveButton] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [copied, setCopied] = useState(false);
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  useEffect(()=>{
    if(copied){
      setShowSuccessMessage(true);
      setSuccessMessage("Copied Successfully")
    }else{
      setShowSuccessMessage(false);
      setSuccessMessage('')
    }
  },[copied])

  const handleEmailChange = (event) => {
    setShowSuccessMessage(false);
    setDisableSendBtn(false);
    const emailId = event.target.value.trim();
    setEmail(emailId);
  };

  const handleShareBtnClick = (buttonName) => {
    if (buttonName === "email") {
      setShowEmailInput(true);
    } else {
      setShowEmailInput(false);
    }
    setShowSuccessMessage(false);
    setSuccessMessage('');
    setEmail("");
    setEmailError('')
    setActiveButton(buttonName);
    if(!(buttonName === 'email' || buttonName === 'copy-link')){
      sendShareAnalyticsData(buttonName)
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    .then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1300);
    })
    .catch(err => console.error('Failed to copy:', err));
  };
  const closeShareVideoPopup = () => {
    setEmail("")
    setActiveButton("");
    setShowSuccessMessage(false);
    setSuccessMessage('')
    shareVideoPopupClose()
    setEmailError('')
    setShowEmailInput(false);
  }

  const handleSendEmail = async () => {
    if(!handleValidation(email)){
      if (email === '') {
        setEmailError('Please enter email address')
      } else if (email !== '' && emailError === '') {
        setDisableSendBtn(true);
        try {
          sendShareAnalyticsData('email',email)
          setDisableSendBtn(false);
          setEmail('')
          setShowSuccessMessage(true);
          setSuccessMessage('Sent Successfully')
        } catch (err) {
          console.error(err);
          setEmail('')
          setEmailError(err?.message)
          setDisableSendBtn(false)
        }
      }
    }
  }

  
  const handleValidation = (inputData) => {
    let returnData = false;
    if (inputData?.length !== 0) {
      const emailErrorData = validateEmailWithSuffix(inputData);
      if(emailErrorData){
        returnData = true;
      }
      setEmailError(validateEmailWithSuffix(inputData));
      setDisableSendBtn(false);
    }
    return returnData;
  }

  const sendShareAnalyticsData = (shareVia: string, receiver: string | null = null) => {
    const payload = {
      "share_via": shareVia,
      "receiver": receiver,
      "video_id": videoData.video_id
    }
    saveShareVideoAnalytic.mutate(payload);
  }

  return (
    <MatPopup open={openShare}
      popupClass={{
        root: 'shareVideoMain',
        paper: 'dialogContent'
      }}

    >
      <div className={'sharePopupContent'}>
        <span className='closeShareIcon' onClick={() => { closeShareVideoPopup() }}><CloseIcon /></span>
        <div className={'titlePopup'}>Share this video</div>
        <div className='iconSectionBox'>
          <LinkedinShareButton url={videoData.share_video_url}>
            <div className={activeShare === 'linkedIn' ? 'activeShareBtn' : 'shareBtn'} onClick={() => handleShareBtnClick("linkedIn")} >
              <img src={LinkedinIcon} alt="linkedIn" />
              <div className='btnTxt'>Linkedin</div>
            </div>
          </LinkedinShareButton>

          <FacebookShareButton url={videoData.share_video_url}>
            <div className={activeShare === 'facebook' ? 'activeShareBtn' : 'shareBtn'} onClick={() => handleShareBtnClick("facebook")}>
              <img src={FacebookIcon} alt="facebook" />
              <div className='btnTxt'>Facebook</div>
            </div>
          </FacebookShareButton>

          <TwitterShareButton  url={videoData.share_video_url}>
            <div className={activeShare === 'x' ? 'activeShareBtn' : 'shareBtn'} onClick={() => handleShareBtnClick("x")}>
              <img src={TwitterIcon} alt="Twitter" />
              <div className='btnTxt'>X</div>
            </div>
          </TwitterShareButton>

          <WhatsappShareButton  url={videoData.share_video_url}>
            <div className={activeShare === 'whatsApp' ? 'activeShareBtn' : 'shareBtn'} onClick={() => handleShareBtnClick("whatsApp")}>
              <img src={WhatsappIcon} alt="WhatsApp" />
              <div className='btnTxt'>Whatsapp</div>
            </div>

          </WhatsappShareButton>

          <button className={activeShare === 'email' ? 'activeShareBtn' : 'shareBtn'} onClick={() => handleShareBtnClick("email")}>
            <img src={EmailIcon} alt="Email" />
            <div className='btnTxt'>Email</div>
          </button>

          <button className={activeShare === 'copy-link' ? 'activeShareBtn' : 'shareBtn'} onClick={() => handleShareBtnClick("copy-link")}>
            <img src={CopyLinkIcon} alt="Copy Link" onClick={() => { copyToClipboard(videoData.share_video_url) }} />
            <div className='btnTxt'>Copy link</div>
          </button>
        </div>
        {showEmailInput && (
          <>
            <div className='SendInputSection'>
              <input
                type='email'
                value={email}
                onChange={handleEmailChange}
                onBlur={(e)=>{handleValidation(e.target.value)}}
                placeholder='Email Address'
              /><button disabled={!!(disableSendBtn || !email)} onClick={handleSendEmail}>Send</button>
            </div>
          </>
        )}
        {emailError.length !== 0 &&
          <p className='emailError'>{emailError}</p>
        }
        {showSuccessMessage && 
          <p className='successMessageStyle'>{successMessage}</p>
        }
      </div>

    </MatPopup>
  )
}

export default ShareVideoUrl;