/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
// https://fastnguyen.medium.com/build-otp-input-with-reactjs-hooks-5699eb58b427
import React, { memo, useState, useCallback, CSSProperties } from 'react';
import SingleInput from './SingleInput';
import { UseFormRegister } from 'react-hook-form';
import { emojiRemoverRegex } from '@bryzos/giss-ui-library';

// export interface OTPInputProps {
//   length: number;
//   onChangeOTP: (otp: string) => any;

//   autoFocus?: boolean;
//   isNumberInput?: boolean;
//   disabled?: boolean;

//   style?: CSSProperties;
//   className?: string;

//   inputStyle?: CSSProperties;
//   inputClassName?: string;
// }

const length =6;

type Props = {
  children?: React.ReactNode,
  register: UseFormRegister<any>;
  isNumberInput: boolean;
  autoFocus: boolean;
  disabled: boolean;
  inputClassName: string;
  inputStyle: string;
  registerName: string;
  inputType: string;
  disableOnPaste: boolean;
}

export const OTPInputComponent: React.FC<Props> = (props) => {
  const {
    register,
    isNumberInput,
    autoFocus,
    disabled,
    // onChangeOTP,
    inputClassName,
    inputStyle,
    registerName,
    inputType,
    disableOnPaste,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(length).fill(''));

  // Helper to return OTP from inputs
//   const handleOtpChange = useCallback(
//     (otp) => {
//       const otpValue = otp.join('');
//       onChangeOTP(otpValue);
//     },
//     [onChangeOTP],
//   );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : '';
    },
    [isNumberInput],
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
    //   handleOtpChange(updatedOTPValues);
    },
    [activeInput,
        //  handleOtpChange,
          otpValues],
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: any) => {
      e.currentTarget.value = e.currentTarget.value.replace(emojiRemoverRegex, '');
      const singleInputVal = Number(e.currentTarget.value);
      if(isNaN(singleInputVal))return;
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: any) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            focusPrevInput();
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            focusPrevInput();
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  const handleOnPaste = useCallback(
    
    (e: any) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .replace(/\D/g, '')
        .trim()
        .slice(0, length - activeInput)
        .split('');
        if (pastedData?.length !== 0) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues],
  );

  function preventOnPaste(e: any) {
    e.preventDefault();
    return false;
  }

  return (
    <div {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInput
           register={register   }
           registerName = {registerName}
          index= {index}
            key={`SingleInput-${index}`}
            type={isNumberInput ? inputType : inputType}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            
            onPaste={!disableOnPaste ? handleOnPaste : preventOnPaste}
            style={inputStyle}
            className={inputClassName}
            disabled={disabled}
          />
        ))}
    </div>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
