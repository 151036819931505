import React, { memo} from "react";
import { Dialog } from "@mui/material";
import styles from './DialogPopup.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';
import useDialogStore, { DialogAction } from "./DialogStore";

const DialogBox = memo(() => {

    const { openDialog, header, content, type, onClose, actions } = useDialogStore();
    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            transitionDuration={200}
            hideBackdrop
            classes={{
                root: styles.ErrorDialog,
                paper: styles.dialogContent
            }}
        >
            {type === 'success' && 
            <button className={styles.closeIcon} onClick={onClose}><CloseIcon /></button>
            }
            <p className={styles.successPopupTitle}>{header}</p>
            <p>{content}</p>
            {
                actions?.map((action: DialogAction, index: number)=>{
                    return <button key={index} className={styles.submitBtn} onClick={action.action}>{action.name}</button>
                })
            }
        </Dialog>
    );
});


export default DialogBox;