import { useQuery } from '@tanstack/react-query';
import { reactQueryKeys } from '../../common';
import axios from 'axios';

const useGetCompanyLists = () => {
  return useQuery([reactQueryKeys.getCompanyLists], async () => {
    try {
      let url = `${import.meta.env.VITE_API_SERVICE}/user/company`;

      const response = axios.get(url);
      const responseData = await response;

      if (responseData.data && responseData.data.data) {
        if (
          typeof responseData.data.data === 'object' &&
          'err_message' in responseData.data.data
        ) {
          throw new Error(responseData.data.data.err_message);
        } else {
          return responseData.data.data;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      throw new Error(error?.message ?? error);
    }
  });
};

export default useGetCompanyLists;
