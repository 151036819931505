
// @ts-nocheck
import { useLocation, useNavigate } from 'react-router';
import styles from './onboardingTnC.module.scss'
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { fileType, routes } from '../../../common';
import OnboardingFooter from './onboardingFooter';
import { commomKeys, useGlobalStore, userRole } from '@bryzos/giss-ui-library';
import { downloadFiles, getChannelWindow } from '../../helper';
import { Dialog } from '@mui/material';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import useDialogStore from 'src/renderer/component/DialogPopup/DialogStore';

function OnboardingTnc() {
    const channelWindow =  getChannelWindow();
    const navigate = useNavigate();
    const location = useLocation();
    const [tncCheckbox, setTncCheckBox] = useState(false);
    const [tncCheckboxDisable, setTncCheckBoxDisable] = useState(false);
    const [tncData, setTncData] = useState({});
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const {setShowLoader, setSignupUser, systemVersion, appVersion} = useGlobalStore();
    const { showCommonDialog, resetDialogStore } = useDialogStore();
    const data = location.state;

    useEffect(() => {
        const handleTncData = async () =>{
            try{
                const responseData = await axios.get(import.meta.env.VITE_API_SERVICE + '/reference-data/homepage');
                responseData.data.ref_bryzos_terms_conditions.forEach(termsAndCondition => {
                    if(data.userType === termsAndCondition.type){
                        setTncData(termsAndCondition)
                    }
                })
            }catch (error) {
                console.error('onboarding tnc error',error)
            }
        }
        setShowLoader(true)
        handleTncData();
    },[])
    const handleOnboardingSubmit = async () => {
        try{
            setTncCheckBoxDisable(false)
            if(data){
                let deviceId = null;
                //sign-up
                const user = await Auth.signUp({
                    username: data.emailAddress,
                    password: data.password,
                    email: data.emailAddress,
                    attributes: {
                        'custom:firstname': data.firstName,
                        'custom:lastname': data.lastName,
                        'custom:type': data.userType
                    }
                });
                const payloadData = {
                    "data": {
                        "username": data.emailAddress
                    }
                };
                const confirmSignupRes = await axios.post(import.meta.env.VITE_API_SERVICE + '/user/confirm-signup', payloadData);
                if(confirmSignupRes.data.data?.error_message){
                    showCommonDialog(null, confirmSignupRes.data.data.error_message, null, resetDialogStore, [{name: commomKeys.errorBtnTitle, action: resetDialogStore}])
                    return;
                }
                const userData = {
                    email: data.emailAddress,
                    password: data.password
                }
                setSignupUser(userData)
                if (channelWindow?.getDeviceId) {
                    deviceId = window.electron.sendSync({ channel: channelWindow.getDeviceId });
                }
                const payload = {
                    data: {
                        first_name: data.firstName,
                        last_name: data.lastName,
                        company_name: data.companyName,
                        client_company: data.companyEntity,
                        email_id: data.emailAddress,
                        user_type: data.userType,
                        cognito_user_name: user.userSub,
                        bryzos_terms_condtion_id: tncData.id,
                        accepted_terms_and_condition: tncData.terms_conditions_version,
                        last_login_app_version: appVersion,
                        os_version: systemVersion,
                        device_id: deviceId,
                        ui_version: import.meta.env.VITE_RENDERER_DEPLOY_VERSION,
                        zip_code: data.zipCode,
                    }
                }
                const responseData = await axios.post(import.meta.env.VITE_API_SERVICE + '/user/signup', payload);
                if(responseData.data.data.error_message){
                    navigate(routes.onboardingDetails, { state: {data, errorMessage: responseData.data.data.error_message, tncCheckbox, tncData}})
                }else{
                    navigate(routes.onboardingThankYou);
                }
            }else{
                navigate(routes.onboardingDetails);
            }
        }catch (error) {
            console.error('onBoarding error',error)
            setTncCheckBoxDisable(true)
        }

    }
    const [tnc, setTnc] = useState('');
    const FetchHtml = async () => {
        const response = await fetch(tncData.cloudfront_url+"?ver="+new Date().getTime());
        return await response.text(); // Returns it as Promise
    };
    const SetHtml = async () => {
        await FetchHtml().then((text) => {
            setTnc(text);
            setShowLoader(false)
        });
    };
    useEffect(() => {
        if(Object.keys(tncData).length !== 0){
            SetHtml(true)
        }
    },[tncData]);

    const downloadReports = () => {
        const url = data.userType === userRole.sellerUser ? import.meta.env.VITE_FILE_URL_DOWNLOAD_SELLER_TNC_PDF : import.meta.env.VITE_FILE_URL_DOWNLOAD_BUYER_TNC_PDF;
        const showError = downloadFiles (`${url}?ver=${new Date().getTime()}`, 'Tnc', fileType.pdf)
        showError.then(res => {
            if (res) {
                setOpenErrorDialog(false);
            } else {
                setOpenErrorDialog(true);
            }
        })

    }
    return (
        <div className={clsx(styles.tncBox, 'bgBlurContent')}>
            <div className={styles.tnCInnerContent}>
            <div className={clsx(tncCheckbox && styles.onboardingBlurClass, styles.onboardingLogo)}>
                <img src='/onboardingLogo.png' />
            </div>
            <div className={clsx(styles.trial, tncCheckbox && styles.onboardingTnCBody)}>
                <div className={styles.tnCPage}>
                    <div className={styles.tncScrollClass}>
                        <h2 className={styles.TermsofUseV1}>Bryzos Instant Pricing Desktop Widget <br />
                            Terms of Use ({tncData?.terms_conditions_version?.toUpperCase()})</h2>
                        {/* <p>Please read these Bryzos Instant Pricing Desktop Widget Terms of Use (these "Terms") carefully as they govern your use of (which includes access to) Bryzos’ Instant Pricing Desktop Widget (the “Widget”).</p> */}
                        {/* <p>Use of the Widget may be subject to additional terms and conditions presented by Bryzos, which are hereby incorporated by this reference into these Terms.</p> */}
                        <div className='tncOnboarding'>
                            <div dangerouslySetInnerHTML={{ __html: tnc }}></div>
                        </div>
                        
                    </div>
                </div>

                <div className={clsx(styles.checkingThisbox, 'onboardingChk')}>
                    <label className='containerChk'>
                        <input type='checkbox' onChange={(e) => {setTncCheckBox(e.target.checked); setTncCheckBoxDisable(e.target.checked)}} checked={tncCheckbox} />
                        <span className='checkmark' />
                        <span className='lblChk'>
                            By checking this box I am confirming on behalf of myself, and the company which I represent that I have read, understand and agree to the above terms and conditions.
                        </span>
                    </label>
                </div>
            </div>

            </div>
            <div className={styles.btnSectionTnC}>
            <div className={styles.onBoradingDownloadTnC} onClick={() => { downloadReports() }}>
                    Click here to download T&C
                </div>
            </div>
            
            <div className={clsx(tncCheckbox && styles.onboardingTnCBody)}><button className={clsx(styles.getstarttext,styles.GetStartedbtn)} disabled={!tncCheckboxDisable}  onClick={handleOnboardingSubmit}>Get Started</button></div>
            <OnboardingFooter />
            <>
                <Dialog
                    open={openErrorDialog}
                    onClose={(event) => setOpenErrorDialog(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: styles.ErrorDialog,
                        paper: styles.dialogContent
                    }}

                >
                    <p>No data found. Please try again in sometime</p>
                    <button className={styles.submitBtn} onClick={(event) => setOpenErrorDialog(false)}>Ok</button>
                </Dialog>
            </>
        </div>
    );
}
export default OnboardingTnc;
