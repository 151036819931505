import { getChannelWindow } from '@bryzos/giss-ui-library';
import { useElementSize } from '@mantine/hooks';
import { useEffect } from 'react';
import { routes } from 'src/common';
export const useHeightListener = (isHeaderHeightNotRequired?: null) => {
    const { ref, height } = useElementSize();
    const channelWindow:any =  getChannelWindow() ;
    const minHeight = 102;
    useEffect(() => {
        // 44 Height of header
        // Todo: buyer setting height is wrongly calculated
        const newHeight = ref?.current.offsetHeight <= minHeight ? minHeight : ref?.current.offsetHeight;
        // window.electron.send({ channel: channelUpdateWindowHeight, data:newHeight })
        // const newHeight= height + heightHeader < minHeight ? minHeight :  height + heightHeader
        if(channelWindow?.updateHeight && location.pathname !== routes.newUpdate)
            (window as any).electron.send({ channel: channelWindow.updateHeight, data: newHeight})
    }, [height]);
    return ref
}
