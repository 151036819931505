// @ts-nocheck
import { useEffect, useState } from 'react';
import Header from '../header';
import styles from './order.module.scss'
import { routes, purchaseOrder, MinSearchDataLen, orderPageConst, commomKeys} from '../../../common';
import { useNavigate } from 'react-router';
import { ReactComponent as YellowWarningIcon } from '../../assets/images/yellow-warning.svg';
import { ReactComponent as OrderCheckIcon } from '../../assets/images/order-check.svg';
import { Dialog, Fade, Tooltip } from '@mui/material';
import { AcceptTooltip, HideTooltip, activeOrderTooltip, orderPageTooltip, pendingOrderTooltip } from '../../tooltip';
import clsx from 'clsx';
import { emojiRemoverRegex, useGlobalStore, useSellerOrderStore } from '@bryzos/giss-ui-library';
import moment from 'moment';
import 'moment-timezone';
import { getPurchaseOrderFilteredList } from '../../helper/commonFunctionality';
import { formatToTwoDecimalPlaces } from '../../helper';
import axios from 'axios';
import { ReactComponent as QuestionIcon } from '../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { CommonTooltip } from '../../component/Tooltip/tooltip';
import DialogPopup from '../../component/DialogPopup/DialogPopup';

const Order = () => {
    const navigate = useNavigate();
    const purchaseOrdersList = useSellerOrderStore(state => state.ordersCart);
    const setShowAlertForNewPo = useSellerOrderStore(state => state.setShowAlertForNewPo);
    const filterPoStoreBy = useSellerOrderStore(state => state.filterPoStoreBy);
    const setFilterPoStoreBy = useSellerOrderStore(state => state.setFilterPoStoreBy);
    const { userData, backNavigation, setBackNavigation, navigationStateForNotification, setNavigationStateForNotification, setViewedOrdersListForBadgeCount } = useGlobalStore();
    const referenceData = userData.referenceData;
    const stateRef = referenceData.ref_states;
    const filteredPoList = useSellerOrderStore(state => state.filteredPoList);
    const setFilteredPoList = useSellerOrderStore(state => state.setFilteredPoList);
    const setOrderToBeShownInOrderAccept = useSellerOrderStore(state => state.setOrderToBeShownInOrderAccept);
    const searchByProductOrZipValue = useSellerOrderStore(state => state.searchByProductOrZipValue);
    const setSearchByProductOrZipValue = useSellerOrderStore(state => state.setSearchByProductOrZipValue);
    const availableAfter = referenceData.ref_general_settings.find( setting => setting.name === "SELLER_AVAIL_IN_MINUTES").value;

    const [popupMessage, setPopupMessage] = useState("");
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogBtnTitle, setDialogBtnTitle] = useState('');
    const [dialogType, setDialogType] = useState('');

    // const [ notificationId ] = useState(orderId);
    const [showDialogPopup, setShowDialogPopup] = useState(false);

    useEffect(()=>{
        setShowAlertForNewPo(false);
        return(() => {
            setBackNavigation(-1)
        })
    },[])

    useEffect(()=>{
        const poNumber = navigationStateForNotification?.referenceId;
        if(poNumber && purchaseOrdersList){
            const index = purchaseOrdersList.findIndex( order => order.buyer_po_number === poNumber);
            setNavigationStateForNotification(null);
            if(index>=0){
                navigateToAcceptOrder(null, purchaseOrdersList[index], index, false);
            }
            else{
                console.log("Error");
                setDialogTitle(commomKeys.info);
                setDialogContent(orderPageConst.orderNotAvaialableMsg);
                setDialogBtnTitle(commomKeys.errorBtnTitle);
                setDialogType(commomKeys.actionStatus.error);
                setShowDialogPopup(true);
            }
        }
    },[navigationStateForNotification]);

    const productMapping = userData.productMapping;
    useEffect(()=>{
        if(purchaseOrdersList){
            const viewedOrderList = purchaseOrdersList.reduce( (list, currentOrder) => {
                if(currentOrder.claimed_by === purchaseOrder.readyToClaim) {
                    list.push(currentOrder.buyer_po_number);
                }
                return list;
            },[]);
            const payload = {
                data:{
                "viewedOrdersListForBadgeCount": viewedOrderList
                }
            }
            try {
                axios.post(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/saveUserUiEventData', payload).then(()=>{
                    setViewedOrdersListForBadgeCount(viewedOrderList);
                });
            } catch (error) {
                console.error('error updating userJsonData: ', error);
            }
        }
    },[purchaseOrdersList])
    useEffect(()=>{
        if(purchaseOrdersList) {
            const poListToBeShown = getPurchaseOrderFilteredList(purchaseOrdersList, filterPoStoreBy, searchByProductOrZipValue, productMapping);
            setFilteredPoList(poListToBeShown);
        }
    },[filterPoStoreBy,searchByProductOrZipValue,purchaseOrdersList]);

    const changePoOrderFilterBy = (filter) => {
        if(filter === filterPoStoreBy) setFilterPoStoreBy('');
        else setFilterPoStoreBy(filter);
    }

    const navigateToAcceptOrder = ($event, purchaseOrder, index, showPopup) => {
        $event?.stopPropagation();
        setPopupMessage("");
        setOrderToBeShownInOrderAccept(purchaseOrder);
        // const data = { purchaseOrder, showPopup}; //changes required:send po_number and get the index in accept order component
        const data = { showPopup, index };
        navigate(routes.acceptOrderPage, { state: data });
    };

    const purchaseOrderTemplate = filteredPoList.map((order, index) => {
        const state = stateRef.find( stateDetail => stateDetail.id == order.state_id).code;
        const deliveryDate = moment.utc(order.delivery_date).tz('America/Chicago').format('M/DD/YY');
        let iconStatus = 
            <Tooltip
                title={activeOrderTooltip()}
                arrow
                placement={'bottom-start'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'orderPageTooltip',
                }}
            >
                <div>
                    <OrderCheckIcon />
                </div>
            </Tooltip>;
        let showAvailableTime = false;
        let availableTime = '';
        const materialValue = +order.seller_po_price;
        const salesTaxValue = +order.seller_sales_tax;
        const totalOrderValue = (materialValue+salesTaxValue).toFixed(2);
        const createdDate = order.payment_method === purchaseOrder.paymentMethodBNPL ? order.created_date : order.ach_po_approved_date;
        const distinctHeaderSet = new Set();
        order.items.forEach((item) => distinctHeaderSet.add(item.shape));
        let poHeader='';
        let loop=0;
        distinctHeaderSet.forEach((item) => {
            poHeader += item;
            if(loop < distinctHeaderSet.size-1) poHeader += ', ';
            loop++;
        })
        if(order.claimed_by === purchaseOrder.pending){
            iconStatus = 
            <Tooltip
                title={pendingOrderTooltip()}
                arrow
                placement={'bottom-start'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'orderPageTooltip',
                }}
            >
                <div>
                    <YellowWarningIcon />
                </div>
            </Tooltip>;
            showAvailableTime = true;
            availableTime = moment.utc(createdDate)
                            .local()
                            .add((+availableAfter)+1, 'minutes')
                            .format('h:mm a');
        }
        return (<li key={index}>
        <button className={styles.liOfOrder} id='orderListElement' key={index} onClick={($event)=>navigateToAcceptOrder($event,order,index,false)}>
        {iconStatus}
            <div>
                <p className={styles.firstLine}>{poHeader}</p>
                <p className={styles.secondLine}>Deliver to {order.city}, {state}</p>
                <p className={styles.secondLine}>Delivery by <span>{deliveryDate}</span></p>
            </div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td className={styles.firstTdText}>Order Value</td>
                            <td className={styles.dollerText}>$</td>
                            <td className={styles.dollerText}>{formatToTwoDecimalPlaces(totalOrderValue)}</td>
                        </tr>
                        <tr>
                            <td className={styles.firstTdText}>Total Wgt (lb)</td>
                            <td></td>
                            <td className={styles.dollerText}>{formatToTwoDecimalPlaces(order.total_weight)}</td>
                        </tr>
                        <tr>
                            {!showAvailableTime && <td className={styles.btnOfAHText} colSpan='3'>
                                    <Tooltip
                                        title={order.is_order_view && AcceptTooltip()}
                                        arrow
                                        placement={'bottom'}
                                        disableInteractive
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 200 }}
                                        classes={{
                                            tooltip: 'acceptTooltip',
                                        }}
                                    >
                                        <span>
                                            <button className={styles.accpetRejectBtn} onClick={($event) => navigateToAcceptOrder($event, order,index, true)} disabled={!order.is_order_view}>A</button>
                                        </span>
                                    </Tooltip>
                                {/* <Tooltip
                                    title={HideTooltip()}
                                    arrow
                                    placement={'bottom'}
                                    disableInteractive
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    classes={{
                                        tooltip: 'acceptTooltip',
                                    }}
                                >
                                    <span>H</span>

                                </Tooltip> */}
                                <button className={styles.accpetRejectBtn} onClick={ $event => $event.stopPropagation()} disabled={true}>H</button>
                            </td>}
                            {showAvailableTime &&  <td className={styles.btnOfAHText} colSpan='3'>Available @ {availableTime} </td>}
                        </tr>
                    </tbody>
                </table>
            </div>
        </button>
        
    </li>);
    });

    return (
        <div>
            <div className={clsx(styles.orderContent,'bgBlurContent')}> 
                <div className={clsx(styles.orderSearch, 'orderSearchBg')}>
                    <input 
                    type='search' 
                    name='search' 
                    className={styles.orderSearch} 
                    placeholder='Search Orders by Product or Zip' 
                    value={searchByProductOrZipValue} 
                    onChange={e => {
                        e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                        setSearchByProductOrZipValue(e.target.value);
                    }}
                    autoComplete="off"
                    />
                    <div className={styles.btnOfOrderSearch}>
                        <CommonTooltip
                            title={"Click here to quickly see what orders are currently available to pick up"}
                            tooltiplabel={<button className={filterPoStoreBy === purchaseOrder.readyToClaim ? styles.activeBtn : ''} onClick={()=>{changePoOrderFilterBy(purchaseOrder.readyToClaim)}}>Available</button>}
                            placement={'left-start'}
                            classes={{
                                popper: 'tooltipPopper',
                                tooltip: 'tooltipMain2 tooltipOrder',
                                arrow: 'tooltipLeftArrow'
                            }}
                            localStorageKey="availableBtnOfTooltip"
                        />
                        <CommonTooltip
                            title={"Click here to see upcoming orders that are still in preview mode"}
                            tooltiplabel={<button className={filterPoStoreBy === purchaseOrder.pending ? styles.activeBtn : ''} onClick={()=>{changePoOrderFilterBy(purchaseOrder.pending)}}>Upcoming</button>}
                            placement={'left-start'}
                            classes={{
                                popper: 'tooltipPopper',
                                tooltip: 'tooltipMain2 tooltipOrder',
                                arrow: 'tooltipLeftArrow'
                            }}
                            localStorageKey="upcomingBtnOfTooltip"
                        />
                    </div>
                </div>
                <div className={styles.orderHeading}>
                    <div className={styles.bigHeading}>Click Accept or <span className={styles.aTag}>A</span> to make a sale...that simple
                        <Tooltip
                            title={orderPageTooltip()}
                            arrow
                            placement={'bottom'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            classes={{
                                tooltip: 'orderPageTooltip',
                            }}
                        >
                            <span className='questionIconPriceChange'>
                                <QuestionIcon className='questionIcon1' />
                                <QuestionHoverIcon className='questionIcon2' />
                            </span>
                        </Tooltip>
                    </div>
                    <div className={styles.smallHeading}>Click on the order to see the line item detail</div>
                </div>
                <div className={styles.listOfOrder}>
                    <ul id='ordersList'>
                        {purchaseOrderTemplate}
                    </ul>

                </div>
                <div className={styles.btnSection}>
                        <div>
                            <button onClick={() =>{ navigate(backNavigation); setBackNavigation(-1) }} className={styles.backBtn}>Back</button>
                        </div>
                        <div className={styles.termsAndPatent}>
                            <div className={styles.TermsandConditions} onClick={() => navigate(routes.TnCPage, { state: { isViewMode: true, navigateTo: routes.orderPage } })}>Terms and Conditions</div>
                            <div className={styles.patentPendingText}>Patent Pending</div>
                        </div>
                    </div>
            </div>
            <Dialog 
                open={!!popupMessage}
                transitionDuration={200}
                hideBackdrop
                classes={{
                    root: styles.ErrorDialog,
                    paper: styles.dialogContent
                }}
            >
                <>
                    <p>{popupMessage}</p>
                    <button className={styles.submitBtn} onClick={() => setPopupMessage('')}>Ok</button>
                </>
            </Dialog>
            <DialogPopup
            dialogTitle={dialogTitle}
            dialogContent={dialogContent}
            dialogBtnTitle={dialogBtnTitle}
            type={dialogType}
            open={showDialogPopup}
            onClose={() => setShowDialogPopup(false)}
            />
        </div>
    )
}
export default Order;