// @ts-nocheck
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { reactQueryKeys } from "../../common";

const useSaveSellerSetting = (userId) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) => {
      if (userId) {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_SERVICE}/user/save/sellingPreference`,
            payload,
            {
              headers: {
                UserId: userId,
              },
            }
          );

          queryClient.invalidateQueries([reactQueryKeys.getSellerSetting]);

          if (response.data && response.data.data) {
            if (
              typeof response.data.data === "object" &&
              "err_message" in response.data.data
            ) {
              throw new Error(response.data.data.err_message);
            } else {
              return response.data.data;
            }
          } else {
            return null;
          }
        } catch (error) {
          throw new Error(error?.message ?? error);
        }
      } else {
        throw new Error("userId is undefined");
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export default useSaveSellerSetting;
