import * as yup from "yup";

export const schema = yup.object({
    userType: yup.string().trim().required('User Type is not valid'),
    companyName: yup.string().trim().required('Company Name is not valid'),
    companyEntity: yup.string().trim().required('Company Entity is not valid'),
    companyNameObj: yup.object(),
    firstName: yup.string().trim().required('First Name is not valid'),
    lastName: yup.string().trim().required('Last Name is not valid'),
    zipCode: yup.string().trim().required('Zip is not valid').min(5,'Zip is not valid'),
    emailAddress: yup.string().trim().required('Email is not valid').email('Email is not valid'),
    password: yup.string().trim().required('Password is required').min(6,'Password must be of minimum 6 characters'),
    reEnterEmailAddress: yup.string().trim()
    .required('Email does not match!')
    .email('Email is not valid')
    .test("isRequired", "Email does not match!", function(value) {
        const emailAddress = this.parent.emailAddress;
        if(emailAddress.trim() === value.trim()) return true;
        return false;
      }),
})