// @ts-nocheck
import { useEffect, useState } from 'react';
import Header from '../header';
import styles from './dispute.module.scss'
import {purchaseOrder, routes } from '../../../common';
import { useNavigate } from 'react-router';
import { ReactComponent as IcondownloadIcon } from '../../assets/images/icondownload.svg';

import { Autocomplete, Fade, Tooltip } from '@mui/material';
import { RejectTooltip, AcceptTooltip ,ModifyTooltip } from '../../tooltip';
import clsx from 'clsx';
import { useGlobalStore, useSellerOrderStore } from '@bryzos/giss-ui-library';
import { Controller } from 'react-hook-form';
import { disputeConst } from '../../../common';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';
import Loader from '../../Loader/Loader';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles({
//     root: {
//       flex:1, // example of setting the width to 300 pixels
//     },
// });

const Dispute = () => {
    // const classes = useStyles();
    const navigate = useNavigate();
    const orderList = useSellerOrderStore(state => state.ordersCart);
    const setOrderDetail = useSellerOrderStore(state => state.setOrderToBeShownInOrderAccept);
    const setShowLoader = useGlobalStore(state => state.setShowLoader);

    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [isDataLoad, setisDataLoad] = useState(true);
    const [filterDisputeBy, setFilterDisputeBy] = useState('');


    useEffect(() => {
        setShowLoader(true);
        getUserPurchaseOrders();
    },[]);

    const getUserPurchaseOrders = async () => {
        const purchaseOrderers = await axios.get(import.meta.env.VITE_API_SERVICE + '/user/getAllOrders');
        setPurchaseOrderList(purchaseOrderers.data.data);
        setisDataLoad(false);
        setShowLoader(false);
    }

    const cancelDispute = ($event, dispute) => {
        $event.stopPropagation();
        console.log(dispute);
    }
    const acceptDispute = ($event, dispute) => {
        $event.stopPropagation();
        console.log(dispute);
    }
    const rejectDispute = ($event, dispute) => {
        $event.stopPropagation();
        console.log(dispute);
    }
    const modifyDispute = ($event, dispute) => {
        $event.stopPropagation();
        console.log(dispute);
    }

    const createResponseDisputeActions = (dispute) => {
        return <tr>
        <td className={styles.btnOfAHText} colSpan='3'>
        <Tooltip
                title={RejectTooltip()}
                arrow
                placement={'bottom'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'acceptTooltip',
                }}
            >
                <span onClick={($event)=>rejectDispute($event, dispute)}>R</span>
            </Tooltip>
            <Tooltip
                title={AcceptTooltip()}
                arrow
                placement={'bottom'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'acceptTooltip',
                }}
            >
                <span onClick={($event)=>acceptDispute($event, dispute)}>A</span>
            </Tooltip>
            <Tooltip
                title={ModifyTooltip()}
                arrow
                placement={'bottom'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'acceptTooltip',
                }}
            >
                <span onClick={($event)=>modifyDispute($event, dispute)}>M</span>
            </Tooltip>
         </td>

    </tr>;
    }

    const createRequestedDisputeActions = (dispute) => {
        return <tr>
        <td className={styles.btnOfAHText} colSpan='3'>
        <Tooltip
                title={RejectTooltip()}
                arrow
                placement={'bottom'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'acceptTooltip',
                }}
            >
                <span onClick={($event)=>cancelDispute($event, dispute)}>C</span>
            </Tooltip>
            <Tooltip
                title={AcceptTooltip()}
                arrow
                placement={'bottom'}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{
                    tooltip: 'acceptTooltip',
                }}
            >
                <span onClick={($event)=>modfiyDispute($event, dispute)}>M</span>
            </Tooltip>
         </td>

    </tr>;
    }

    const createDisputeList = () => {  //need changes with api
        return purchaseOrderList.map((dispute)=> {
            console.log(dispute);
            console.log(dispute.claimed_by + "=>" + filterDisputeBy);
            if("READY_TO_CLAIM".includes(filterDisputeBy)){  //need changes with api
                const purchaseDate = moment.utc(new Date()).tz('America/Chicago').format('M/DD/YY');
                // const distinctHeaderSet = new Set();
                // dispute.items.forEach((item) => distinctHeaderSet.add(item.shape));
                let poHeader='Beam, Pipe';  //need changes with api
                // let loop=0;
                // distinctHeaderSet.forEach((item) => {
                //     poHeader += item;
                //     if(loop < distinctHeaderSet.size-1) poHeader += ', ';
                //     loop++;
                // })
                let disputeAction;
                let badge;
                if(dispute?.status){  //need changes with api
                    disputeAction = createRequestedDisputeActions(dispute);
                    badge = styles.disputesBlue;
                }
                else{
                    disputeAction= createResponseDisputeActions(dispute);
                    badge = styles.bedgeLogin;
                }
                return (<li key={dispute.id} onClick={() => createDispute(dispute, false)}>
                    <div><span className={badge}></span></div>
                    <div>    
                        <p className={styles.firstLine}>P1234567 (S1234) <IcondownloadIcon /> </p>
                        <p className={styles.secondLine}>Purchase Date : <span className={styles.secondLine}>{purchaseDate}</span></p>
                        <p className={styles.secondLine}>{poHeader}</p>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.firstTdText}>Current</td>
                                    <td className={styles.dollerText}>$</td>
                                    <td className={styles.dollerText}>10,000.00</td>
                                </tr>
                                <tr>
                                    <td className={styles.firstTdText}>Proposed</td>
                                    <td></td>
                                    <td className={styles.dollerText}>9,500.00</td>

                                </tr>
                                {disputeAction}
                            </tbody>
                        </table>
                    </div>
                </li>);
            }
        })
    }

    const createDispute = (order, isEditMode) => {
        console.log(order);
        setOrderDetail(order);
        const navData = { showPopup: false, isEditMode: isEditMode, isDispute: true };
        navigate(routes.acceptOrderPage, { state: navData})
    }

    const changeDisputeFilterBy = (filter) => {
        if(filter === filterDisputeBy) setFilterDisputeBy('');
        else setFilterDisputeBy(filter);
    }

    return (
            <div className={styles.orderContent}>
                <div className={clsx(styles.orderSearch,'orderSearchBg')}>
                    {/* <input type='search' name='search' className={styles.orderSearch} placeholder='Search Orders to Create Dispute' /> */}
                    <Autocomplete
                        id={`searchPoForDispute`}
                        options={purchaseOrderList}
                        // className='autoCompleteMain'
                        // className={classes.root}
                        classes={{
                            root: styles.autoCompleteDesc,
                            popper: styles.autocompleteDescPanel,
                            paper: styles.autocompleteDescInnerPanel,
                            listbox: styles.listAutoComletePanel,
                        }}
                        filterOptions={(options, state) => {
                            return (options.filter( option => option.po_number.toUpperCase().startsWith(state.inputValue.toUpperCase()) ));
                        }}
                        getOptionLabel={(order) => {
                            return order.po_number ? order.po_number : ''
                        }
                        }
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <textarea rows={1} type="text" {...params.inputProps} placeholder={disputeConst.searchPlaceholder} className={clsx(styles.poDescription)} />
                            </div>
                        )}
                        onChange={(event, order) => {
                            createDispute(order,true);
                        }}

                        renderOption={(props, order) => {
                            return (
                                <span key={order.id} {...props}>{order.po_number}</span>
                            );
                        }}
                    />
                    <div className={styles.btnOfOrderSearch}>
                        <button className={filterDisputeBy === purchaseOrder.readyToClaim ? styles.activeBtn : ''} onClick={()=>{changeDisputeFilterBy(purchaseOrder.readyToClaim)}}>Needs Your Attention</button>
                        <button className={filterDisputeBy === purchaseOrder.pending ? styles.activeBtn : ''} onClick={()=>{changeDisputeFilterBy(purchaseOrder.pending)}}>Pending Response</button>
                    </div>
                </div>
                <div className={styles.orderHeading}>
                    <div className={styles.bigHeading}>Click Accept or <span>A</span> ,Reject <span>R</span> , or Modify <span>M</span> to resolve </div>
                    <div className={styles.smallHeading}>Click on the order to see the line item detail</div>
                </div>
                <div className={styles.listOfOrder}>
                    <ul>
                        {createDisputeList()}
                        {/* <li onClick={() => navigate(routes.acceptOrderPage)}>
                            <div><span className={styles.bedgeLogin}></span></div>
                            <div>
                                <p className={styles.firstLine}>P1234567 (S1234) <IcondownloadIcon /> </p>
                                <p className={styles.secondLine}>Purchase Date : <span className={styles.secondLine}>3/15/23</span></p>
                                <p className={styles.secondLine}>Beams, Pipe, Coil</p>

                            </div>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.firstTdText}>Current</td>
                                            <td className={styles.dollerText}>$</td>
                                            <td className={styles.dollerText}>10,000.00</td>
                                        </tr>
                                        <tr>
                                            <td className={styles.firstTdText}>Proposed</td>
                                            <td></td>
                                            <td className={styles.dollerText}>9,500.00</td>

                                        </tr>
                                        <tr>
                                            <td className={styles.btnOfAHText} colSpan='3'>
                                            <Tooltip
                                                    title={RejectTooltip()}
                                                    arrow
                                                    placement={'bottom'}
                                                    disableInteractive
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    classes={{
                                                        tooltip: 'acceptTooltip',
                                                    }}
                                                >
                                                    <span>R</span>

                                                </Tooltip>
                                                <Tooltip
                                                    title={AcceptTooltip()}
                                                    arrow
                                                    placement={'bottom'}
                                                    disableInteractive
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    classes={{
                                                        tooltip: 'acceptTooltip',
                                                    }}
                                                >
                                                    <span>A</span>

                                                </Tooltip>
                                                <Tooltip
                                                    title={ModifyTooltip()}
                                                    arrow
                                                    placement={'bottom'}
                                                    disableInteractive
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    classes={{
                                                        tooltip: 'acceptTooltip',
                                                    }}
                                                >
                                                    <span>H</span>

                                                </Tooltip>
                                                </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li onClick={() => navigate(routes.acceptOrderPage)}>
                            <div><span className={styles.disputesBlue}></span></div>
                            <div>    <p className={styles.firstLine}>P1234567 (S1234) <IcondownloadIcon /> </p>
                                <p className={styles.secondLine}>Purchase Date : <span className={styles.secondLine}>3/15/23</span></p>
                                <p className={styles.secondLine}>Beams, Pipe, Coil</p>

                            </div>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.firstTdText}>Current</td>
                                            <td className={styles.dollerText}>$</td>
                                            <td className={styles.dollerText}>10,000.00</td>
                                        </tr>
                                        <tr>
                                            <td className={styles.firstTdText}>Proposed</td>
                                            <td></td>
                                            <td className={styles.dollerText}>9,500.00</td>

                                        </tr>
                                        <tr>
                                            <td className={styles.btnOfAHText} colSpan='3'><span>R</span><span>A</span><span>H</span> </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li> */}
                    </ul>

                </div>
                <div className={styles.btnSection}>
                    <div>
                        <button onClick={() => navigate(routes.homePage)} className={styles.backBtn}>Back</button>
                    </div>
                    <div className={styles.TermsandConditions}  onClick={()=>navigate(routes.TnCPage, {state : { isViewMode: true, navigateTo: routes.disputePage }})}>Terms and Conditions</div>
                </div>
            </div>
    )
}
export default Dispute;