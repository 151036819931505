const ProductDescription = ({product}) => {
    const lines:string[] = product.UI_Description.split('\n');
    const firstLine:string = lines[0];
    const restLines:string[] = lines.slice(1);

    return (
        <div>
            <p className="liHead">{firstLine}</p>
            {restLines.map((line: string, index:number) => (
                <p key={index}>{line}</p>
            ))}
        </div>
    );
}

export default ProductDescription;