//@ts-nocheck
import { PDF_TEMPLATE_EXPORT, RecevingHoursFrom, RecevingHoursTo, commomKeys, httpsOrigin, pdfMakeData, raygunKeys, refererSuffix, userRole } from '../../../common';
import { dispatchRaygunError, format4DigitAmount, formatPhoneNumber, formatPhoneNumberRemovingCountryCode, formatToTwoDecimalPlaces, getAccessToken, getUnitPostScript, pdfGenreationError } from '../../helper';
import { ReactComponent as ExportPdfIcon } from '../../assets/images/Export-PO.svg';
import DialogPopup from 'src/renderer/component/DialogPopup/DialogPopup';
import { useEffect, useState, useRef } from 'react';
import {  getNumericVersion, refereshImpersonatedUserToken, useGlobalStore, getChannelWindow } from '@bryzos/giss-ui-library';
import { compare } from 'compare-versions';


function PdfMakePage({ getExportPoData, buyingPreferenceData, sellerData, disabled, disableOnclick }) {
    const channelWindow =  getChannelWindow();
    const [isExportPdfGenerate, setIdExportPdfGenreate] = useState('');
    const { setShowLoader, securityHash, appVersion, setAppVersion, isImpersonatedUserLoggedIn, originalLoggedInUserData} = useGlobalStore();
    const appVersionRef = useRef(appVersion);
    let otherDescriptionLines = '';
    let totalOrderWeights = 0;
    const calculateLineWeight = (data) => {
        let lineWeight = 0;
        const updatedUnit = getUnitPostScript(data.qty_unit);
        try {
            if (data?.descriptionObj?.buyerPricing_LB) {
                const buyerPricePerUnit = data.descriptionObj[`buyerPricing_${updatedUnit}`]?.replace(/[\$,]/g, '');
                if (buyerPricePerUnit) {
                    const actualBuyingPricePerUnit = parseFloat(buyerPricePerUnit);
                    const actualBuyerExtended = parseFloat(actualBuyingPricePerUnit * data.qty);
                    lineWeight = actualBuyerExtended / data.descriptionObj.buyerPricing_LB.replace(/[\$,]/g, "");
                }
            } else {
                lineWeight = data.extendedValue / data?.descriptionObj?.Buyer_Pricing_LB.replace(/[\$,]/g, "");
            }
            totalOrderWeights = +(formatToTwoDecimalPlaces(totalOrderWeights)) + +(formatToTwoDecimalPlaces(lineWeight).replace(/[\$,]/g, ""));
        } catch (error) {
            const raygunErrorData = [data.descriptionObj[`buyerPricing_${updatedUnit}`], data.descriptionObj.buyerPricing_LB, data?.descriptionObj?.Buyer_Pricing_LB]
            dispatchRaygunError(
                Error(JSON.stringify(raygunErrorData)),
                raygunKeys.buyerPriceNull.tag
            );
        } finally {
            return formatToTwoDecimalPlaces(lineWeight ?? 0);
        }
    }

    const calculateSellerLineWeight = (data) => {
        totalOrderWeights += +data.total_weight;
        return formatToTwoDecimalPlaces(data.total_weight)

    }
    const descriptionLines = (description) => {
        const descriptionArray = description.split('\n');
        const firstDescriptionLine = descriptionArray[0];
        otherDescriptionLines = descriptionArray.slice(1).join("");
        return firstDescriptionLine;
    }
    async function openPdf() {
        setShowLoader(true);
        const pdfData = {};
        const buyer = buyingPreferenceData?.userType === userRole.buyerUser;
        const seller = sellerData?.userType === userRole.sellerUser;
        let createPoData
        if(buyer && getExportPoData){
            createPoData = getExportPoData();
        }
        if(buyer && !buyingPreferenceData?.phone){
            setIdExportPdfGenreate("No buyer settings found. Please add buyer settings.")
            setShowLoader(false);
            return;
        }
        const itemDetailHeader = [
            { text: "LN", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [false, true, false, false] },
            { text: "Description", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', border: [false, true, false, false] },
            { text: "Qty", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "center" },
            { text: "$ / Unit", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "center" },
            // { text: "", fontSize: 9, font: "InterSemiBold", color: '#0f0f14', border: [false, true, false, false], alignment: "center" },
            { text: "EXT ($)", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "right" },
        ];
        const date = new Date();
        const orderedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear().toString().slice(2);
        if (createPoData) {
            createPoData.stateCode = buyingPreferenceData.stateRef.find(stateDetail => stateDetail.id == createPoData.shipping_details.state_id)?.code
            createPoData.recevingHoursData = [
                [
                    { text: "Receiving Hours (CT)", font: "InterSemiBold", fontSize: 7, alignment: "center", color: '#0f0f14', border: [0, 0, 1, 0], margin: [0, 4, 0, 0] },
                    ...createPoData.recevingHours.map((recHrs, i) => [
                        [
                            { text: [{ text: recHrs.display_name.toUpperCase(), font: "InterSemiBold" }, '\n', { text: recHrs.from === "closed" ? "Closed" : [{ text: RecevingHoursFrom.find(obj => obj.value === +recHrs.from)?.title, }, { text: ' to ' }, { text: RecevingHoursTo.find(obj => obj.value === +recHrs.to)?.title }] }], font: "InterRegular", fontSize: 7, alignment: "center", color: '#0f0f14', },
                        ]
                    ]),
                    { text: [{ text: "SAT", font: "InterSemiBold" }, '\n', { text: "Closed" }], font: "InterRegular", fontSize: 7, alignment: "center", color: '#0f0f14', },
                    { text: [{ text: "SUN", font: "InterSemiBold" }, '\n', { text: "Closed" }], font: "InterRegular", fontSize: 7, alignment: "center", color: '#0f0f14', },
                ]
            ]
        }

        pdfData.data = buyer ? createPoData : sellerData;
        pdfData.data.headerData = buyer ? [
            [
                { text: "Buyer", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                { text: `${buyingPreferenceData.company_name}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
            ],
            [
                { text: "Bryzos Sales Order#", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                { text: '', font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
            ],
            [
                { text: "Buyer PO #", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                { text: `${pdfData.data.internal_po_number}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
            ],
            [
                { text: "Order Confirmation Date", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                { text: '', font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
            ],

            [
                { text: "Version", fontSize: 9, font: "InterSemiBold", color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, true] },
                { text: 'SAVED DRAFT', font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, true] }
            ],

        ]
            :
            [
                [
                    { text: "Supplier", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                    { text: `${pdfData.data.sellerCompanyName}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
                ],
                [
                    { text: "Bryzos PO#", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                    { text: '', font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
                ],
                [
                    { text: "Purchase Order Date", font: "InterSemiBold", fontSize: 9, color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, false] },
                    { text: '', font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, false] }
                ],
                [
                    { text: "Version", fontSize: 9, font: "InterSemiBold", color: '#0f0f14', margin: [0, 0, 0, 3], border: [true, false, false, true] },
                    { text: 'AVAILABLE TO CLAIM', font: "InterRegular", fontSize: 9, color: '#0f0f14', alignment: "right", border: [false, false, true, true] }
                ],

            ]

        pdfData.data.orderInfo = buyer ? [
            [
                { text: "Draft Date", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                { text: "Delivery Due", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                { text: "Freight Term", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                { text: "Ship To", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                { text: "Contact Info", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 0, 0] },
            ],
            [
                { text: `${orderedDate}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                { text: `${pdfData.data.delivery_date}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                { text: `FOB Destination (Delivered)`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                { text: `${buyingPreferenceData.company_name},\n ${pdfData.data.shipping_details.line1},\n ${pdfData.data.shipping_details.city}, ${pdfData.data.stateCode} ${pdfData.data.shipping_details.zip}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                { text: `${buyingPreferenceData.first_name} ${buyingPreferenceData.last_name}\n${buyingPreferenceData.email_id}\n${formatPhoneNumber(formatPhoneNumberRemovingCountryCode(buyingPreferenceData.phone)) ?? ""}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 0, 0] },
            ]
        ]
            :
            [
                [
                    { text: "Date Ordered", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                    { text: "Delivery Due", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                    { text: "Freight Term", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                    { text: "Ship To", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0] },
                    { text: "Contact Info", font: "InterSemiBold", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 0, 0] },
                ],
                [
                    { text: `${pdfData.data.createDateFormat}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                    { text: `${pdfData.data.deliveryDate}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                    { text: `FOB Destination (Delivered)`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                    { text: `${pdfData.data.city}, ${pdfData.data.stateCode} ${pdfData.data.zip}`, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 1, 0]},
                    { text: ``, font: "InterRegular", fontSize: 9, alignment: "center", color: '#0f0f14', border: [0, 1, 0, 0] },
                ]
            ]

        pdfData.data.orderNotes = buyer ?
            [
                [
                    {
                        fontSize: 11,
                        color: '#0f0f14',
                        font: "SyncopateBold",
                        text: 'ORDER NOTES\n'
                    }
                ],
                [
                    {
                        fontSize: 9,
                        color: '#0f0f14',
                        font: "InterRegular",
                        lineHeight: 1.1,
                        margin: [0, 2, 0, 0],
                        text: 'All delivered material will be new (aka “prime”), fulfilled to the defined specification, loaded/packaged for forklift and/or magnetic offloading and have mill test reports. The maximum bundle weight is 5,000 pounds.'
                    }
                ]
            ]
            :
            [
                [
                    {
                        fontSize: 11,
                        color: '#0f0f14',
                        font: "SyncopateBold",
                        text: 'ORDER NOTES\n'
                    }
                ],
                [
                    {
                        fontSize: 9,
                        color: '#0f0f14',
                        font: "InterRegular",
                        lineHeight: 1.1,
                        margin: [0, 2, 0, 0],
                        ul: [
                            { text: [{ text: 'Prices reflect expedited delivery - Delivery date' }, { text: ' MUST ', font: 'InterSemiBold' }, { text: 'be met for all lines on this order unless exception stated explicitly in writing.' }] },
                            'If another length is acceptable for shipment, alternate lengths will be noted as substitutions on that particular line item.',
                            'All delivered material must be new, prime, within the stated tolerance per the product specification and include mill test reports.',
                            'Material must be packaged & loaded for forklift or magnetic offloading.',
                            'Strip load only, no pyramid loading for pipe.',
                            'Material must be reasonably free of oxidation and pitting.',
                            'Maximum bundle weight is 5,000 pounds.',
                            'You agree to fulfill this order (including delivery, fuel surcharges and other fulfillment-related items) at the published price. The only acceptable variance between the published price and your future invoices will be reasonable quantity reconciliations (ie. ordered vs shipped).'
                        ],
                    }
                ]
            ]

        pdfData.data.itemDetail = buyer ? [itemDetailHeader, ...pdfData.data.cart_items.map((data, i) => [
            { pageBreak: i + 1, text: `${i + 1}`, font: "InterSemiBold", fontSize: 11, alignment: 'center', color: '#0f0f14', border: [false, true, false, false], margin: [0, 10, 0, 0] },
            // { pageBreak: i + 1, text: `${data.partNumber ?? ""}\n ${descriptionLines(data.descriptionObj.UI_Description)}\n${otherDescriptionLines}${data.domesticMaterialOnly ? '\nDomestic (USA) Material Only' : ''}`, fontSize: 9, alignment: 'left', color: '#0f0f14', lineHeight: 1.1, border: [false, true, false, false] },
            { pageBreak: i + 1, text: [data.product_tag ? { text: data.product_tag + '\n', font: "InterSemiBold" } : { text: '' }, { text: `${descriptionLines(data.descriptionObj.UI_Description)}`, bold: true }, { text: `${otherDescriptionLines}`, font: "InterRegular" }, { text: `${data.domesticMaterialOnly ? '\nDomestic (USA) Material Only' : ''}`, bold: true }], fontSize: 9, alignment: 'left', color: '#0f0f14', lineHeight: 1.1, border: [false, true, false, false] },
            { pageBreak: i + 1, text: `${formatToTwoDecimalPlaces(data.qty)} ${data.qty_unit}\n ${calculateLineWeight(data)} Lb `, font: "InterRegular", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "center", lineHeight: 1.5},
            { pageBreak: i + 1, text: `$ ${data.price_unit === 'Lb' ? format4DigitAmount(data.price) : formatToTwoDecimalPlaces(data.price)} / ${data.price_unit.toUpperCase()}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "center", margin: [0, 11, 0, 0] },
            { pageBreak: i + 1, text: `$ ${formatToTwoDecimalPlaces(data.extended)}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "right", margin: [0, 11, 0, 0] },
        ])]
            :
            [itemDetailHeader, ...pdfData.data.items.map((data, i) => [
                { pageBreak: i + 1, text: `${i + 1}`, font: "InterSemiBold", fontSize: 11, alignment: 'center', color: '#0f0f14', border: [false, true, false, false], margin: [0, 10, 0, 0] },
                // {pageBreak: i + 1, text: `${descriptionLines(data.description)}\n${otherDescriptionLines}${data.domestic_material_only > 0 ? '\nDomestic (USA) Material Only' : ''}`, fontSize: 9, alignment: 'left', color: '#0f0f14', lineHeight: 1.1, border: [false, true, false, false] },
                { pageBreak: i + 1, text: [{ text: `${descriptionLines(data.description)}`, bold: true }, { text: `${otherDescriptionLines}`, font: "InterRegular" }, { text: `${data.domestic_material_only > 0 ? '\nDomestic (USA) Material Only' : ''}`, bold: true }], fontSize: 9, alignment: 'left', color: '#0f0f14', lineHeight: 1.1, border: [false, true, false, false] },
                { pageBreak: i + 1, text: `${formatToTwoDecimalPlaces(data.qty)} ${data.qty_unit}\n ${calculateSellerLineWeight(data)} Lb `, font: "InterRegular", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "center", lineHeight: 1.5},
                { pageBreak: i + 1, text: `$ ${formatToTwoDecimalPlaces(data.seller_price_per_unit)} / ${data.price_unit.toUpperCase()}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "center", margin: [0, 11, 0, 0] },
                { pageBreak: i + 1, text: `$ ${formatToTwoDecimalPlaces(data.seller_line_total)}`, font: "InterRegular", fontSize: 9, color: '#0f0f14', border: [false, true, false, false], alignment: "right", margin: [0, 11, 0, 0] },
            ])]

        pdfData.data.totalPurchaseBelowText = buyer ?
            "A valid sales tax exemption certificate must be presented to Bryzos prior to purchase to honor a sales tax exemption."
            :
            "Bryzos is a tax-exempt reseller. Please find our sales tax exemption certificate attached to the email with this PO.";

        pdfData.data.headerHeading = buyer ? 'ORDER DRAFT' : 'PENDING SALE';
        pdfData.data.materialTotal = buyer ? pdfData.data.price : pdfData.data.seller_po_price;
        pdfData.data.salesTax = buyer ? pdfData.data.sales_tax : pdfData.data.seller_sales_tax;
        pdfData.data.totalPurchase = buyer ? pdfData.data.totalPurchase : pdfData.data.totalOrderValue;
        pdfData.data.totalOrderWeight = (((totalOrderWeights) - Math.floor(totalOrderWeights)).toFixed(2) === "0.00") ? totalOrderWeights : formatToTwoDecimalPlaces(totalOrderWeights);
        pdfData.data.itemsLength = buyer ? pdfData.data.cart_items.length : pdfData.data.items.length;
        pdfData.pdfName = pdfMakeData.pdfName;
        pdfData.file_name = pdfMakeData.pdfName;

        try {
            if (channelWindow?.fetchPdf) {
                if (isImpersonatedUserLoggedIn) {
                    const token = await refereshImpersonatedUserToken(originalLoggedInUserData);
                    createPdf(pdfData, token);
                } else {
                    const token = await getAccessToken();
                    createPdf(pdfData, token);
                }
            } 
            else if (channelWindow?.generatePdf) window.electron.send({ channel: channelWindow.generatePdf, data: JSON.stringify(pdfData) })
            else setShowLoader(false);
        } catch (error) {
            console.log(error)
            setShowLoader(false);
        }
    }

    const createPdf = (pdfData, token) => {
        pdfData.url = import.meta.env.VITE_API_SERVICE + '/user/createPdf';
        pdfData.file_type = PDF_TEMPLATE_EXPORT;
        if(compare(appVersionRef.current, import.meta.env.VITE_SECURITY_MIDDLEWARE_APP_VERSION, '>=')){
            pdfData.headersObj = { 
                'AccessToken': token,
                'security': securityHash,
                'Origin': httpsOrigin + import.meta.env.VITE_AWS_COGNITO_DOMAIN,
                'Referer': httpsOrigin + import.meta.env.VITE_AWS_COGNITO_DOMAIN+ refererSuffix,
                'User-Agent': import.meta.env.VITE_PDF_USER_AGENT
            };
        }else{
            pdfData.accessToken = token;
        }
        console.log("first", pdfData,appVersion,import.meta.env.VITE_SECURITY_MIDDLEWARE_APP_VERSION);
        window.electron.send({ channel: channelWindow.fetchPdf, data: JSON.stringify(pdfData) })
    }

    useEffect(()=>{
        if (!appVersionRef.current && channelWindow?.electronVersion) {
            appVersionRef.current = window.electron.sendSync({ channel: channelWindow.electronVersion });
            setAppVersion(appVersionRef.current);
        }

        if (channelWindow?.fetchPdfResult) {
            window.electron.receive(channelWindow.fetchPdfResult, (pdfResult) => {
                if (pdfResult.code !== pdfMakeData.codes.cancel) {
                    let message = '';
                    if (pdfResult.code === pdfMakeData.codes.success) {
                        message = pdfMakeData.message.success;
                    } else if (pdfResult.code === pdfMakeData.codes.ebusy) {
                        message = `Update failed: '${pdfResult.data?.fileName}.pdf' is open. Please close it or rename the file.`;
                    } else if (pdfResult.data?.error) {
                        message = pdfMakeData.message.error;
                        dispatchRaygunError(new Error(pdfResult.data.error), [pdfGenreationError]);
                    }
                    setIdExportPdfGenreate(message)
                }
                setShowLoader(false);
            })
        } else if (channelWindow?.generatePdfResult) {
            window.electron.receive(channelWindow?.generatePdfResult,(pdfResult)=>{
                if(pdfResult.code !== pdfMakeData.codes.cancel){
                    let message = '';
                    if(pdfResult.code === pdfMakeData.codes.success){
                        message = pdfMakeData.message.success;
                    }else if(pdfResult.code === pdfMakeData.codes.ebusy) {
                        message = `Update failed: '${pdfResult.data?.fileName}.pdf' is open. Please close it or rename the file.`;
                    }else if(pdfResult.data?.error){
                        message = pdfMakeData.message.error;
                        dispatchRaygunError(new Error(pdfResult.data.error), [pdfGenreationError]);
                    }
                    setIdExportPdfGenreate(message)
                }
                setShowLoader(false);
              })
        }
    },[])

    return (
        <>
            <button onClick={!disableOnclick ? openPdf : undefined} className='exportBtn' disabled={disabled || !appVersionRef.current}><ExportPdfIcon /> Export PO</button>
            <DialogPopup
                dialogContent={isExportPdfGenerate}
                dialogBtnTitle={commomKeys.successBtnTitle}
                type={commomKeys.actionStatus.success}
                open={!!isExportPdfGenerate}
                onClose={() => setIdExportPdfGenreate(false)}
            />
        </>
    )
}

export default PdfMakePage;