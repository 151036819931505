// @ts-nocheck
import { useMutation } from "@tanstack/react-query";
import { cassErrorMessage } from "../../common";
import axios from "axios";
import { dispatchRaygunError } from "../helper";

const useCreateCassSupplier = () => {
  return useMutation(async ({ data, accessToken }) => {
    try {
      let url = `${import.meta.env.VITE_API_CASS_SAVE_MASTER_DATA}`;

      const response = await axios.post(url, data, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          skip: "true",
          skipInterceptor: "true",
        },
      });

      if (response?.data?.validationErrors?.length) {
        dispatchRaygunError(
          Error(JSON.stringify(response.data.validationErrors)),
          cassErrorMessage
        );

        throw Error(JSON.stringify(response.data.validationErrors));
      } else {
        return response?.data?.uniqueIdentifier;
      }
    } catch (error) {
      if (error?.response?.data?.validationErrors?.length) {
        dispatchRaygunError(
          Error(JSON.stringify(error.response.data.validationErrors)),
          cassErrorMessage
        );

        throw Error(JSON.stringify(error.response.data.validationErrors));
      } else if (error?.response?.data?.errors) {
        dispatchRaygunError(
          Error(JSON.stringify(error.response.data.errors)),
          cassErrorMessage
        );

        const _erros = [];
        for (const property in error.response.data.errors) {
          if (
            error.response.data.errors[property] &&
            error.response.data.errors[property].length
          ) {
            error.response.data.errors[property].forEach((err) => {
              _erros.push({ errorMessage: err });
            });
          }
        }
        throw Error(JSON.stringify(_erros));
      } else {
        dispatchRaygunError(
          Error(JSON.stringify(error?.message ?? error)),
          cassErrorMessage
        );

        throw Error(
          JSON.stringify([{ errorMessage: error?.message ?? error }])
        );
      }
    }
  });
};

export default useCreateCassSupplier;
