import { useEffect, useRef, useState } from "react";
import { MinSearchDataLen, keyDown, keyEnter, keyUp } from "../../../common";
import { CommonTooltip } from "../../component/Tooltip/tooltip";
import { useDebouncedValue } from "@mantine/hooks";
import axios from "axios";
import ProductDescription from "../../component/ProductDescription/ProductDescription";
import { ProductPricingModel } from "../../types/Search";
import { emojiRemoverRegex, getValidSearchData, searchProducts } from '@bryzos/giss-ui-library';
import { ReferenceDataProduct } from "../../types/ReferenceDataProduct";

type ProductSearchState = {
    products: ReferenceDataProduct[];
    selectedProducts: ProductPricingModel[];
    selectProduct: (product: ReferenceDataProduct) => void,
    sessionId: string
};

const ProductSearch: React.FC<ProductSearchState> = ({products, selectedProducts , selectProduct, sessionId}) => {
    const [searchData, setSearchData] = useState<string>('');
    const [searchByProductResult, setSearchByProductResult] = useState<ReferenceDataProduct[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const selectedProductRef = useRef(null);
    const searchProductInputRef = useRef<any>(null);
    const [debouncedSearchData, cancelDebouncedSearchData] = useDebouncedValue(searchData, 400);
    const [enableRejectSearchAnalytic, setEnableRejectSearchAnalytic]= useState<boolean>(true);

    useEffect(() => {
        if(debouncedSearchData?.length >= MinSearchDataLen ){
            searchAnalyticsApi(sessionId, null, debouncedSearchData)
        }
    },[debouncedSearchData])
    
    useEffect(() => {
        const container = containerRef.current;
        const selectedItem:any = selectedProductRef.current;
        if (container && selectedProductRef && selectedItem) {
            const itemTop = selectedItem.offsetTop;
            const itemHeight = selectedItem.offsetHeight;
            const containerHeight = container.offsetHeight;
            const scrollOffset = container.scrollTop;
            if (itemTop < scrollOffset) {
                container.scrollTop = itemTop - 90;
            } else if (itemTop + itemHeight > scrollOffset + containerHeight) {
                container.scrollTop = itemTop + itemHeight - containerHeight - 60;
            }
        }

    }, [selectedIndex]);

    useEffect(() => {
        if(searchData.length === 1 ){
            setEnableRejectSearchAnalytic(true)
        }
        if(searchData.length === 0 && searchByProductResult.length === 0 && debouncedSearchData && enableRejectSearchAnalytic){
            searchAnalyticsApi(sessionId, 'Reject', debouncedSearchData)
        }
    },[searchData])

    const searchHandler = (text: string) => {
        setSearchData(text);
        const searchStrings = getValidSearchData(text);
        const productSearch = searchProducts(products, searchStrings, text);
        setSearchByProductResult(productSearch);
    }

    const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const startIndex = 0;
        const endIndex = searchByProductResult.length - 1;
        if (event.key === keyUp && selectedIndex > startIndex) {
            setSelectedIndex(selectedIndex - 1)
        } else if (event.key === keyDown && selectedIndex < endIndex) {
            setSelectedIndex(selectedIndex + 1)
        }else if(event.key === keyEnter && selectedIndex >= startIndex){
            setSelectedIndex(-1);
            const element: any = selectedProductRef.current;
            const selectedProductId = element.getAttribute('data-value');
            selectProductFromSearch(products[selectedProductId - 1]);
        }
    }

    const searchAnalyticsApi = (sessionId:string, status:string | null, searchKeyword:string) => {
        const payload = {
            "data": {
                "session_id": sessionId,
                "keyword" : searchKeyword,
                "status" : status,
                "source" : 'search'
            }
          }
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/create_po_search', payload)
        .catch(err => console.error(err))
    }

    const selectProductFromSearch = (product: ReferenceDataProduct) => {
        setEnableRejectSearchAnalytic(false)
        searchAnalyticsApi(sessionId, 'Accept', debouncedSearchData)
        selectProduct(product);
        setSearchByProductResult([]);
        setSearchData('');
        if(searchProductInputRef.current)
        searchProductInputRef.current.focus();
    }

    return <>
        <div className={`homeBody ${searchData === '' && selectedProducts.length === 0 ? '' : 'bdrRadiusNone'}`}>
            <CommonTooltip
                title={'Use this search bar to search any product using any description'}
                tooltiplabel={
                    <input 
                        type='search' 
                        name='search' 
                        onKeyDown={handleSearchKeyDown} 
                        value={searchData} 
                        onChange={event => { 
                            event.target.value = event.target.value.replace(emojiRemoverRegex, '');
                            searchHandler(event.target.value)
                        }} 
                        placeholder={selectedProducts.length === 0 ? 'Search by Product' : 'Search Another Product'} 
                        ref={searchProductInputRef}/>
                }
                placement={'top-start'}
                open={selectedProducts.length !== 0}
                classes={{
                    popper: 'tooltipPopper',
                    tooltip: 'tooltipMain tooltipSearch',
                    arrow: 'tooltipArrow'
                }}
                localStorageKey="searchbarTooltip"
            />
        </div>
        {
            searchByProductResult.length > 0 && 
            <div className='listBody removePaddingBottom'>
            <div className='productListContainer'>
                <div className='ulBody' ref={containerRef}>
                    {(searchData.length > 1) && 
                        <>
                            {searchByProductResult.map((product: ReferenceDataProduct, index: number) => (  
                                <CommonTooltip
                                key={product.id}
                                title={'Click on the product to view instant pricing'}
                                tooltiplabel={
                                    <div className={`liBody ${index === selectedIndex ? 'selectedLiBody' : ''}`}
                                    ref={index === selectedIndex ? selectedProductRef : null}
                                    data-value={product.id}
                                    onClick={() => selectProductFromSearch(product)}>
                                        <ProductDescription product = {product}/>
                                    </div>
                                }
                                placement={'top-end'}
                                classes={{
                                    popper: 'tooltipPopper tooltipSearchList',
                                    tooltip: 'tooltipMain tooltipRight2',
                                    arrow: 'tooltipArrow'
                                }}
                                localStorageKey="searchListTooltip"
                                />
                            ))}
                        </>
                    }
                </div>
            </div>
            <div className='lineH'></div>
        </div>
        }
        
    </>
}

export default ProductSearch;