import { create } from 'zustand';

const snackbarStoreInit = {
    openSnackbar: false,
    snackbarMessage: '',
    snackbarSeverity: 'alert',
    snackbarActions: null,
    snackbarCloseHandler: null
}

const useSnackbarStore = create((set) => ({
    ...snackbarStoreInit,
    setSnackbarOpen: (isOpen: boolean) => set({ openSnackbar: isOpen }),
    setSnackbarMessage: (msg: string) => set({ snackbarMessage: msg }),
    setSnackbarSeverity: (sev: string) => set({ snackbarSeverity: sev }),
    setSnackbarActions: (action: any) => set({ snackbarActions: action }),
    showToastSnackbar: (msg: string, sev: string, actions:any, closeHandler:any) => set({openSnackbar: true,snackbarMessage: msg, snackbarSeverity: sev, snackbarActions: actions, snackbarCloseHandler:closeHandler}),
    resetSnackbarStore: () => set((state:any) => ({
        ...snackbarStoreInit
    }))
}));

export default useSnackbarStore;