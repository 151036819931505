// @ts-nocheck
import React from "react";
import styles from './onboardingFooter.module.scss';
import { useNavigate } from "react-router";
import { routes } from "../../../common";


function OnboardingFooter() {
    const navigate = useNavigate();
    return (
        <div className={styles.onboardingFooterPage}>
                <div className={styles.patentPandingTxt}>PATENT PENDING</div>
        </div>
    );
}
export default OnboardingFooter;
