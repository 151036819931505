// @ts-nocheck
import { useMutation } from "@tanstack/react-query";
import { reactQueryKeys } from "../../common";
import axios from "axios";

const useSaveUserActivity = () => {
  return useMutation(async ({url, payload}) => {
    try {
      const response = axios.post(url, payload);
      return await response;
    } catch (error) {
      throw new Error(error?.message ?? error);
    }
  });
};

export default useSaveUserActivity;
