// @ts-nocheck
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useGetUserDiscountData = () => {
  return useMutation(async () => {
    try {
      let url = `${import.meta.env.VITE_API_SERVICE}/user/discountData`;

      const response = axios.get(url);
      const responseData = await response;

      if (responseData.data && responseData.data.data) {
        if (
          typeof responseData.data.data === "object" &&
          "err_message" in responseData.data.data
        ) {
          throw new Error(responseData.data.data.err_message);
        } else {
          return responseData.data.data;
        }
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error?.message ?? error);
    }
  },
  {
    retry: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  }
  );
};

export default useGetUserDiscountData;
