import { ReactComponent as UpDarkArrow } from './assets/images/p-up-dark-arrow.svg';
import { ReactComponent as CheckDarkIcon } from './assets/images/p-check-dark.svg';
import { ReactComponent as DownDarkArrow } from './assets/images/p-down-dark-arrow.svg';
import { Tooltip, Fade } from '@mui/material';

// Header 
export const pinTooltip = () => {
    return (
        <>
            <div className='pinText'>Pin this widget to your desktop to always remain in view. Unpin to allow it to hide behind other open screens.</div>

        </>
    )
}
// Header 
// Home Page 
export const priceFeedBakTooltip = () => {
    return (
        <>
            <div className='headTooltip'>How’s the Pricing?</div>
            <div className='containTooltip'><UpDarkArrow /> =  Pricing seems high</div>
            <div className='containTooltip'><CheckDarkIcon /> =  Price is good</div>
            <div className='containTooltip'><DownDarkArrow /> =  Honestly, pricing seems low</div>

        </>
    )
}
export const resetBtnTooltip = () => {
    return (
        <>
            <div className='resetTooltipText'>Clear your search history for this session.</div>

        </>
    )
}
export const shareListTooltip = () => {
    return (
        <>
            <div className='resetTooltipText'>Share pricing with friends by selecting the lines you want to share and, then, clicking this share icon.</div>

        </>
    )
}
export const shareWidgetTooltip = () => {
    return (
        <>
            <div className='shareWidgetText'>Tell your friends & coworkers! Email a link to download this pricing tool.</div>

        </>
    )
}
// Home Page 
// dispute page 
export const AcceptTooltip = () => {
    return (
        <>
            <div className='acceptText'>Accept</div>

        </>
    )
}
export const RejectTooltip = () => {
    return (
        <>
            <div className='acceptText'>Reject</div>

        </>
    )
}
export const ModifyTooltip = () => {
    return (
        <>
            <div className='acceptText'>Modify</div>

        </>
    )
}
// dispute page 
// Order Page 
export const HideTooltip = () => {
    return (
        <>
            <div className='acceptText'>Hide</div>

        </>
    )
}
// Order Page 
// create Order Page 
export const JobPOTooltip = () => {
    return (
        <>
            <div className='acceptText'>Internal reference name or number you will use in your ERP for this order</div>

        </>
    )
}
export const DeliveryToTooltip = () => {
    return (
        <>
            <div className='acceptText'>Destination address for this order</div>

        </>
    )
}
export const DeliveryDateTooltip = () => {
    return (
        <>
            <div className='acceptText'>Date you expect to receive this order</div>

        </>
    )
}
export const SalesTaxTooltip = () => {
    return (
        <>
            <div className='acceptText'>If you have a valid Sales Tax Exemption Certificate that should apply to this transaction, upload that Certificate in your Settings. Unfortunately, at this time, we are not able to hold pricing. However, the pricing is unlikely to change if you go to Buyer Settings now and upload your certificate. (Hint: Click on gear at the top of this page.)</div>

        </>
    )
}
// create Order Page 
// Buyer Setting Page 
export const BCompanyNameTooltip = () => {
    return (
        <>
            <div className='acceptText'>The parent company name of your organization (possibly just your company name).</div>

        </>
    )
}
export const CompanyEntityTooltip = () => {
    return (
        <>
            <div className='acceptText'>Your organization's name, DBA, or specific branch location (possibly the same as Main Company Name).</div>

        </>
    )
}
export const BCompanyAddressTooltip = () => {
    return (
        <>
            <div className='acceptText'>Where would documents be mailed?</div>

        </>
    )
}
export const BYourNameTooltip = () => {
    return (
        <>
            <div className='acceptText'>What name is on your business card?</div>

        </>
    )
}
export const BEmailPhoneTooltip = () => {
    return (
        <>
            <div className='acceptText'>How do people contact you at work?</div>

        </>
    )
}
export const BDeliverToTooltip = () => {
    return (
        <>
            <div className='acceptText'>Where should your orders be delivered to primarily? (This can be adjusted on each PO if it varies.)</div>

        </>
    )
}
export const BDeliverDateTooltip = () => {
    return (
        <>
            <div className='acceptText'>How soon do you typically expect to receive your material upon placing an order? (This can be adjusted on each PO if it varies.)</div>

        </>
    )
}
export const BSendInvoicesTooltip = () => {
    return (
        <>
            <div className='acceptText'>What is an email contact for your Accounts Payable department?</div>

        </>
    )
}
export const BShippingDocsTooltip = () => {
    return (
        <>
            <div className='acceptText'>Who would you like receive a copy of Shipping Docs (packing lists, MTRs, etc)?</div>

        </>
    )
}
export const BResaleCertTooltip = () => {
    return (
        <>
            <div className='acceptText'>Providing a valid Sales Tax Exemption Certificate will grant tax exemption for any purchases delivering to that specific exempt state.</div>

        </>
    )
}
export const BResaleCertExpireTooltip = () => {
    return (
        <>
            <div className='acceptText'>Select the option from the dropdown menu that most closely defines your Sales Tax Exemption Certificate's expiration date. Select Never if there is no expiration.</div>

        </>
    )
}
export const BReceivingHoursTooltip = () => {
    return (
        <>
            <div className='acceptText'>What is the earliest and the latest a truck could arrive to your delivery address on any given day?</div>

        </>
    )
}
export const BNet30TermsTooltip = () => {
    return (
        <>
            <div className='acceptText'>"Buy Now, Pay Later" (BNPL) allows you to purchase orders on credit with Net 30 payment terms. Bryzos partners with a third-party creditor that will evaluate and determine your credit access on Bryzos based on your application here. After applying, you will be notified via email of the approval status and your available credit details will be visible in this section of your Settings.</div>

        </>
    )
}
export const BACHCreditTooltip = () => {
    return (
        <>
            <div className='acceptText'>ACH Credit allows you to "push" funds from your bank account to Bryzos to make payments on invoiced orders. Any purchases made using ACH Credit will require immediate payment to process and fulfill the order. Any purchases made using ACH Credit will be considered on hold until payment is received.</div>

        </>
    )
}
// Buyer Setting Page 
// Seller Setting Page 
export const SCompanyNameTooltip = () => {
    return (
        <>
            <div className='acceptText'>The parent company name of your organization (possibly just your company name).</div>

        </>
    )
}
export const SCompanyAddressTooltip = () => {
    return (
        <>
            <div className='acceptText'>Where would documents be mailed?</div>

        </>
    )
}
export const SYourNameTooltip = () => {
    return (
        <>
            <div className='acceptText'>What do people call you?</div>

        </>
    )
}
export const SEmailPhoneTooltip = () => {
    return (
        <>
            <div className='acceptText'>How do people contact you at work?</div>

        </>
    )
}
export const SStockingLocationTooltip = () => {
    return (
        <>
            <div className='acceptText'>Where will your orders be shipping from primarily?</div>

        </>
    )
}
export const SSendInvoicesTooltip = () => {
    return (
        <>
            <div className='acceptText'>Share this email address with your Accounts Receivable department to which they can send all Bryzos invoices.</div>

        </>
    )
}
export const SShippingDocsTooltip = () => {
    return (
        <>
            <div className='acceptText'>Share this email address with your Operations department to which they can send all Bryzos-related shipping documents.</div>

        </>
    )
}
export const SIRSW9FormTooltip = () => {
    return (
        <>
            <div className='acceptText'>Bryzos needs a copy of your company's W-9 Form to complete customer setup.</div>

        </>
    )
}
export const SYourProductsTooltip = () => {
    return (
        <>
            <div className='acceptText'>Let us know what you sell! Upload a copy of your Line Card.</div>

        </>
    )
}
export const SWireTooltip = () => {
    return (
        <>
            <div className='acceptText'>If selected, Bryzos will Wire transfer all funds to your company bank account listed here as payment for your sales.</div>

        </>
    )
}
export const SACHCreditTooltip = () => {
    return (
        <>
            <div className='acceptText'>If selected, Bryzos will transfer funds via ACH to your company bank account listed here as payment for your sales.</div>

        </>
    )
}
// Seller Setting Page 
// orderConfimation page 
export const SUploadYourSOTooltip = () => {
    return (
        <>
            <div className='acceptText'>Upload or email a copy of your internal Sales Order document from your ERP system for this order</div>

        </>
    )
}
export const BUploadYourSOTooltip = () => {
    return (
        <>
            <div className='acceptText'>Upload or email a copy of your internal Purchase Order document from your ERP system for this order</div>

        </>
    )
}
// order preview button tooltip 
export const orderPreviewTooltip = () => {
    return (
        <>
            <div className='acceptText'>Order will be available to claim soon</div>

        </>
    )
}
// signup tooltip
export const signupTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Click here to create a new account</div>

        </>
    )
}
export const shareappTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Click here to share this desktop widget with your friends!</div>

        </>
    )
}
export const priceUnitChangeTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Here, you can change your pricing search based on Dollars per Pound, Dollars per Foot, or Dollars per Hundredweight.</div>

        </>
    )
}
export const activeOrderTooltip = () => {
    return (
        <>
            <div className='tooltipText'>This order is now available to claim</div>

        </>
    )
}
export const pendingOrderTooltip = () => {
    return (
        <>
            <div className='tooltipText'>This order is still in review mode</div>

        </>
    )
}
export const orderPageTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Click A to accept an order, click H to hide an order.</div>

        </>
    )
}
export const chooseYourUnitTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Here, you can choose your unit of measure for pricing products (we know...default setting to come soon)</div>

        </>
    )
}

export const deleteCertificateTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Delete Uploaded Certificate.</div>

        </>
    )
}

export const deleteCertificateLineTooltip = () => {
    return (
        <>
            <div className='tooltipText'>Delete Certificate Line.</div>

        </>
    )
}

export const ChatIconToolTip = ({ children }: any) => {
    return (<Tooltip
        title={<div className='tooltipText'>Connect with other Bryzos members</div>}
        arrow
        placement={'top-end'}
        disableInteractive
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 200 }}
        classes={{
            popper: 'pinTooltipPopper',
            tooltip: 'pinTooltip',
            arrow: 'arrowTooltip'
        }}
    >
        {children}
    </Tooltip>)
}