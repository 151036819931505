// @ts-nocheck
import clsx from 'clsx';
import { CustomMenu } from '../buyer/CustomMenu';
import styles from './onboardingDetails.module.scss'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './onboardingDetailsSchema';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { userTypes, routes, useCompanies, useVerifyUserEmail, useVerifyZipCode } from '@bryzos/giss-ui-library';
import { ReactComponent as ErrorEmailIcon } from '../../assets/images/errorEmail.svg';
import OnboardingFooter from './onboardingFooter';
import { Autocomplete, Fade, Tooltip } from '@mui/material';
import { emojiRemoverRegex, useGlobalStore } from '@bryzos/giss-ui-library';
import axios from 'axios';
import { Auth } from 'aws-amplify';

function OnboardingDetails() {
  const setShowLoader = useGlobalStore(state => state.setShowLoader);
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  const { register, handleSubmit, getValues, clearErrors, setValue, watch, setError, control, formState: { errors, dirtyFields, isDirty, isValid } } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState({
    userType: false,
    CompanyName: false,
    CompanyEntity: false,
    ZipCode: false,
    FirstName: false,
    LastName: false,
    EmailAddress: false,
    ReEnterEmailAddress: false,
    submitBtn: false,
  });
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [companyNameValue, setCompanyNameValue] = useState(null);
  const [openCompanyName, setOpenCompanyName] = useState(false);
  const [onboardCompanies, setOnboardCompanies] = useState([]);
  const [companyEntityInput, setCompanyEntityInput] = useState("");
  const [companyEntityValue, setCompanyEntityValue] = useState(null);
  const [onboardCompanyEntities, setOnboardCompanyEntities] = useState([]);
  const [disableCompanyEntity, setDisableCompanyEntity] = useState(true);
  const [isSelectedCompanyName, setIsSelectedCompanyName] = useState(false);

  const { data: companiesData, isLoading: companiesLoading } = useCompanies();
  const verifyUserEmailMutation = useVerifyUserEmail();
  const verifyZipCodeMutation = useVerifyZipCode();

  useEffect(() => {
    if (companiesData?.data && typeof companiesData.data !== 'string') {
      setOnboardCompanies(companiesData.data);
    }
  }, [companiesData]);

  useEffect(() => {
    if (userData?.data) {
      setValue("userType", userData?.data.userType)
      setValue("companyName", userData?.data.companyName)
      setValue("firstName", userData?.data.firstName)
      setValue("lastName", userData?.data.lastName)
      setValue("emailAddress", userData?.data.emailAddress)
      if (userData?.errorMessage) {
        setValue("reEnterEmailAddress", "")
        setError("emailAddress", { message: userData.errorMessage }, { shouldFocus: true })
      }
      setValue("zipCode", userData?.data.zipCode)
      setValue("companyEntity", userData?.data.companyEntity)
      userData.zipcodeErrorMessage && setError("zipCode", { message: 'Zip is not valid' })
    }
  }, [userData])

  useEffect(() => {
    if(watch('companyName')?.length > 1){
      setDisableCompanyEntity(false)
      const companyData = onboardCompanies?.find((companyData) => companyData.company_name === watch("companyName"))
      setOnboardCompanyEntities(companyData?.client_company ?? [])
    }else{
      setDisableCompanyEntity(true)
      setOnboardCompanyEntities([])
    }
  },[watch('companyName')])

  useEffect(() => {
    if (companyNameValue) {
      const top = document.querySelector(`[company_id="${companyNameValue.id}"]`)?.offsetTop;
      document.getElementById("combo-box-demo-listbox") && (document.getElementById("combo-box-demo-listbox").scrollTop = top);
      setIsSelectedCompanyName(false)
    }
  }, [isSelectedCompanyName])

  const handleInputFocus = (inputName) => {
    setIsInputFocused((inputState) => ({
      ...inputState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputFocused((inputState) => ({
      ...inputState,
      [inputName]: false,
    }));
  };
  const isAnyInputFocused = Object.values(isInputFocused).some((state) => state);

  const onSubmit = async (data) => {
    try {
      setShowLoader(true)
      const verifyOnBoardUserEmailPayload = {
        data: {
          email_id: data.emailAddress,
        },
      };
      const verifyOnBoardUserZipcodePayload = {
        data: {
          zip_code: data.zipCode,
        },
      };

      const emailVerification = await verifyUserEmailMutation.mutateAsync(verifyOnBoardUserEmailPayload);
      const zipCodeVerification = await verifyZipCodeMutation.mutateAsync(verifyOnBoardUserZipcodePayload);

      if (emailVerification.data?.error_message || zipCodeVerification.data?.error_message) {
        navigate(routes.onboardingDetails, {
          state: {
            data,
            errorMessage: emailVerification.data?.error_message,
            zipcodeErrorMessage: zipCodeVerification.data?.error_message,
            tncCheckbox: false,
          },
        });
        setShowLoader(false);
      } else {
        navigate(routes.onboardingTnc, { state: data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailBlur = (e) => {
    const emailAddress = getValues('emailAddress')?.trim();
    const reEnterEmailAddress = getValues('reEnterEmailAddress')?.trim();
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailPattern.test(emailAddress) && emailPattern.test(reEnterEmailAddress) && emailAddress === reEnterEmailAddress) {
      clearErrors(["emailAddress", "reEnterEmailAddress"]);
    } else {
      if (!emailPattern.test(emailAddress)) {
        setError("emailAddress", { message: "Email is not valid" });
      } else {
        setError("emailAddress", { message: "Email does not match!" });
      }
      if (!emailPattern.test(reEnterEmailAddress) && reEnterEmailAddress.length !== 0) {
        setError("reEnterEmailAddress", { message: "Email is not valid" });
      } else {
        setError("reEnterEmailAddress", { message: "Email does not match!" });
      }
    }
  }

  return (
    <div className={clsx('bgBlurContent', styles.onboardingDetailsMain, isAnyInputFocused || isDropdownOpen || isCompanyDropdownOpen ? styles.isBackdrop : '')}>
      <div className={styles.onboardingLogo}>
        <img src='/onboardingLogo.png' />
      </div>
      <div className={styles.onboardingForm}>
        <CustomMenu
          keyHandler={() => { handleInputFocus('userType'); setIsDropdownOpen(true) }}
          control={control}
          name={'userType'}
          placeholder={'Choose User Type'}
          MenuProps={{
            classes: {
              paper: clsx(styles.Dropdownpaper, 'selectUserType'),
              list: styles.muiMenuList,
            },
          }}
          onOpen={() => { setIsDropdownOpen(true) }}
          onClick={() => {
            handleInputFocus('userType')
            setIsDropdownOpen(true)
          }}
          onClose={() => {
            handleInputBlur('userType')
            setIsDropdownOpen(false)
          }}
          category={'THIS CANNOT BE CHANGED...CHOOSE WISELY.'}
          items={userTypes.map(userType => ({ title: userType.name, value: userType.value }))}
          className={clsx('selectDropdown1', isDropdownOpen ? 'dropdownFocused' : '')}
        />
        <div >
          <Controller
            name='companyName'
            control={control}
            render={({ field: { ...rest } }) => (
              <Autocomplete
                freeSolo
                id={`combo-box-demo`}
                open={companyNameInput.length > 1 && openCompanyName}
                onOpen={() => {
                  setOpenCompanyName(true)
                  if(companyNameValue) setIsSelectedCompanyName(true)
                }}
                onClose={(e) => {
                  setOpenCompanyName(false)
                }}
                options={onboardCompanies?.length ? onboardCompanies : []}
                value={companyNameValue}
                inputValue={companyNameInput}
                className={clsx(styles.inputOnboarding1)}
                onInputChange={(event, value, reason) => {
                  if(reason !== "reset" || value.length > 0){
                    const data = value.replace(emojiRemoverRegex, '')
                    setCompanyNameInput(data)
                    setValue('companyName', data, { shouldDirty: true })
                  }
                }}
                disablePortal={true}
                classes={{
                  root: styles.autoCompleteDesc,
                  popper: styles.autocompleteDescPanel,
                  paper: styles.autocompleteDescInnerPanel,
                  listbox: styles.listAutoComletePanel,
                }}
                getOptionLabel={(item) => {
                  return item.company_name ? item.company_name : "";
                }}
                renderOption={(props, item: any) => <span {...props} company_id={item.id}>{item.company_name ? item.company_name : ""}</span>}
                renderInput={(params) => (
                  <div className={clsx(styles.companyNameInput, errors?.companyName && styles.error)} ref={params.InputProps.ref}>
                    <input
                      type="text"
                      {...params.inputProps}
                      placeholder="Main Company Name"
                      className={clsx(styles.poDescription)}
                    />
                  </div>
                )}
                onChange={(event, item, reason) => {
                  if (reason === "selectOption") {
                    setCompanyNameInput(item?.company_name)
                    setValue('companyName', item?.company_name, { shouldDirty: true })
                  }
                  setCompanyNameValue(item)
                  rest.onChange(item?.company_name ?? companyNameInput);
                }}
                onBlur={(e) => {
                  if (/<|>/g.test(e.target.value) || e.target.value.length === 0) {
                    setError("companyName", { message: 'Company Name is not valid' })
                  } else {
                    clearErrors('companyName')
                  }
                  rest.onBlur(e);
                  handleInputBlur('CompanyName')
                  setIsCompanyDropdownOpen(false)
                }}
                onFocus={() => {
                  handleInputFocus('CompanyName')
                  setIsCompanyDropdownOpen(true)
                }}
              />
            )}
          />

          <div  className={clsx(styles.inputOnboarding1)}>
            <Controller
            name="companyEntity"
            control={control}
            render={({ field: { ...rest } }) => (
                <Autocomplete
                  disabled={disableCompanyEntity}
                  className={clsx(styles.inputOnboarding1)}
                  classes={{
                    root: styles.autoCompleteDesc,
                    popper: styles.autocompleteDescPanel,
                    paper: styles.autocompleteDescInnerPanel,
                    listbox: styles.listAutoComletePanel,
                  }}
                  id={`combo-box-demo1`}
                  freeSolo
                  value={companyEntityValue}
                  inputValue={companyEntityInput}
                  onChange={(event, value) => {
                    setCompanyEntityValue(value);
                    rest.onChange(value ?? null);
                  }}
                  onInputChange={(event, newInputValue) => {
                    const data = newInputValue.replace(emojiRemoverRegex, '')
                    setCompanyEntityInput(data);
                      setValue("companyEntity", data, { shouldDirty: true });
                  }}
                  onBlur={(e) => {
                    if (/<|>/g.test(e.target.value) || e.target.value.length === 0) {
                      setError("companyEntity", { message: 'Company Entity is not valid' })
                    } else {
                      clearErrors('companyEntity')
                    }
                    rest.onBlur(e);
                    handleInputBlur('CompanyEntity')
                    setIsCompanyDropdownOpen(false)
                  }}
                  onFocus={() => {
                    handleInputFocus('CompanyEntity')
                    setIsCompanyDropdownOpen(true)
                  }}
                  disablePortal={true}
                  options={onboardCompanyEntities?.length ? onboardCompanyEntities :[]}
                  renderInput={(params) => (
                    <div className={clsx(styles.companyNameInput, errors?.companyEntity && styles.error)} ref={params.InputProps.ref}>
                      <input
                        type="text"
                        {...params.inputProps}
                        placeholder="Your Company Entity/Location"
                        className={clsx(styles.poDescription)}
                      />
                    </div>
                  )}
                  getOptionLabel={(item) => {
                      return item ?? "";
                  }}
                />
                )}
            />
          </div>

          <div className={clsx(styles.inputOnboarding, styles.inputOnboarding1)}>
            <input className={clsx(styles.input1, errors?.firstName && styles.error)}
              {...register("firstName")}
              placeholder='First Name'
              onFocus={() => handleInputFocus('FirstName')}
              onBlur={(e) => {
                register("firstName").onBlur(e);
                handleInputBlur('FirstName')
              }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                register('firstName').onChange(e);
              }}
            />

            <input className={clsx(styles.input1, errors?.lastName && styles.error)}
              {...register("lastName")}
              placeholder='Last Name'
              onFocus={() => handleInputFocus('LastName')}
              onBlur={(e) => {
                register("lastName").onBlur(e);
                handleInputBlur('LastName')
              }}
              onChange={(e)=>{
                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                register('lastName').onChange(e);
              }}
            />
          </div>

          <div className={styles.emailErrorContainer}>
            <Tooltip
              title={errors?.emailAddress?.message || errors?.reEnterEmailAddress?.message}
              arrow
              placement={"top"}
              disableInteractive
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
              classes={{
                tooltip: "inputQtyTooltip",
              }}
            >
              <input {...register("emailAddress")} className={clsx(styles.inputOnboarding, (errors?.emailAddress || errors?.reEnterEmailAddress) && styles.error)} type='text' placeholder='Email Address'
                onFocus={() => handleInputFocus('EmailAddress')}
                onBlur={(e) => {
                  register("emailAddress").onBlur(e);
                  handleInputBlur('EmailAddress')
                  handleEmailBlur(e)
                }}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                  register('emailAddress').onChange(e);
                }}
              />
            </Tooltip>

            <input {...register("reEnterEmailAddress")} className={clsx(styles.inputOnboarding, (errors?.emailAddress || errors?.reEnterEmailAddress) && styles.error)} type='text' placeholder='Re-Enter Email Address'
              onFocus={() => handleInputFocus('ReEnterEmailAddress')}
              onBlur={(e) => {
                register("reEnterEmailAddress").onBlur(e);
                handleInputBlur('ReEnterEmailAddress');
                handleEmailBlur(e);
              }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                register('reEnterEmailAddress').onChange(e);
              }}
            />
            {(errors?.emailAddress || errors?.reEnterEmailAddress) &&
              <div className={styles.errorBorder}>
                <ErrorEmailIcon />
              </div>
            }
            <Tooltip
              title={errors?.password?.message}
              arrow
              placement={"top"}
              disableInteractive
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
              classes={{
                tooltip: "inputQtyTooltip",
              }}
            >
              <input {...register("password")} className={clsx(styles.inputOnboarding, errors?.password && styles.error)} type='password' placeholder='Enter your password'
                onFocus={() => handleInputFocus('password')}
                onChange={(e) => {
                  register("password").onChange(e);
                  setValue('password', e.target.value);
                }}
                onBlur={(e) => {
                  register("password").onBlur(e);
                  handleInputBlur('password')
                }} />
            </Tooltip>
            <Tooltip
              title={errors?.zipCode?.message}
              arrow
              placement={"top"}
              disableInteractive
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
              classes={{
                tooltip: "inputQtyTooltip",
              }}
            >

              <input {...register("zipCode")} className={clsx(styles.inputOnboarding, errors?.zipCode && styles.error)} type='tel' placeholder='Enter your zip code'
                onFocus={() => handleInputFocus('ZipCode')}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, '');
                  register("zipCode").onChange(e);
                  setValue('zipCode', e.target.value);
                }}
                maxLength={5}
                onBlur={(e) => {
                  register("zipCode").onBlur(e);
                  handleInputBlur('ZipCode')
                }} />
            </Tooltip>

          </div>
        </div>

      </div>
      <div className={clsx(styles.btnSection, (Object.keys(errors).length === 0 && isValid) ? styles.isEnabled : '')}>
        <button className={clsx(styles.nextBtn, (Object.keys(errors).length === 0 && isValid) && styles.enableBtn)} disabled={!(Object.keys(errors).length === 0 && isValid)}
          onClick={handleSubmit(onSubmit)}> Next </button>
      </div>

      <div className={clsx(styles.loginBtnSection, (Object.keys(errors).length === 0 && isValid) ? styles.isEnabled : '')}>
        Already have an account? <button onClick={() => navigate(routes.loginPage)}>Login</button>
      </div>


      <OnboardingFooter />
    </div>
  );
}
export default OnboardingDetails;
