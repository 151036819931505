import React, { memo} from "react";
import { Dialog } from "@mui/material";
import styles from './DialogPopup.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';

type Props = {
    children?: React.ReactNode,
    open: boolean,
    onClose: () => void,
    type: string,
    dialogTitle: string,
    dialogContent: string,
    dialogBtnTitle: string,
}
const DialogPopup: React.FC<Props> = memo((props) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            transitionDuration={200}
            hideBackdrop
            classes={{
                root: styles.ErrorDialog,
                paper: styles.dialogContent
            }}
        >
            {props.type === 'success' && 
            <button className={styles.closeIcon} onClick={props.onClose}><CloseIcon /></button>
            }
            <p className={styles.successPopupTitle}>{props.dialogTitle}</p>
            <p>{props.dialogContent}</p>
            <button className={styles.submitBtn} onClick={props.onClose}>{props.dialogBtnTitle}</button>
        </Dialog>
    );
});


export default DialogPopup;