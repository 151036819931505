import { Fade, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import {  useEffect, useState } from "react";
import { priceUnitChangeTooltip, resetBtnTooltip, shareListTooltip, shareWidgetTooltip } from "../../tooltip";
import { ReactComponent as ResetIcon } from '../../assets/images/icon-reset.svg';
import { ReactComponent as ResetIconHover } from '../../assets/images/icon-reset-hover.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/icon-share.svg';
import { ReactComponent as ShareIconHover } from '../../assets/images/icon-share-hover.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { ReactComponent as DropdownIcon } from '../../assets/images/icon_Triangle.svg';
import ProductPricing from "./productPricing";
import ProductDescription from "../../component/ProductDescription/ProductDescription";
import { ProductPricingModel, ProductQtyType } from "../../types/Search";
import { useGlobalStore, unitDropdownChangeHandler, handleSelectSingleProduct } from '@bryzos/giss-ui-library';

type SelectedProductListState = {
    selectedProducts: ProductPricingModel[],
    setSelectedProducts: React.Dispatch<ProductPricingModel[]>,
    isUserChangedSelectedOption: boolean,
    setIsUserChangedSelectedOption: React.Dispatch<boolean>,
    setSelectedOption: React.Dispatch<ProductQtyType>,
    selectedOption: ProductQtyType,
    setShowWidgetPanel: React.Dispatch<boolean>,
    sessionId: string,
};

const SelectedProductListing: React.FC<SelectedProductListState> = ({selectedProducts, setSelectedProducts, isUserChangedSelectedOption, setIsUserChangedSelectedOption, setSelectedOption, selectedOption, sessionId, setShowWidgetPanel}) => {
    const { userData, setUserData } = useGlobalStore();
    const [focusSingleProduct, setFocusSingleProduct] = useState<any>({});

    useEffect(()=>{
        setUserData({ ...userData, singleProduct: [] });
    },[])

    const handleResetData = () => {
        setSelectedProducts([]);
        setUserData({...userData, selectedProducts: [], singleProduct: []});
        setIsUserChangedSelectedOption(false);
    }

    const handleSharePrice = () => {
        setShowWidgetPanel(true);
    }

    return <>
        <div className={`listBody`}>
            <div className="selectProduct">
                <div className='headingSelect'>
                    <div className='firstDiv'>
                        <Tooltip
                            title={resetBtnTooltip()}
                            arrow
                            placement={'right'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 200 }}
                            classes={{
                                tooltip: 'rightTooltip',
                            }}
                        >
                            <div className='resetIcon cp' onClick={() => handleResetData()}>
                                <span className='img1'><ResetIcon /></span>
                                <span className='img2'><ResetIconHover /></span>
                            </div>
                        </Tooltip>
                        <Tooltip
                            title={shareListTooltip()}
                            arrow
                            placement={'right'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            classes={{
                                tooltip: 'rightTooltip',
                            }}
                        >
                            <div className='resetIcon cp' onClick={() => handleSharePrice()}>
                                <span className='img1'><ShareIcon /></span>
                                <span className='img2'><ShareIconHover /></span>
                                {(userData.singleProduct && Object.keys(userData.singleProduct).length !== 0) ? <span className='img3'><ShareIconHover /></span> : ''}
                            </div>
                        </Tooltip>

                        <span>

                            <Select
                                value={selectedOption}
                                onChange={(event) => { unitDropdownChangeHandler(event.target.value, setSelectedOption, setIsUserChangedSelectedOption, sessionId, selectedProducts) }}
                                className={`unitDropdown ${selectedOption === "cwt,ft" ? "multipleunitDropdown" : ""}`}
                                IconComponent={DropdownIcon}
                                MenuProps={{
                                    classes: {
                                        paper: 'SelectUnitDropdown',
                                    },
                                }}
                            >
                                {selectedOption === "cwt,ft" && <MenuItem value="cwt,ft" disabled={true} style={{ display: 'none' }}>$/CWT, $/FT</MenuItem>}
                                <MenuItem value="cwt">$/CWT</MenuItem>
                                <MenuItem value="ft">$/FT</MenuItem>
                                <MenuItem value="lb">$/LB</MenuItem>
                            </Select>
                        </span>
                        <Tooltip
                            title={priceUnitChangeTooltip()}
                            arrow
                            placement={'bottom-start'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            classes={{
                                tooltip: 'priceUnitChangeTooltip',
                            }}
                        >
                            <span className='questionIconPriceChange'>
                                <QuestionIcon className='questionIcon1' />
                                <QuestionHoverIcon className='questionIcon2' />
                            </span>
                        </Tooltip>
                    </div>
                </div>
                
                <div className='selectedPordListScroll'>
                    {
                        selectedProducts.map((product: ProductPricingModel) => (
                            <table className={`liBodyList ${focusSingleProduct[product.id] ? 'clickToShare' : ''}`} onClick={() => handleSelectSingleProduct(product, focusSingleProduct, setFocusSingleProduct)} key={product.id} >
                                <tbody>
                                    <tr>
                                        <td>
                                            <ProductDescription product = {product}/>
                                        </td>
                                        <td>
                                            <ProductPricing 
                                            selectedOption= {selectedOption} 
                                            isUserChangedSelectedOption= {isUserChangedSelectedOption} 
                                            product = {product}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))
                    }
                </div>
                <div className='lineH'></div>
                <div className='headingSelect footerSection'>
                    <div className='firstDiv'>
                        <span className='joinBryzos'>Patent Pending</span>
                    </div>
                    <Tooltip
                        title={shareWidgetTooltip()}
                        arrow
                        placement={'top-end'}
                        disableInteractive
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        classes={{
                            tooltip: 'shareWidgetTooltip',
                        }}
                    >
                        <div className='secondDiv'>
                            {/* <button onClick={handleOpenWidget} className='shareWidget'>Share Widget</button> */}
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    </>
}

export default SelectedProductListing;