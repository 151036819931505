// @ts-nocheck
import { useQuery } from "@tanstack/react-query";
import { reactQueryKeys } from "../../common";
import axios from "axios";

const useGetSellerSetting = (userId) => {

  return useQuery(
    [reactQueryKeys.getSellerSetting],
    async () => {
      try {
        const response = axios.get(
          `${import.meta.env.VITE_API_SERVICE}/user/sellingPreference`,
          {
            headers: {
              userId,
            },
          }
        );
        const responseData = await response;
        
        if (responseData.data && responseData.data.data) {
          if (
            typeof responseData.data.data === "object" &&
            "err_message" in responseData.data.data
          ) {
            throw new Error(responseData.data.data.err_message);
          } else {
            
            return responseData.data.data;
          }
        } else {
          return null;
        }
      } catch (error) {
        throw new Error(error?.message ?? error);
      }
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export default useGetSellerSetting;
