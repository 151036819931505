// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import styles from './videoPlayer.module.scss';
import { ReactComponent as VideoPlayIcon } from '../../assets/images/VideoPlay-Icon.svg';
import { ReactComponent as VideoPlayControlIcon } from '../../assets/images/Play.svg';
import { ReactComponent as VideoPauseIcon } from '../../assets/images/Pause.svg';
import { ReactComponent as VolumeIcon } from '../../assets/images/UnMute.svg';
import { ReactComponent as VolumeMutedIcon } from '../../assets/images/Mute.svg';
import { ReactComponent as FullScreenIcon } from '../../assets/images/Fullscreen.svg';
import { ReactComponent as ExitFullScreenIcon } from '../../assets/images/ExitFullScreen.svg';
import { ReactComponent as PlayNext } from '../../assets/images/Skip.svg';
import clsx from 'clsx';
import { getChannelWindow, useGlobalStore } from '@bryzos/giss-ui-library';
import { CircularProgress } from '@mui/material';
import { useLoadSubtitle } from 'src/renderer/hooks/useLoadSubtitle';

const VideoPlayer = ({ url, width, height, autoPlay, playNextVideo, disableNextVideoBtn, captionUrl }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const containerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMacDevice, setIsMacDevice] = useState(false);
  const [isBuffering, setIsBuffering] = useState(true);
  const [subtitleUrl, setSubtitleUrl] = useState();
  const channelWindow =  getChannelWindow();
  const {emitAppCloseEvent, setEmitAppCloseEvent}: any = useGlobalStore();
  const { data: subtitle, error, isLoading: isSubtitleLoading } = useLoadSubtitle(captionUrl);

  useEffect(() => {
    if (!isSubtitleLoading && subtitle) {
      const subtitleBlob = new Blob([subtitle], { type: 'text/vtt' });
      const objectUrl = URL.createObjectURL(subtitleBlob);
      setSubtitleUrl(objectUrl);
    }
  
    return () => {
      URL.revokeObjectURL(subtitleUrl);
    };
  }, [isSubtitleLoading, subtitle]);  

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsBuffering(false);
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const updateTime = () => {
    setCurrentTime(videoRef.current.currentTime);
    setDuration(videoRef.current.duration);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value / 100) * duration;
    videoRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleVolumeChange = (e) => {
    const vol = parseFloat(e.target.value);
    setVolume(vol);
    videoRef.current.volume = vol;
    if (vol === 0) {
      setIsMuted(true);
    } else {
      videoRef.current.muted = false;
      setIsMuted(false);
    }
  };

  const toggleMute = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    videoRef.current.muted = newMutedState;
    if (newMutedState) {
      setVolume(0);
    } else {
      setVolume(videoRef.current.volume);
    }
  };

  const formatTime = (time) => {
    if (isNaN(time)) return '00:00';

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  };

  const toggleFullscreen = () => {
    const container = containerRef.current;
    if (!isFullscreen) {
      if(channelWindow?.resizeWindow && !isMacDevice)
      (window as any).electron.send({ channel: channelWindow.resizeWindow, data: true});
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const handleOnPlaying = () => {
    setIsBuffering(false)
  };
  
  const handleOnWaiting = () => {
    setIsBuffering(true)
  };

  useEffect(() => {
    if (channelWindow?.systemVersion) {
      const deviceType = window.electron.sendSync({ channel: channelWindow.systemVersion });
      setIsMacDevice(deviceType === 'Mac Intel' || deviceType === 'Mac ARM');
    }
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement || !!document.webkitFullscreenElement || !!document.mozFullScreenElement || !!document.msFullscreenElement);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  useEffect(()=>{
    if(!isFullscreen && channelWindow?.resizeWindow && !isMacDevice)
    (window as any).electron.send({ channel: channelWindow.resizeWindow, data: false});
  }, [isFullscreen])

  useEffect(()=>{
    if(emitAppCloseEvent && videoRef?.current){
      if(!videoRef?.current.paused){
        videoRef.current.pause();
        setIsPlaying(false);
        setEmitAppCloseEvent(false);
      } 
    }
  }, [emitAppCloseEvent])

  useEffect(() => {
    if(url){
      setIsPlaying(autoPlay);
      setIsBuffering(true);
    }
  }, [url,autoPlay]);
  const progress = (currentTime / duration) * 100 || 0;

  return (
    <div className={styles['videoPlayerMain']} ref={containerRef}>
      <div className={clsx(styles['custom-video-player'], isFullscreen && styles['fullScreenHeight'])}>
        {isBuffering && <div className={styles.videoLoading}>
           <CircularProgress classes={{
            circle:styles.colorLoader
           }} />
          </div>}
        <video
          ref={videoRef}
          controls={false}
          onTimeUpdate={updateTime}
          onLoadedMetadata={updateTime}
          className={styles.video}
          autoPlay={autoPlay}
          onEnded={handleEnded}
          width={width}
          height={height}
          src={url}
          onWaiting={handleOnWaiting}
          onPlaying={handleOnPlaying}
        >
         {(subtitleUrl) &&
            <track
            kind="subtitles"
            src={subtitleUrl}
            srcLang="en"
            label="English"
            default />
         }         
        Your browser does not support the video tag.
        </video>
          <div className={clsx(styles['overlay'], {[styles['noOverlay']] : isPlaying })} onClick={togglePlay}>
            <span className={styles['VideoPlayIcon']}>
              {(!isBuffering && !isPlaying) && <VideoPlayIcon />}
            </span>
          </div>
        { (
          <div className={styles['controls']}>
            <div className={styles['seek-container']}>
              <input
                type="range"
                min="0"
                max="100"
                value={progress}
                className={styles['seek-bar']}
                onChange={handleSeek}
                style={{ '--progress': `${progress}%` }}
              />
            </div>
            <div className={styles['action-container']}>
            <div className={styles['leftbar-action']}>
              <button onClick={togglePlay}>
                {isPlaying ? <VideoPauseIcon /> : <VideoPlayControlIcon />}
              </button>
              <button className={styles.playNextBtn} onClick={playNextVideo} disabled={disableNextVideoBtn}>
                <PlayNext />
              </button>
              <div className={styles['time-display']}>
                <span className={styles['current-time']}>{formatTime(currentTime)}</span>
                <span>/</span>
                <span className={styles['duration']}>{formatTime(duration)}</span>
              </div>
              
              <button className={styles['volume-icon']} onClick={toggleMute}>
                {isMuted ? <VolumeMutedIcon /> : <VolumeIcon />}
              </button>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className={styles['volume-bar']}
                style={{
                  background: `linear-gradient(to right, #fff ${volume * 100}%, rgba(156, 163, 175, 0.6) ${volume * 100}%)`
                }}
              />

            </div>
            {(channelWindow && channelWindow.resizeWindow) && <button className="fullscreen-button" onClick={toggleFullscreen}>
               {!isFullscreen ? <FullScreenIcon/> : <span className={styles.exitFullscreenIcon}><ExitFullScreenIcon/></span>}
            </button>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
