import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

const fetchSubtitle = async (url: string): Promise<Uint8Array> => {
  if(!url){
    return new Uint8Array(new ArrayBuffer(0));
  }
  const response = await axios.get(url, {
    responseType: 'arraybuffer', // Important to get the response as a Buffer
  });
  return new Uint8Array(response.data);
};

export const useLoadSubtitle = (url: string):UseQueryResult<Uint8Array> => {
  return useQuery(['subtitle', url], () => fetchSubtitle(url), {
    refetchOnWindowFocus: false, // Prevents refetching on window focus
    refetchOnReconnect: false,   // Prevents refetching on network reconnect
  });
};