// @ts-nocheck
import React, { Component, useEffect, useRef } from "react";
import styles from './onboardingWelcome.module.scss';
import video1 from '../../assets/images/welcomepage.mp4';
import { useNavigate } from "react-router";
import { routes } from "../../../common";
import clsx from "clsx";


function OnboardingWelcome() {
    const videoRef = useRef();
    const navigate = useNavigate();
    useEffect(() =>{
        function goToMainPage(){
            navigate(routes.onboardingDetails);
        }
  
        document.addEventListener('keydown',goToMainPage)
        document.addEventListener('click',goToMainPage)
        return(()=>{
          document.removeEventListener('keydown',goToMainPage)
          document.removeEventListener('click',goToMainPage)
        })
      },[navigate])
    const handleVideoEnded = () => {
        navigate(routes.onboardingDetails);
    }
    if (videoRef.current) {
        videoRef.current.addEventListener('ended', handleVideoEnded);
    }
    return (
        <div>
            <div className={clsx(styles.welcomePage, 'bgBlurContent')}>
                <video src={video1}  autoPlay={true} ref={videoRef}/>
            </div>
        </div>
    );
}
export default OnboardingWelcome;
