import { create } from 'zustand';

export interface DialogAction{
    name: string,
    action: any
}

const dialogStoreInit = {
    openDialog: false,
    header: null,
    content: null,
    type: null,
    onClose: null,
    actions: []
}

const useDialogStore = create((set) => ({
    ...dialogStoreInit,
    setOpenDialog: (isOpen: boolean) => set({ openDialog: isOpen }),
    setDialogHeader: (msg: string) => set({ header: msg }),
    setDialogContent: (msg: string) => set({ content: msg }),
    setDialogType: (msg: string) => set({ type: msg }),
    setDialogOnClose: (onclose: any) => set({ onClose: onclose }),
    setDialogActions: (actions: DialogAction) => set({ snackbarActions: actions }),
    showCommonDialog: (header: string, content:string, type: string, onclose:Function, actions:DialogAction[]) => set({openDialog: true,header: header, content: content, type: type, onClose: onclose, actions: actions}),
    resetDialogStore: () => set((state:any) => ({
        ...dialogStoreInit
    }))
}));

export default useDialogStore;